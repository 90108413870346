export const styleCenteredText = {
    textAlign: 'center'
}
export const styleCenteredHorizontally = {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
}
export const styleWordWrap = {
    // whiteSpace: 'pre-wrap',
    whiteSpace: '-moz-pre-wrap',
    // whiteSpace: '-o-pre-wrap',
    wordWrap: 'break-word'
} as React.CSSProperties

export const errBoarderStyle = (isErr:boolean) => {
    let r = isErr ?
        {
            border: "3px solid #ff0000"
        }
        : {}
    return r;
}

export const styleHeaderTextSize = { fontSize: '2em' };
export const styleNormalTextSize = { fontSize: '1.33em' };

export default styleCenteredHorizontally;