import * as React from "react"

import { SVGProps } from "react"

const FaceAngrySvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={51.102}
        height={51.102}
        viewBox="0 0 47.909 47.909"
        {...props}
    >
        <defs>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="b"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="c"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="d"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="e"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="f"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                fy={32.797}
                fx={28.935}
                r={22}
                gradientTransform="translate(-3.8 6.8)"
                cx={28.935}
                cy={32.797}
                gradientUnits="userSpaceOnUse"
                id="g"
            >
                <stop offset={0} stopColor="#e6cf00" />
                <stop offset={1} stopColor="#fde94a" />
            </radialGradient>
            <radialGradient
                r={22}
                fy={32.797}
                fx={28.935}
                cy={32.797}
                cx={28.935}
                gradientTransform="matrix(1.04397 -.2088 .2088 1.04397 -147.722 553.727)"
                gradientUnits="userSpaceOnUse"
                id="i"
                xlinkHref="#f"
            />
            <linearGradient
                x1={14.66}
                y1={7.024}
                x2={24.031}
                gradientUnits="userSpaceOnUse"
                y2={34.826}
                id="a"
            >
                <stop offset={0} stopColor="#fcf9fb" />
                <stop offset={1} stopOpacity={0} stopColor="#fcf9fb" />
            </linearGradient>
            <linearGradient
                gradientTransform="rotate(-25.003 1166.868 769.946) scale(.34394)"
                y2={34.826}
                x2={24.031}
                y1={7.024}
                x1={14.66}
                gradientUnits="userSpaceOnUse"
                id="h"
                xlinkHref="#a"
            />
        </defs>
        <g transform="translate(229.669 -548.408)">
            <path
                d="m-206.829 565.402 2.032.918c1.543.713.755.337 2.368 1.127-4.127 1.94-.288 2.617-4.436 5.136l.01-.701c.05-4.012-.329 1.925.026-6.48z"
                style={{
                    opacity: 0.8,
                    fill: "url(#h)",
                }}
            />
            <circle
                cy={572.362}
                cx={-205.631}
                style={{
                    color: "#000",
                    display: "inline",
                    overflow: "visible",
                    visibility: "visible",
                    fill: "url(#i)",
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "#f10000",
                    strokeWidth: 2,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeDashoffset: 0,
                    strokeOpacity: 1,
                    marker: "none",
                }}
                r={22.954}
            />
            <circle
                style={{
                    color: "#000",
                    display: "inline",
                    overflow: "visible",
                    visibility: "visible",
                    opacity: 0.68999999,
                    fill: "#faa",
                    stroke: "#fff",
                    strokeWidth: 2.53080082,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeDashoffset: 0,
                    strokeOpacity: 1,
                    marker: "none",
                }}
                r={20.743}
                cy={572.362}
                cx={-205.714}
            />
            <path
                d="M-218.506 582.908s11.621-14.759 26.381 0"
                style={{
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "#000",
                    strokeWidth: 1.60132337,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
            />
            <ellipse
                ry={5.08}
                rx={1.314}
                cy={566.056}
                cx={-210.357}
                style={{
                    opacity: 1,
                    fill: "#000",
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "#000",
                    strokeWidth: 2,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeDashoffset: 0,
                    strokeOpacity: 1,
                }}
            />
            <ellipse
                style={{
                    opacity: 1,
                    fill: "#000",
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "#000",
                    strokeWidth: 2,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeDashoffset: 0,
                    strokeOpacity: 1,
                }}
                cx={-201.233}
                cy={566.056}
                rx={1.314}
                ry={5.08}
            />
        </g>
    </svg>
)

export default FaceAngrySvgComponent
