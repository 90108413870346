import "./styles.css";
import "semantic-ui-css/semantic.min.css";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";

import * as _ from "lodash";

import { Button, Grid, Icon, Input, InputOnChangeData, Popup, Segment, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import  { Layer, Line, Stage as StageComponent } from "react-konva";
import React, { Fragment, useEffect, useState } from "react";
import { RegionType, VisLabelType } from "../typings/vis";
import { Timeline, TimelineOptions } from "vis-timeline";
import { VideoInfo, VideoInfoDefault } from "../typings/video";
import { format, parseISO } from "date-fns";
import { frameFromTime, timeFromFrame } from "../utils/video";

import { AnalysisInfo } from "../typings/models/other";
import { DataSet } from "vis-data";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import ReactPlayer from "react-player";
import { RootState } from "../store";
import { RouteItemIdParam } from "../typings/models/User";
import { Stage } from "konva/lib/Stage";
import {axiosService} from "../utils/axios";
import { formatMessage as formatMessageLocal } from "../utils/localization";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowDimensions from "../utils/hooks";
import { withHandlingDimming } from "../utils/hocs";

type EditLabelType = Pick<VisLabelType, 'id' | 'content' | 'comments' | 'title' | 'color' | 'order'> & Pick<RegionType, 'frame' > & Partial<Pick<RegionType, 'points'>>
type EditLabelFormType = Pick<EditLabelType, 'content' | 'comments' | 'title' | 'points'>

function getEditLabelFromVisLabel(vl: VisLabelType, frame: number) : EditLabelType {
  let r = {
    id: vl.id,
    comments: vl.comments,
    title: vl.title,
    content: vl.content,
    order: vl.order,
    color: vl.color,
    frame: frame,
    points: vl.regions?.find(r => r.frame===frame)?.points
  } as EditLabelType;
  return r;
}

function LabelOrderComparisonFunction(a: VisLabelType, b: VisLabelType) {
  // order by order property
  if (a.order && b.order)
  {
    return a.order - b.order;
  }
  else {
    return 0;
  }
}

function getEditLabelsForFrame(frame: number, dataSet: DataSet<VisLabelType, 'id'>) : EditLabelType[] {
  let visLabels = dataSet.get( {filter : (item) => (+item.start.valueOf() <= frame && (item?.end ? +item.end.valueOf() > frame : +item.start.valueOf() === frame))});
  let ls = visLabels.map(l => getEditLabelFromVisLabel(l, frame));
  return ls;
}

function getRelativePointerPosition(node : Stage, k: number = 1) {
  // the function will return pointer position relative to the passed node
  const transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();

  // get pointer (say mouse or touch) position
  const pos = node.getStage().getPointerPosition();

  // now we find relative point
  let r = transform.point(pos!);
  return {
    x: r.x / k,
    y: r.y / k,
  };
}
function Analyse({setIsHandling} : {setIsHandling: React.Dispatch<React.SetStateAction<boolean>>}) {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {id} = useParams<RouteItemIdParam>();
  const [curAnalysis, setCurAnalysis] = useImmer<AnalysisInfo | null>(null);
  const curAnalysisRef = React.useRef<AnalysisInfo | null>(null);
  const [curVideoInfo, setCurVideoInfo] = useState<VideoInfo>(VideoInfoDefault );
  const [ _w, windHeight] = useWindowDimensions();
  const [curStrech, setCurStrech] = useState(1);
  const visLabelsRef = React.useRef<DataSet<VisLabelType> | null>(null);
  const visContainerRef = React.useRef<HTMLDivElement | null>(null);
  const visRef = React.useRef<Timeline | null>(null);
  const playerRef = React.useRef<ReactPlayer>(null);
  const frameSeekingRef = React.useRef(false);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);
  const [isLabelEditing, setIsLabelEditing] = useState(false);
  const [labelEditingForm, setLabelEditingForm] = useImmer<EditLabelFormType | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const curFrameRef = React.useRef(1);
  const curOrderRef = React.useRef(1);

  const [editLabels, setEditLabels] = useImmer<EditLabelType[] | null>(null);
  const account = useSelector((state: RootState) => state.auth.account);
  const isOwn = curAnalysis ? curAnalysis?.author === account?.id : false;

  useEffect(()=> {
    if (curVideoInfo.FRAME_HEIGHT !== 0) {
      let curWindStrech =  0.5 * windHeight / curVideoInfo.FRAME_HEIGHT;
      setCurStrech(curWindStrech);
    }
  }, [windHeight]);

  useEffect(() => {
    axiosService.get<AnalysisInfo>(`${process.env.REACT_APP_API_URL}/analysis/${id}/`)
      .then( (res : any) => {
          const { data : loadedAnalysis } = res;
          if (loadedAnalysis.analysis_results===null){
            loadedAnalysis.analysis_results = {} as Record<string, any>;
          }
          setCurAnalysis(loadedAnalysis);
          let loadedVideoInfo = loadedAnalysis.exercise.analysis_results['video_info'] as VideoInfo;
          setCurVideoInfo(loadedVideoInfo);
          let loadedLabels : VisLabelType[] = loadedAnalysis.analysis_results ? loadedAnalysis.analysis_results['labels'] ?? [] : [];
          let curLabels = new DataSet<VisLabelType, 'id'>(loadedLabels.map( (ll) => {
            let curLL : VisLabelType = {
              ...ll,
              start: new Date(ll.start)
            };
            if (ll.end) {
              curLL.end = new Date(ll.end);
            }
            return curLL;
          }));
          visLabelsRef.current = curLabels;
          setEditLabels(null);
          let curWindStrech = 0.5 * windHeight / loadedVideoInfo.FRAME_HEIGHT;
          setCurStrech(curWindStrech);
          // Default configuration of the Timeline
          const visTimelineOptions : TimelineOptions = {
            maxMinorChars: 3,
            showMajorLabels: false,
            horizontalScroll: true,
            verticalScroll: false,
            orientation: "top",
            moveable: true,
            zoomable: true,
            min: 1,
            max: loadedVideoInfo.FRAME_COUNT+2,
            start: 0,
            end: loadedVideoInfo.FRAME_COUNT+2,
            showCurrentTime: false,
            stack: true,
            multiselect: true,
            multiselectPerGroup: true,
            groupOrder: 'id',
            showTooltips: true,
            rollingMode: {
              follow: false,
              offset: 0.5
            },
            order: LabelOrderComparisonFunction,
            onAdd: function (item: VisLabelType, callback: any) {
              item.title = 'label';
              item.order = curOrderRef.current;
              item.color = Konva.Util.getRandomColor();
              item.regions = [];
              curOrderRef.current +=1;
              handleChanges(item, callback);
            },
            onUpdate: function (item: any, callback: any) {
              handleChanges(item, callback);
            },
            onRemove: function (item: any, callback: any) {
              handleChanges(item, callback);
            },
            onMove: function (item: any, callback: any) {
              handleChanges(item, callback);
            },
          };
          if (loadedVideoInfo.duration!==0) {
            // if (loadedAnalysis?.author.email === account?.email) {
            if (loadedAnalysis?.author.id === account?.id) {
              visTimelineOptions['editable'] = {
                add: true,
                updateTime: true,
                remove: true,
                overrideItems: true
              };
            };
            // TODO check WTF is https://chromestatus.com/feature/5745543795965952 - see console: Analyse.tsx:193 [Violation] Added non-passive event listener to a scroll-blocking 'wheel' event. Consider marking event handler as 'passive' to make the page more responsive. See https://www.chromestatus.com/feature/5745543795965952 (anonymous)	@	Analyse.tsx:193
            let visObj = new Timeline(visContainerRef.current!, curLabels, visTimelineOptions);
            visObj.on("timechange", (p: any) => {
              frameSeekingRef.current = true;
              let newFrame = new Date(p.time).valueOf();
              let curFrame, curTime;
              if (newFrame >= loadedVideoInfo.FRAME_COUNT) {
                curFrame = loadedVideoInfo.FRAME_COUNT + 1;
                curTime = loadedVideoInfo.duration;
              }
              else {
                curFrame = newFrame;
                curTime = timeFromFrame(curFrame, loadedVideoInfo.FPS);
              }
              playerRef.current!.seekTo(curTime,  "seconds");
              setCurrentFrame(curFrame);
            });
            visObj.on("timechanged", (p: any) => {
              frameSeekingRef.current = false;
            });
            visObj.on("select", (items: any) => {
              setSelectedLabelId(items.items[0]);
            });
            visObj.on("doubleClick", (item: any) => {
              let selectedLabelId = item.item;
              let selection = visLabelsRef.current?.get(selectedLabelId);
              let selectedItem = Array.isArray(selection) ? selection[0] : selection;
              let curTime = selectedItem!.start;
              let curFrame = new Date(curTime!).valueOf();
              let curPlayerTime = timeFromFrame(curFrame, loadedVideoInfo.FPS);
              playerRef.current!.seekTo(curPlayerTime,  "seconds");
            });
            visObj.addCustomTime(1, "curTime");
            visRef.current = visObj;
          };
      })
      .catch((err) => {
        alert(formatMessage({id: "general.error"}));
      });
    return () =>
    {
      visRef.current = null;
      visLabelsRef.current = null;
    }
  }, []);

  useEffect( () => {
    curAnalysisRef.current = curAnalysis;
  }, [curAnalysis])

  const saveLabels = () => {
    if (visLabelsRef.current && curAnalysisRef.current)
    {
      let curLabels = visLabelsRef.current!.get();
      let updatedAnalysis = curAnalysisRef.current.analysis_results ?
        {
          analysis_results: {...curAnalysisRef.current.analysis_results, labels: curLabels}
        }
        :
        {
          analysis_results: {labels: curLabels}
        }
      ;
      axiosService.put(`${process.env.REACT_APP_API_URL}/analysis/${curAnalysisRef.current!.id}/`, updatedAnalysis)
      .then(r=> {
        // console.log('saved');
      })
      .catch( (err) => {
        alert(formatMessage({id: "general.error"}));
      });
      setCurAnalysis(ca => {
        ca!.analysis_results!['labels'] = curLabels;
      });
    }
  };

  const handleChanges = (item: any, callback: any) => {
    let handledItem = {
      ...item,
    };
    if (item.end !== undefined){
      const [startFrame, endFrame] = [new Date(item.start).valueOf(), new Date(item.end).valueOf()];
      let remainingRegions = item.regions.filter( (r : RegionType) => (r.frame>= startFrame && r.frame <endFrame));
      handledItem = {
        ...item,
        regions: remainingRegions
      };
    }
    callback(handledItem);
    setEditLabels(getEditLabelsForFrame(curFrameRef.current, visLabelsRef.current!));
    saveLabels();
  }

  const setCurrentFrame = (curFrameArg: number) => {
    curFrameRef.current = curFrameArg;
    visRef.current!.setCustomTimeTitle(curFrameArg.toString(), "curTime");
    visRef.current!.setCustomTime(curFrameArg.toString(), "curTime");
    setEditLabels(getEditLabelsForFrame(curFrameRef.current, visLabelsRef.current!));
  }

  const deleteCurrentAnalysis = () => {
    setIsHandling(true);
    axiosService.delete(`${process.env.REACT_APP_API_URL}/analysis/${id}/`)
        .then( (r) => {
            if (r.status===200)
            {
              navigate("/analyse");
              // setIsHandling(false);
            }
        })
        .catch((err) => {
            alert(err.message);
            setIsHandling(false);
        });
  }
  return (
    <Fragment>
      <Grid stackable padded centered>
        <Grid.Row columns={1}>
          <Grid.Column width={16} textAlign="center">
            {curAnalysis && (curAnalysisRef.current?.author.id === account?.id ?
              <div>
                {formatMessage({id: "analyse.header.coach"}, {
                  exercise_author: curAnalysis?.exercise.author.name,
                  id: curAnalysis?.exercise.id,
                  exercise_date: format(parseISO(curAnalysis!.exercise?.date_created), "yyyy-MM-dd hh:mm")
                })}
                <Popup content={formatMessage({id: "analyse.deletepopup.content"})} trigger={<Button icon='delete' color='red' inverted onClick={deleteCurrentAnalysis} /> } />
              </div>
              :
              formatMessage({id: "analyse.header.customer"},  {
                analysis_author: curAnalysis?.author.name,
                analysis_date: format(parseISO(curAnalysis!.date_created), "yyyy-MM-dd hh:mm"),
                id: curAnalysis?.exercise.id,
                exercise_date: format(parseISO(curAnalysis!.exercise.date_created), "yyyy-MM-dd hh:mm")
              }))
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div id="container" style={{
                width: `${curVideoInfo.FRAME_WIDTH * curStrech}px`,
                height: `${curVideoInfo.FRAME_HEIGHT * curStrech}px`,
                position: "relative",
                backgroundColor: "red",
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
              }}>
              <div id="playerLayer">
                <ReactPlayer
                  ref={playerRef}
                  url={curAnalysis ? `${process.env.REACT_APP_MEDIA_URL}${curAnalysis?.exercise.recording_file}` : undefined}
                  controls={isLabelEditing ? false : true}
                  height={curVideoInfo.FRAME_HEIGHT * curStrech}
                  width={curVideoInfo.FRAME_WIDTH * curStrech}
                  progressInterval={1000/curVideoInfo.FPS}
                  onProgress={(state) => {
                    if (!frameSeekingRef.current && visRef.current!==null) {
                      let timePlayed = state.playedSeconds;
                      let framesPlayed = frameFromTime(timePlayed, curVideoInfo.FPS);
                      setCurrentFrame(framesPlayed);
                    }
                  }}
                  onPause = {()=>
                  {
                    setEditLabels(getEditLabelsForFrame(curFrameRef.current, visLabelsRef.current!));
                  }}
                  onStart = {()=>
                  {
                    setEditLabels([]);
                  }}
                  onPlay = {()=>
                  {
                    setEditLabels([]);
                  }}
                  onEnded = {()=>
                  {
                    setEditLabels(getEditLabelsForFrame(curFrameRef.current, visLabelsRef.current!));
                  }}
                />
              </div>
              <div id="labelingLayer" style={{
                width: `${curVideoInfo.FRAME_WIDTH * curStrech}px`,
                height: `${curVideoInfo.FRAME_HEIGHT * curStrech}px`,
                position: "absolute",
                top: "0",
                right: "0",
                pointerEvents: isLabelEditing ?  "auto" : "none"
              }}>
                <StageComponent
                  width={curVideoInfo.FRAME_WIDTH * curStrech} height={curVideoInfo.FRAME_HEIGHT * curStrech}
                  onMouseDown={(e: KonvaEventObject<MouseEvent>) => {
                    setIsDrawing(true);
                    const point = getRelativePointerPosition(e.target.getStage()!, curStrech);
                    setLabelEditingForm( f => {f!.points = [point]});
                  }}
                  onMouseMove={(e) => {
                    if (!isDrawing) {
                      return;
                    }
                    const point = getRelativePointerPosition(e.target.getStage()!, curStrech);
                    setLabelEditingForm( f => {
                      f!.points = f!.points!.concat([point])
                    });
                  }}
                  onMouseUp={(e) => {
                    if (!isDrawing) {
                      return;
                    }
                    setIsDrawing(false);
                  }}
                >
                  <Layer>
                    {
                      editLabels?.map(el => {
                        const isSelected = el.id===selectedLabelId;
                        const curOpacity = isSelected ? 0.3 : 0.8;
                        if (isSelected && isLabelEditing) {
                          return <Line key={el.id}
                            name="region"
                            points={labelEditingForm!.points?.flatMap(p => [p.x * curStrech, p.y * curStrech])}
                            fill={el.color}
                            stroke="black"
                            closed
                            opacity={curOpacity}
                          />
                        }
                        else {
                          return <Line key={el.id}
                            name="region"
                            points={el.points?.flatMap(p => [p.x * curStrech, p.y * curStrech])}
                            fill={el.color}
                            closed
                            opacity={curOpacity}
                          />
                        }
                      })
                    }
                  </Layer>
                </StageComponent>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div ref={visContainerRef}
              style={{
                pointerEvents: isLabelEditing ? "none" : "auto",
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
              }}
              />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {editLabels?.length && editLabels?.length>0 ?
          <Table celled>
            <Table.Header>
              <Table.Row  textAlign='center'>
                <Table.HeaderCell width={4}><FormattedMessage id='analyse.column.title' /></Table.HeaderCell>
                <Table.HeaderCell width={4}><FormattedMessage id='analyse.column.description' /></Table.HeaderCell>
                <Table.HeaderCell width={6}><FormattedMessage id='analyse.column.comments' /></Table.HeaderCell>
                {( isOwn || curAnalysis?.author.email === account?.email) &&
                  <Table.HeaderCell width={2}><FormattedMessage id='analyse.column.actions' /></Table.HeaderCell>
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                editLabels?.map(el => {
                  if (el.id===selectedLabelId) {
                    return (<Table.Row key={el.id} active  textAlign='center'>
                      {isLabelEditing ?
                        <React.Fragment>
                          <Table.Cell>
                            <Input fluid placeholder={el.title} onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                              setLabelEditingForm( pv => {pv!.title =data.value});
                            }} value={labelEditingForm?.title}/>
                          </Table.Cell>
                          <Table.Cell><Input fluid placeholder={el.content} onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                              setLabelEditingForm( pv => {pv!.content =data.value});
                            }} value={labelEditingForm?.content}/></Table.Cell>
                          <Table.Cell><Input fluid placeholder={el.comments} onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                              setLabelEditingForm( pv => {pv!.comments =data.value});
                            }} value={labelEditingForm?.comments}/></Table.Cell>
                          <Table.Cell  textAlign='center'>
                            <Button icon color= 'green' onClick={() => {
                              setIsLabelEditing(false);
                              let selectedVisLabel = visLabelsRef.current!.get(selectedLabelId);
                              let updatedVisLabel = {
                                ...selectedVisLabel,
                                title: labelEditingForm?.title,
                                content: labelEditingForm?.content,
                                comments: labelEditingForm?.comments,
                              };
                              if (labelEditingForm?.points && labelEditingForm?.points?.length>0)
                              {
                                if (selectedVisLabel!.regions && selectedVisLabel!.regions.length>0)
                                {
                                  updatedVisLabel['regions']=[ ...selectedVisLabel!.regions!.filter(r => r.frame!== curFrameRef.current),
                                    {
                                      frame: curFrameRef.current,
                                      points: labelEditingForm?.points
                                    }];
                                  }
                                else
                                {
                                  updatedVisLabel['regions']=[
                                    {
                                      frame: curFrameRef.current,
                                      points: labelEditingForm?.points
                                    }];
                                }
                              }
                              visLabelsRef.current!.update(updatedVisLabel!);
                              setLabelEditingForm(null);
                              setEditLabels(getEditLabelsForFrame(curFrameRef.current, visLabelsRef.current!));
                              saveLabels();
                            }}>
                              <Icon name= 'save'/>
                            </Button>
                            <Button icon color= 'red' onClick={() => {
                              setIsLabelEditing(false);
                              setLabelEditingForm(null);
                              }}>
                              <Icon name= 'cancel'/>
                            </Button>
                          </Table.Cell>
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <Table.Cell>{el.title}</Table.Cell>
                          <Table.Cell>{el.content}</Table.Cell>
                          <Table.Cell>{el.comments}</Table.Cell>
                          { ( isOwn || curAnalysis?.author.email === account?.email) &&
                            <Table.Cell>
                              <Button icon color= 'green' onClick={() => {
                                setSelectedLabelId(el.id as string);
                                setIsLabelEditing(true);
                                setLabelEditingForm({
                                  title: el.title,
                                  content: el.content,
                                  comments: el.comments,
                                  points: el.points
                                });
                                visRef.current?.setSelection(el.id);
                              }}>
                                <Icon name= 'edit' />
                              </Button>
                            </Table.Cell>
                          }
                        </React.Fragment>
                      }
                    </Table.Row>)
                  }
                  else {
                    return (<Table.Row key={el.id} textAlign='center'>
                      <React.Fragment>
                          <Table.Cell>{el.title}</Table.Cell>
                          <Table.Cell>{el.content}</Table.Cell>
                          <Table.Cell>{el.comments}</Table.Cell>
                          { ( isOwn || curAnalysis?.author.email === account?.email)
                            &&
                            <Table.Cell>
                              <Button icon color= 'green' disabled={isLabelEditing} onClick={() => {
                                setSelectedLabelId(el.id as string);
                                setIsLabelEditing(true);
                                setLabelEditingForm({
                                  title: el.title,
                                  content: el.content,
                                  comments: el.comments
                                });
                                visRef.current?.setSelection(el.id);
                              }}>
                                <Icon name= 'edit' />
                              </Button>
                            </Table.Cell>
                          }
                        </React.Fragment>
                    </Table.Row>)
                  }
                })
              }
            </Table.Body>
          </Table>
        :
        <Segment raised textAlign='center'>
          <FormattedMessage id='analyse.nolabelsmessage' />
        </Segment>
      }
    </Fragment>

  )
}

export default withHandlingDimming(formatMessageLocal({id: "general.serverhandling"}), false)(Analyse);