// import { UIState } from "../../typings/types";

import { createSlice } from "@reduxjs/toolkit";

export type UIState = {
    sidebarOpened: boolean,
    analysisSettingsOpened: boolean,
};

const initialState: UIState = {
    sidebarOpened: false,
    analysisSettingsOpened: false
    
} as UIState;

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setSidebarOpened(state: UIState) {
            state.sidebarOpened = true;
        },
        setSidebarClosed(state: UIState) {
            state.sidebarOpened = false;
        },
        changeAnalysisSettingsOpened(state: UIState) {
            state.analysisSettingsOpened = !state.analysisSettingsOpened;
        },
    },
});

export default uiSlice;
export const { setSidebarOpened, setSidebarClosed, changeAnalysisSettingsOpened } = uiSlice.actions;

export type UIStoreState = ReturnType<typeof uiSlice.reducer>;