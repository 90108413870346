import * as Yup from "yup";

import { Form, TextArea, TextAreaProps } from "semantic-ui-react";

import { FormikValues } from "formik";
import LabelWithError from "../../LabelWithError";
import React from "react";
import { errBoarderStyle } from "../../../typings/styles";
import { formatMessage } from "../../../utils/localization";
import { useDebounce } from "../../../utils/hooks";
import { useIntl } from "react-intl";
import { useState } from "react";

export const commentsSubFormYupShape = {
    comments: Yup.string()
        .min(10, formatMessage({id: "uploadmodal.comments.min"}))
        .max(255, formatMessage({id: "uploadmodal.comments.max"}))
        .required(formatMessage({id: "uploadmodal.uploadSchema.required"})),
}

function CommentsSubForm({formik, debounceTime = 1000}: {formik: FormikValues, debounceTime?: number}) {
    const {formatMessage: formatMessagesHook} = useIntl();
    const [currentText, setCurrentText] = useState(formik.values.comments);
    const debouncedUpdateTextChange = useDebounce(formik.setFieldValue, debounceTime);
    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps)  => {
        let curComments = data.value;
        setCurrentText(curComments);
        debouncedUpdateTextChange('comments', curComments);
    }
    return <Form.Field>
        <Form.Field>
            <LabelWithError
                isErr={Boolean(formik.errors.comments)}
                labelTextNorm = {formatMessagesHook({id: "uploadmodal.comments.norm"})}
                labelTextErr={formik.errors.comments! as string}
                infoText={formatMessagesHook({id: "uploadmodal.comments.infoText"})}
            />
            <TextArea
                placeholder="comments"
                name="comments"
                rows={4}
                onChange={onChangeText}
                onBlur={formik.handleBlur}
                style={errBoarderStyle(Boolean(formik.errors.comments))}
                type="string"
                value={currentText}
            />
        </Form.Field>
    </Form.Field>

}

export default React.memo(CommentsSubForm);