import { Header, Segment } from "semantic-ui-react";

import ContentComponentFromDB from "../../components/common/Content";
import { Fragment } from "react";

export default function TgComponent() {

    return <Fragment>
        <Header>This is tg 2222</Header>
        <Segment>
            <ContentComponentFromDB id= {1} withTitle={true} withBody={true}/>
        </Segment>
    </Fragment>
}