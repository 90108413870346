import { AnalysisSettingsType, defaultAnalysisSettings } from "../../typings/models/AnalysisSettings";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const analysisSettingsSlice = createSlice({
    name: "analysisSettings",
    initialState: defaultAnalysisSettings,
    reducers: {
        setAnalysisSettings(state: AnalysisSettingsType, action: PayloadAction<AnalysisSettingsType>) {
            return action.payload;
        },
        resetToDefaultAnalysisSettings(state: AnalysisSettingsType) {
            return defaultAnalysisSettings;
        }
    },
});

export default analysisSettingsSlice;
export const {setAnalysisSettings, resetToDefaultAnalysisSettings} = analysisSettingsSlice.actions;
