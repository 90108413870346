import { AnalysisSettingsType } from "./AnalysisSettings";
import { SpecialtyInfo } from "./other";
import { UserRoles } from "../../settings";
import _ from "lodash";

export type User = {
    id: number;
    email: string;
    name: string;
    is_active: boolean;
    created: Date;
    updated: Date;
    role: UserRoles;
};

export interface IAccount {
    user: User;
    access: string;
    refresh: string;
}

export interface UserResponse {
    email: string;
    username: string;
    is_active: string;
    created: Date;
    updated: Date;
    id: string;
}

export type RouteItemIdParam = {
    id: string;
};

export type ProfileResponse = {
    user: number,
    analysis_settings: AnalysisSettingsType
}

export type UserType = {
    email: string,
    name: string,
}

export type UserSystemType = {
    id: number,
} & UserType;

export type InviteUserInfoType = UserType & {
    comments: string,
};

export type InvitedUserInfoType = Omit<UserSystemType, 'email'> & {
    role: string,
    specialties?: string[],
}

export type AuthorInfo = {
    description: string
} & UserSystemType;

export type UserShortInfo = Pick<User, 'id' | 'name'>;

export type UserShortInfoWithDescription = UserShortInfo & {
    role: string,
    description: string,
    specialties: number[]
}

export type UsersInfoItemsType = {
    users: UserShortInfoWithDescription[],
    specialties: SpecialtyInfo[]
}
export interface IExpersTableUser extends Omit<AuthorInfo, "email"> {
    role: string;
    specialties: string[];
    selected?: boolean
};

export class ExpertsTableUSer implements IExpersTableUser {
    id: number;
    name: string;
    role: string;
    specialties: string[];
    description: string;
    selected?: boolean;

    constructor(public userInfo: UserShortInfoWithDescription, specialties: SpecialtyInfo[], isSelected : boolean = false) {
        this.id = userInfo.id;
        this.name = userInfo.name;
        this.role = userInfo.role;
        this.specialties = userInfo.specialties.map(ui => _.find(specialties, {'id': ui})!.title);
        this.description = userInfo.description;
        this.selected = isSelected;
    }
}


