import React, { Fragment, } from "react";

import { ContentFullType } from "../../typings/models/other";
import { useContentFromDB } from "../../utils/hooks";

/**
 * component for showing content in div from the DB by the ID of the content in the DB. Can show only body and/or title of the content. The style is applied to the div, containing the content. Title and body are concatenated if both included.
 * @param id - id of the content in the DB
 * @param withTitle - title is added to the content.
 * @param withBody - body is added to the content.
 * @param withStyle - style is applied to the div of the content.
 * @returns JSX.Element with the content from the database
 */
export default function ContentComponentFromDB ({id, withTitle = false, withBody = true, withStyle = {"display": "inline"}}: {id : number, withTitle?: boolean, withBody? : boolean, withStyle?: React.CSSProperties | undefined}) {
    const curContent : ContentFullType | null = useContentFromDB(id);
    let content : string = ``;
    if (curContent) {
        let contentTitle = withTitle ? curContent.title : "";
        content = withBody ? contentTitle + curContent.body : contentTitle;
    }
    return <Fragment>
        {curContent && <div dangerouslySetInnerHTML={{__html: content}} style={withStyle} />}
    </Fragment>

}

export function ContentComponentFromHTML ({htmlStr, withStyle = {"display": "inline"}}: {htmlStr: string, withStyle?: React.CSSProperties | undefined}) {
    return <div dangerouslySetInnerHTML={{__html: htmlStr}} style={withStyle} />
}