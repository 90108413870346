import { format } from "date-fns";

export const getDateTimeString = (d : Date, dateFormatStr : string = "yyyy-MM-dd hh:mm") =>
{
    return format(d, dateFormatStr);
}

export const isDateTimeStringValid = (ds : string) =>
{
    let timestamp = Date.parse(ds);
    if (isNaN(timestamp)) {
        return false;
    } else {
        return true;
    }
}