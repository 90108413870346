import authSlice from '../store/slices/auth';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store';
import { v4 as uuidv4 } from 'uuid';

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosService.interceptors.request.use(async (config) => {
    const { token } = await store.getState().auth;

    if (token !== null && token !== undefined &&  config.headers !== undefined) {
        config.headers.Authorization = 'Bearer ' + token;
        // @ts-ignore
        console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    }
    else {
        console.debug(`no token`);
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore
        console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        console.debug(
            '[Response]',
            err.config.baseURL + err.config.url,
            err.response.status,
            err.response.data
        );
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const { refreshToken } = store.getState().auth;
    if (refreshToken) {
        return axios
            .post(
                '/auth/refresh/',
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: process.env.REACT_APP_API_URL
                }
            )
            .then((resp) => {
                const { access, refresh } = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({ token: access, refreshToken: refresh })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(authSlice.actions.setLogout());
                }
            });
    }
    else
    {
        store.dispatch(authSlice.actions.setLogout());
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

function uploadFile({urlAPI, file, auxData}: {urlAPI: string, file: File | Blob, auxData?: any})
{
    const data = new FormData();
    if ( "name" in (file as File)) {
        data.append('recording_file', file, (file as File).name);
    }
    else {
        data.append('recording_file', file);
    }

    if (auxData) {
        let dataToAdd = JSON.stringify(auxData);
        data.append('data', dataToAdd);
    }

    return axiosService.post(urlAPI, data, {
        headers: {
            'Content-Type': `multipart/form-data`,
        }
    });
}

function uploadBlob({urlAPI, blob, fileName, auxData}: {urlAPI: string, blob: Blob, fileName?: string, auxData?: any})
{
    fileName = fileName ? fileName : uuidv4() + ".webm";
    let file = new File([blob], fileName);
    return uploadFile({urlAPI, file, auxData});
}

export {axiosService, uploadFile, uploadBlob};