import "react-multi-carousel/lib/styles.css";

import {
    Card,
    CardGroup,
    Grid,
    Header,
    Image,
    List,
    ListContent,
    ListIcon,
    ListItem,
    Segment,
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl';
import { styleHeaderTextSize, styleNormalTextSize } from "../typings/styles";

import Carousel from "react-multi-carousel";
import FeedbackModal from '../components/Modals/FeedbackModal';
import Link from '../components/Link';
import React from 'react';
import ReactPlayer from 'react-player';
import { RootState } from '../store';
import { boldText } from '../utils/localization';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../utils/hooks';

// region image sources
// Image segmentation guide
// https://developers.google.com/mediapipe/solutions/vision/image_segmenter

// Gesture recognition task guide
// https://developers.google.com/mediapipe/solutions/vision/gesture_recognizer

// Hand landmarks detection guide
// https://developers.google.com/mediapipe/solutions/vision/hand_landmarker

// Face detection guide
// https://developers.google.com/mediapipe/solutions/vision/face_detector

// Face landmark detection guide
// https://developers.google.com/mediapipe/solutions/vision/face_landmarker

// Pose landmark detection guide
// https://developers.google.com/mediapipe/solutions/vision/pose_landmarker
// endregion
const illustrationImagesInfo = [
    {
        id: 'image_segmenter',
        file: 'image_segmentation.png',
        header: 'home.image_segmenter.header',
        description: 'home.image_segmenter.description',
    },
    {
        id: 'gesture_recognizer',
        file: 'hand_gesture.png',
        header: 'home.gesture_recognizer.header',
        description: 'home.gesture_recognizer.description',
    },
    {
        id: 'hand_landmarker',
        file: 'hand_landmark.png',
        header: 'home.hand_landmarker.header',
        description: 'home.hand_landmarker.description',
    },
    {
        id: 'face_detector',
        file: 'face_detector.png',
        header: 'home.face_detector.header',
        description: 'home.face_detector.description',
    },
    {
        id: 'face_landmarker',
        file: 'face_landmark.png',
        header: 'home.face_landmarker.header',
        description: 'home.face_landmarker.description',
    },
    {
        id: 'pose_landmarker',
        file: 'pose_detector.png',
        header: 'home.pose_landmarker.header',
        description: 'home.pose_landmarker.description',
    },
]

function Home() {
    const {formatMessage} = useIntl();
    const [windWidth, windHeight] = useWindowDimensions();
    const account = useSelector((state: RootState) => state.auth.account);
    const isLandscape = windWidth > windHeight;
    const videoWidth = isLandscape ? 'auto' : windWidth * 0.3 ;
    const videoHeight = isLandscape ? windHeight * 0.3 : 'auto'  ;

    return ( <React.Fragment>
        <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                    <Header as='h3' style={styleHeaderTextSize}>
                            <FormattedMessage id="home.welcome.header"/>
                        </Header>
                        <div style={{ textAlign: 'justify', ...styleNormalTextSize}}>
                            <FormattedMessage id="home.welcome.text"/>
                        </div>
                    </Grid.Column >
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        <Header as='h3' style={styleHeaderTextSize}>
                            <FormattedMessage id="home.welcome.header.list"/>
                        </Header>
                        <Segment raised>
                            <div style={{ textAlign: 'justify', ...styleNormalTextSize, fontWeight: 'bold'}}>
                                <List>
                                    <ListItem>
                                        <ListIcon name='user' color="red"/>
                                        <ListContent><FormattedMessage id="home.welcome.header.list.self"/></ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='users' color='yellow' />
                                        <ListContent><FormattedMessage id="home.welcome.header.list.users"/></ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='archive' color='green' />
                                        <ListContent><FormattedMessage id="home.welcome.header.list.archive"/></ListContent>
                                    </ListItem>
                                </List>
                            </div>
                        </Segment>
                        <div style={{ textAlign: 'justify', ...styleNormalTextSize}}>
                            {formatMessage({id: "home.welcome.header.list.outro"}, {
                                HelpLink: <Link url={process.env.REACT_APP_WEB_URL! + "/help"} children={formatMessage({id: "home.helpLink"})}/>,
                            })}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        <Header as='h3' style={styleHeaderTextSize}>
                            <FormattedMessage id="home.description.header"/>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8} textAlign='center'>
                        <div
                            style={{ textAlign: 'justify', ...styleNormalTextSize}}
                            dangerouslySetInnerHTML={{__html: formatMessage({id: "home.description.text"}, {link: process.env.REACT_APP_WEB_URL})}}
                            />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            centerMode={true}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={true}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 1,
                                partialVisibilityGutter: 40
                                },
                                mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                                },
                                tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={true}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {illustrationImagesInfo.map(({id, file, header, description}) => (
                                <div key={id} style={{
                                    paddingTop: '0',
                                    paddingLeft: '3px',
                                    paddingRight: '3px',
                                    paddingBottom: '30px',
                                }}>
                                    <Card style={{
                                        height: "250px",
                                    }}
                                    >
                                        <Image src={`imgs/${file}`} alt="p1" height={windHeight/6} wrapped ui={false} />
                                        <Card.Content>
                                            <Card.Header textAlign='center'><FormattedMessage id={header} /></Card.Header>
                                            <Card.Description ><FormattedMessage id={description} /></Card.Description>
                                        </Card.Content>
                                    </Card>
                                </div>
                            ))
                            }
                        </Carousel>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={styleHeaderTextSize}  textAlign='center'>
                            <FormattedMessage id="home.screncast"/>
                        </Header>
                        <p style={{ textAlign: 'justify', ...styleNormalTextSize}} >
                            <FormattedMessage id="home.screncast.description"/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        <ReactPlayer
                            className='react-player fixed-bottom'
                            url= 'videos/Levina_marked.mp4'
                            width={videoWidth}
                            height={videoHeight}
                            controls = {true}
                            style={{display: 'inline-block', padding: "2px"}}
                        />
                        <ReactPlayer
                            className='react-player fixed-bottom'
                            url= 'videos/ShiYanbin_marked.mp4'
                            width={videoWidth}
                            height={videoHeight}
                            controls = {true}
                            style={{display: 'inline-block', padding: "2px"}}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                    <div style={{ textAlign: 'justify', ...styleNormalTextSize}}>
                        {account!=null ?
                            formatMessage({id: "home.feedback"},
                                {
                                    b: boldText,
                                    form: <FeedbackModal buttonLabel={formatMessage({id: "home.feedback.buttonLabel"})} />,
                                }
                            )
                            :
                            formatMessage({id: "home.register"},
                                {
                                    b: boldText,
                                    registrationLink: <Link url={process.env.REACT_APP_WEB_URL! + "/register"} children={formatMessage({id: "home.register_text"})}/>,
                                    loginLink: <Link url={process.env.REACT_APP_WEB_URL! + "/login"} children={formatMessage({id: "home.loginLink"})}/>,
                                    email_to_contact: process.env.REACT_APP_EMAIL?.replace("@", "(at)")
                                }
                            )
                        }
                    </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </React.Fragment>
    );
}

export default Home;