import { AnyAction, Reducer, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist";

import analysisSettingsSlice from "./slices/AnalysisSettings";
import authSlice from "./slices/auth";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import uiSlice from "./slices/ui";
import { useDispatch } from "react-redux";

const combinedReducer = combineReducers({
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    analysisSettings: analysisSettingsSlice.reducer
});
export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "auth/setLogout") {
        storage.removeItem('persist:root');
        state = {} as RootState;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer<RootState>(
    {
        key: "root",
        version: 1,
        storage: storage,
    },
    rootReducer
    // combinedReducer
);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);

export default store;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();