import { createMedia } from "@artsy/fresnel";

const appMedia = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})
export const mediaStyle = appMedia.createMediaStyle();
export const { MediaContextProvider, Media } = appMedia;
