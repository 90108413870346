import * as Yup from "yup";

import { Button, Form, Header } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';
import {Navigate, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import FeedbackModal from "../components/Modals/FeedbackModal";
import LabelWithError from "../components/LabelWithError";
import { ProfileResponse } from "../typings/models/User";
import React from 'react';
import { RootState } from "../store";
import authSlice from "../store/slices/auth";
import { axiosService } from "../utils/axios";
import { defaultAnalysisSettings } from "../typings/models/AnalysisSettings";
import { setAnalysisSettings } from "../store/slices/AnalysisSettings";
import { useFormik } from "formik";
import { useState } from "react";

function Login() {
    const [isLoading, setLoading] = useState(false);
    const [isAuthErr, setIsAuthErr] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();

    const handleLogin = (email: string, password: string) => {
        setLoading(true);
        axiosService
            .post(`${process.env.REACT_APP_API_URL}/auth/login/`, { email, password })
            .then((res : any) => {
                dispatch(
                    authSlice.actions.setAuthTokens({
                        token: res.data.access,
                        refreshToken: res.data.refresh,
                    })
                );
                axiosService.get<ProfileResponse>(`${process.env.REACT_APP_API_URL}/profile/`)
                    .then( (res : any) => {
                        const { data } = res;
                        dispatch(setAnalysisSettings(data.analysis_settings));
                    })
                    .catch((err : any) => {
                        console.log(err.message);
                        dispatch(setAnalysisSettings(defaultAnalysisSettings));
                        axiosService
                            .post(`${process.env.REACT_APP_API_URL}/profile/`, defaultAnalysisSettings)
                            .catch((error) => {
                                alert(formatMessage({id: "settingsmodal.alert.error"}, {err: error}));
                            });
                    });

                dispatch(authSlice.actions.setAccount(res.data.user));
                setLoading(false);
                navigate("/exercise");
            })
            .catch((err : any) => {
                setIsAuthErr(true);
                setLoading(false);
            });

    };

    const formikLogin = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            setLoading(true);
            handleLogin(values.email, values.password);
        },
        validationSchema: Yup.object({
            email: Yup.string().trim().required(formatMessage({id: "login.validationSchema.required"})).email(formatMessage({id: "login.validationSchema.required"})),
            password: Yup.string().trim().required(formatMessage({id: "login.validationSchema.required"})),
        }),
    });
    const handleChangeCustom = (e: React.ChangeEvent<any>) => {
        if (isAuthErr) setIsAuthErr(false);
        formikLogin.handleChange(e);
    }
    const auth = useSelector((state: RootState) => state.auth);
    let loggedInComponent = <Header as='h1'>🤗 <FormattedMessage id="login.registered.header"/></Header>
    let authComponent = <div style ={{padding: "1em"}}>
        <Header as='h1'>🤗 <FormattedMessage id="login.notregistered.header"/></Header>
        <p>🔐 <FormattedMessage id="login.notregistered.text_1"/><a href="/register/"><FormattedMessage id="login.ref_to_register"/></a> <FormattedMessage id="login.notregistered.text_2"/><a href="/verifyemail/"><FormattedMessage id="login.ref_to_verification"/></a>.</p>
        <Header as='h1'>🤫 <FormattedMessage id="login.intro.header"/></Header>
        <p>🔐 <FormattedMessage id="login.intro.text"/></p>
        <Form onSubmit ={formikLogin.handleSubmit}>
            <Form.Field>
                <LabelWithError
                        isErr={formikLogin.touched.email && Boolean(formikLogin.errors.email)}
                        labelTextNorm = {formatMessage({id: "login.label.email"})}
                        labelTextErr={formikLogin.errors.email!}
                    />
                <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formikLogin.values.email}
                    onChange={handleChangeCustom}
                    onBlur={formikLogin.handleBlur}
                />
            </Form.Field>
            <Form.Field>
                <LabelWithError
                        isErr={formikLogin.touched.password && Boolean(formikLogin.errors.password)}
                        labelTextNorm = {formatMessage({id: "login.label.password"})}
                        labelTextErr={formikLogin.errors.password!}
                    />
                <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formikLogin.values.password}
                    onChange={handleChangeCustom}
                    onBlur={formikLogin.handleBlur}
                />
            </Form.Field>
            <Button type='submit'
                disabled = {isLoading}
                loading = {isLoading}
                color= {!isLoading ? 'green' : 'red'}
            >
                <FormattedMessage id={isLoading ? "login.button.submitting" : "login.button.submit"}/>
            </Button>
            {isAuthErr && <span style={{"color": "red"}}>{formatMessage({id: "login.errmessage"},
                {
                    form: <FeedbackModal buttonLabel={formatMessage({id: "home.feedback.buttonLabel"})} />,
                }
            )}</span>}
        </Form>
    </div>
    return auth.account == null ? authComponent : loggedInComponent;
}

export default Login;
