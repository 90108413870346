//#region  settings stuff
export type AnalysisSettingsType = {
    generalAnalysisSettings: IAnalysisSettingsGeneral,
    videoSettings: IAnalysisSettingsVideo,
    audioSettings: IAnalysisSettingsAudio,
}
export const defaultAnalysisSettings : AnalysisSettingsType = {
    generalAnalysisSettings: {
        includeVideo: true,
        includeAudio: true
    },
    videoSettings: {
        frameDelayCapture: 200,
        centerPositionDiviation: 0.2,
        rotationAngleDeviation: 10
    },
    audioSettings: {
        includeVolume: true,
        volumeMin: 0.3,
        volumeMax: 0.7,
        includeSpeech: true,
        upperSlowWords: 50,
        upperOkWords: 100,
        upperMaxWords: 150
    }
};
export interface IAnalysisSettingsGeneral{
    includeVideo: boolean,
    includeAudio: boolean
}
export interface IAnalysisSettingsVideo{
    frameDelayCapture: integer,
    centerPositionDiviation: percentage,
    rotationAngleDeviation: angle
}
export interface IAnalysisSettingsAudio{
    includeVolume: boolean,
    volumeMin: number,
    volumeMax: number,
    includeSpeech: boolean,
    upperSlowWords: number,
    upperOkWords: number,
    upperMaxWords: number,
}
//#endregion

type integer = number;
type percentage = number;
type angle = number;