import { EmotionType } from "../../utils/video";

//#region video stuff
export const videoResultsInitial : VideoResultsType = {
    positionChangeRequired: false,
    emotions: 'neutral',
}
export type VideoResultsType = {
    positionChangeRequired: boolean,
    emotions: EmotionType,
};
//#endregion

//#region audio stuff
export const audioResultsInitial : AudioResultsType = {
    currentTimeTick: 0,
    currentVolume: 0,
    transcript: [],
    currentWordSpeed: 0
}
export type AudioResultsType = {
    currentTimeTick: number,
    currentVolume: number,
    transcript: string[],
    currentWordSpeed: number
};
//#endregion