import { Navigate, Outlet, useLocation } from "react-router";
import { RootState } from "../store";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {

    const auth = useSelector((state: RootState) => state.auth);
    let {pathname} = useLocation();
    const location = pathname?.slice(1);

    if (auth.account) {
        if (location === "/login") {
            return <Navigate replace to="/" />;
        }
        return <Outlet />;
    } else if (!auth.account) {
        return <Navigate replace to={"/login"} />;
    } else {
            return <div>Not found</div>;
    }
};

export default ProtectedRoute;