import {
    Image,
} from 'semantic-ui-react'
import {NavLink} from "react-router-dom";
import React from 'react';
import { RootState } from '../store';
import styled from "styled-components";
import { useSelector } from 'react-redux';

// import ticketsImage from "./../imgs/tickets.png";

const NavbarLink = styled(NavLink)`
    color:aqua;
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin: 10px;
    &:hover{
        color: green;
    };
`

const DDIV = styled.div`
    color:green;
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin: 10px;
    &:hover{
        color: red;
    };
`


function User() {
    const account = useSelector((state: RootState) => state.auth.account);
    const registerJsx = <React.Fragment>
        <p>"Unlogged / unregistered!"</p>
        <p>Please, <a href="/login">register or login</a></p>
    </React.Fragment>

    return (<React.Fragment>
        <h1>Welcome!</h1>
        {account ? `welcome ${account.name}` : registerJsx}
        <p>To <a href="/logout">log out</a></p>
    </React.Fragment>);
}

export default User;