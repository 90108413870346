import * as _ from 'lodash';

import { AnalysisSettingsType, IAnalysisSettingsVideo } from '../typings/models/AnalysisSettings';
import { Face, FaceLandmarksDetector, Keypoint } from "@tensorflow-models/face-landmarks-detection";
import { InferenceSession, Tensor } from 'onnxruntime-web';
import { RotationDirectionX, TurnDirection, drawArrowMove, drawArrowRotationX, drawArrowTurnZ } from './drawing';
import { arrCoordSum, arrsAngle, arrsMult, arrsSubtract } from './vectors';

import { BoundingBox } from '@tensorflow-models/face-landmarks-detection/dist/shared/calculators/interfaces/shape_interfaces';
import {Image} from 'image-js';
import { Updater } from 'use-immer';
import { VideoResultsType } from '../typings/models/Results';

//#region config
const emotion_table_original = {
    'neutral':0, 'happiness':1, 'surprise':2, 'sadness':3, 'anger':4, 'disgust':5, 'fear':6, 'contempt':7
};
const allEmotions = ['neutral', 'happy', 'angry'] as const;

const emotionMin = 0.4266163185238838;
const emotionMax = 0.42671896889805794;
const emotionDelta = emotionMax - emotionMin;
const EmotionRangesMins = [0, emotionMin + emotionDelta / 3, emotionMin + 2 * emotionDelta / 3]; //must be increasing sequence to indicate ranges for the emotions
export type EmotionType = typeof allEmotions[number];
const EmotionIndexWeights = [1, 1, 1, 1, 1, 1, 1, 1];
const modelInputImgSize = 64;
//#endregion

export const drawFacePredictions = (
predictions: Face[],
ctx: CanvasRenderingContext2D,
wk: number,
hk: number
) => {
    if (predictions.length > 0) {
        predictions.forEach((prediction: Face) => {
            const { xMin, yMin, width, height } = prediction.box;
            const eyeLeft = prediction.keypoints.filter(kp => kp.name === 'leftEye');
            const eyeRight = prediction.keypoints.filter(kp => kp.name === 'rightEye');
            ctx.beginPath();
            // drawing bounding box
            ctx.rect(xMin * wk, yMin * hk, width * wk, height * hk);
            // drawing eyes
            ctx.fillStyle = "#FF0000";
            eyeLeft.forEach(p => ctx.fillRect(p.x * wk, p.y * hk, 2, 2));
            ctx.fillStyle = "#33FF4F";
            eyeRight.forEach(p => ctx.fillRect(p.x * wk, p.y * hk, 2, 2));
            ctx.stroke();
        });
    }
};
export const drawSuggestedMoveDirection = (
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    wk: number,
    hk: number,
    arrowKw: number,
    arrowKh: number,
    videoSettings: IAnalysisSettingsVideo
) : boolean => {
    const canvas = ctx.canvas;
    const { xMin, yMin, width, height } = bbox;
    const [sceneCenterX, sceneCenterY ] = [canvas.clientWidth / 2, canvas.clientHeight / 2];
    const [bbX, bbY] = [xMin + width /2, yMin + height / 2];

    let moveRequired = false;
    
    // horizontal suggestion
    if (bbX * wk / sceneCenterX > 1 + videoSettings.centerPositionDiviation) {
        drawArrowMove({canvas: canvas, attachX: xMin * wk, attachY: bbY*hk, moveDirection: 'left', kx: arrowKw, ky: arrowKh});
        moveRequired = true;
    }
    else if (bbX * wk / sceneCenterX < 1 - videoSettings.centerPositionDiviation) {
        drawArrowMove({canvas: canvas, attachX: (xMin+width)*wk, attachY: bbY*hk, moveDirection: 'right', kx: arrowKw, ky: arrowKh});
        moveRequired = true;
    }

    // vertical suggestion
    if (bbY *hk/ sceneCenterY > 1 + videoSettings.centerPositionDiviation) {
        drawArrowMove({canvas: canvas, attachX: bbX * wk, attachY: yMin*hk, moveDirection: 'up', kx: arrowKw, ky: arrowKh});
        moveRequired = true;
    }
    else if (bbY *hk / sceneCenterY < 1 - videoSettings.centerPositionDiviation) {
        drawArrowMove({canvas: canvas, attachX: bbX * wk, attachY: (yMin + height)*hk, moveDirection: 'down', kx: arrowKw, ky: arrowKh});
        moveRequired = true;
    }
    return  moveRequired;
};
export const estimateEmotions = (
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    session: InferenceSession,
    videoResultsUpdater: Updater<VideoResultsType>
) => {
    let {xMin, yMin, width, height} = bbox;
    width = Math.floor(width);
    height = Math.floor(height);
    const heightToExtend = width > height ? true : false;
    const boundingSquare = heightToExtend ? width : height;
    // TODO need to check if this works for corners - where extending can get out of the canvas
    const imgCanvasData = ctx.getImageData(xMin, yMin, boundingSquare, boundingSquare).data;
    let img = new Image(boundingSquare, boundingSquare, imgCanvasData);
    // TODO - check if needed to draw rectangle to hide unnecessary data for getting square for the face image
    // const colorCodeToSet = [0, 0, 0, 255];
    // drawRect(img, 0, height, width, delta, colorCodeToSet);
    img = img.grey();
    img = img.resize({width: modelInputImgSize});
    let imgDataRow = img.data;
    let maxImgData = _.max(imgDataRow);
    let imgData = new Float32Array(imgDataRow.map(p => p/maxImgData!));
    const preprocessedData = new Tensor(imgData, [1, 1, modelInputImgSize, modelInputImgSize]);
    const feeds: Record<string, Tensor> = {};
    feeds[session.inputNames[0]] = preprocessedData;
    session.run(feeds).then(res => {
        const output = res[session.outputNames[0]];
        let outputResults = output.data as Float32Array;
        let scaledResults = arrsMult(outputResults, EmotionIndexWeights);
        let resultIndex = arrCoordSum(scaledResults);
        let r : EmotionType;

        for (let i = EmotionRangesMins.length -1; i >=0; i--)
        {
            if (resultIndex > EmotionRangesMins[i])
            {
                r = allEmotions[i];
                // console.log(`resultIndex=${resultIndex} and emotion=${r}`);
                // eslint-disable-next-line no-loop-func
                videoResultsUpdater( vr => { vr.emotions = r;});
                break;
            }
        }
    });
};

export function detectFaces(
    faceLandmarksDetector: FaceLandmarksDetector,
    session: InferenceSession,
    imageBitmap: ImageBitmap,
    ctx: CanvasRenderingContext2D,
    wk: number,
    hk: number,
    videoResultsUpdater: Updater<VideoResultsType>,
    analysisSettings: AnalysisSettingsType
    ) {
    faceLandmarksDetector.estimateFaces(imageBitmap).then(predictions => {
        if (predictions.length>0) {
            // analyse video according to the settings
            console.log(`predictions.length - ${predictions.length}`);
            analyseVideo(ctx, predictions, session, videoResultsUpdater, wk, hk, analysisSettings)
        }
        else
        {
            console.log(`no face detected!`);
        }
    });
}

export const analyseVideo = (
    ctx: CanvasRenderingContext2D,
    predictions: Face[],
    session: InferenceSession,
    videoResultsUpdater: Updater<VideoResultsType>,
    wk: number,
    hk: number,
    settings: AnalysisSettingsType
    ) =>
{
    if (settings.generalAnalysisSettings.includeVideo) {
        drawFacePredictions(predictions, ctx, wk, hk);
        let videoResultsMove = drawSuggestedMoveDirection(ctx, predictions[0].box, wk, hk, 1, 1, settings.videoSettings);
        let videoResultsRotate = drawSuggestedRotateDirections(ctx, predictions[0], wk, hk, 0.3, 0.3, settings.videoSettings);
        videoResultsUpdater( (results)=> {
            results.positionChangeRequired = videoResultsMove || videoResultsRotate;
        });
        estimateEmotions(ctx, predictions[0].box, session, videoResultsUpdater);
    }
}
export const drawSuggestedRotateDirections = (
    ctx: CanvasRenderingContext2D,
    predictions: Face,
    wk: number,
    hk: number,
    arrowKw: number,
    arrowKh: number,
    videoSettings: IAnalysisSettingsVideo
) : boolean => {
    const canvas = ctx.canvas;
    let kps = predictions.keypoints;
    const { xMin, yMin, width, height } = predictions.box;
    let rotationRequired = false;

    let kp_left = kps[33];
    let kp_right = kps[263];
    let eyeArr3DX = getArrFrom2Points(kp_left, kp_right);
    let eyeArr3DZ = [...eyeArr3DX];
    // handle X
    let eyeArrXY = _.pullAt(eyeArr3DX, [0, 1]);
    let angleX = arrsAngle(eyeArrXY, [1, 0]);
    // handle Z
    let eyeArrXZ = _.pullAt(eyeArr3DZ, [0, 2]);
    let angleZ = arrsAngle(eyeArrXZ, [0, 1]);

    // X suggestion - for deviation from X axis
    if (Math.abs(angleX) > videoSettings.rotationAngleDeviation) {
        let rotDir = eyeArrXY[1]>0 ? 'counterclockwise' : 'clockwise';
        let attachX = eyeArrXY[1]>0 ? (xMin + width)*wk : xMin * wk;
        drawArrowRotationX({canvas: canvas, attachX: attachX, attachY: yMin*hk, rotationDirection: rotDir as RotationDirectionX, kx: arrowKw, ky: arrowKh});
        rotationRequired = true;
    }
    
    // Z suggestion - for deviation from Z axis normal
    let angZTurnD = angleZ > 0 ? angleZ : angleZ+90;
    if (Math.abs(angZTurnD) > videoSettings.rotationAngleDeviation) {
        let turnDirection = angZTurnD > 0 ? 'left' : 'right';
        let attachXZ = angZTurnD > 0 ? (xMin + width) * wk : xMin * wk;
        drawArrowTurnZ({canvas: canvas, attachX: attachXZ, attachY: (yMin + height / 2)*hk, turnDirection: turnDirection as TurnDirection, kx: arrowKw, ky: arrowKh});
        rotationRequired = true;
    }
    return rotationRequired;
};
export const timeFromFrame = (frameNumber: number, fps: number = 30) => {
    return (frameNumber / fps);
}
export const frameFromTime = (time: number, fps: number = 30) => {
    return Math.round(time * fps);
}

export function clearCanvas(ctx: CanvasRenderingContext2D, canvasWidth?: number, canvasHeight?: number) : void {
    let c_w = canvasWidth ?? ctx.canvas.clientWidth;
    let c_h = canvasHeight ?? ctx.canvas.clientWidth;
    ctx.clearRect(0, 0, c_w, c_h);
}

function getArrFrom2Points(kp1:Keypoint, kp2: Keypoint){
    let k1 = _.slice(_.values(kp1), 0, 3) as number[];
    let k2 = _.slice(_.values(kp2), 0, 3) as number[];
    let v = arrsSubtract(k2, k1);
    return v;
}

