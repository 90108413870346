import * as _ from 'lodash';

import { Icon, IconProps } from "semantic-ui-react";

export enum ProcessingStatus {
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
    DONEOK = 'DONEOK',
    DONEERR = 'DONEERR'
}

const ProcessingStatusIconsDict  = {
    [ProcessingStatus.PENDING]: {
        name: 'envelope outline',
        color: 'blue'
    },
    [ProcessingStatus.PROCESSING]: {
        name: 'hourglass start',
        color: 'yellow'
    },
    [ProcessingStatus.DONEOK]: {
        name: 'check',
        color: 'green'
    },
    [ProcessingStatus.DONEERR]: {
        name: 'x',
        color: 'red'
    }
};

export const ProcessingStatusIcons = _.mapValues(ProcessingStatusIconsDict, (v) => (<Icon {...v as IconProps}/>));

export const enum UserRoles {
    CUSTOMER ="CUSTOMER",
    COACH = "COACH",
}

export const spacialties = ['speacking', 'health', 'sports', 'theater'];
export const roles = ['user', 'expert', 'robot'];

export const OTP_max_length = 6;