import { styleCenteredText, styleWordWrap } from "../../../typings/styles";

import { EmotionType } from "../../../utils/video";
import FaceAngrySvgComponent from "../../../imgs/FaceAngry";
import FaceHappySvgComponent from "../../../imgs/FaceHappy";
import FaceNeutralSvgComponent from "../../../imgs/FaceNeutral";
import { Header } from "semantic-ui-react";
import React, {  } from "react";
import {useIntl} from "react-intl";

//#region config
const emotionsSigns = {
    'angry': <FaceAngrySvgComponent style={{display:'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        }}/>,
    'neutral': <FaceNeutralSvgComponent style={{display:'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        }}/>,
    'happy': <FaceHappySvgComponent style={{display:'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        }}/>,
}
//#endregion


// export default function EmotionMeter({width, height} : {width?: number; height?: number})
export default function EmotionMeter({emotion, width, height} : {emotion?: EmotionType, width?: number; height?: number})
{
    // TODO add handling various width/height settings
    const {formatMessage} = useIntl();
    let curEmotion = emotion ?? 'neutral' as EmotionType;
    return <React.Fragment>
        <Header
            content={formatMessage({id: "exercisenew.video.emotion"})}
            as='h3'
            style = {{...styleCenteredText, ...styleWordWrap}}
        />
        {emotionsSigns[curEmotion]}
    </React.Fragment>
}