import * as _ from 'lodash';
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

import {Accordion, Grid, Header, Icon, TextArea} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";
import Meter, { meterSizesDefault } from "../../components/Meter";
import React, { useEffect, useRef, useState } from 'react';
import ReactSpeedometer, { CustomSegmentLabelPosition } from "react-d3-speedometer";
import { audioResultsInitial, videoResultsInitial } from "../../typings/models/Results";
import { clearCanvas, detectFaces } from "../../utils/video";
import { styleCenteredText, styleWordWrap } from "../../typings/styles";

import { CSSProperties } from 'styled-components';
import EmotionMeter from "../Domain/Analysis/EmotionMeter";
import {InferenceSession} from "onnxruntime-web";
import RecordRTC from "recordrtc";
import { RootState } from "../../store";
import { SizeMe } from 'react-sizeme';
import ThumbDownSvgComponent from "../../imgs/ThumbDown";
import ThumbUpSvgComponent from "../../imgs/ThumbUp";
import { createFaceLandmarksDetector } from '../../utils/analysis';
import { createSpeechRecognizer } from "../../utils/audioutils";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import useWindowDimensions from '../../utils/hooks';

/**
 * Component for recording media according to the settings taken from Redux store.
 * @param handleRecordedBlob - callback for returning the recorded Blob to the parent component
 * @returns component which is used to record media stream(s) and show recording in UI. Recorded Blob is handed over via the handleRecordedBlob callback.
 */
function RecordingComponent ( {setIsRecordingStarting, handleRecordedBlob, recordingDeviceId = null } : {
    setIsRecordingStarting: React.Dispatch<React.SetStateAction<boolean>>,
    handleRecordedBlob: (recordedBlob: Blob) => void,
    recordingDeviceId?: string | null | undefined
}) {
    const {formatMessage} = useIntl();
    const analysisSettings = useSelector((state: RootState) => state.analysisSettings);
    const videoHtmlRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [aspectWidthToHeigh, setAspectWidthToHeigh] = React.useState<number | null>(null);
    const [videoResults, setVideoResults] = useImmer(videoResultsInitial);
    const [ windowWidth, windowHeight] = useWindowDimensions();

    const streamRef = useRef<MediaStream | null >(null);
    const isAudioAnalysisOnRef = React.useRef<boolean>();
    const isVideoAnalysisOnRef = React.useRef<boolean>();

    const faceDetectorRef = React.useRef<faceLandmarksDetection.FaceLandmarksDetector>();
    const onnxSessionRef = React.useRef<InferenceSession | null>(null);

    const recorderRef = React.useRef<typeof RecordRTC | null>(null);
    //#region voice recognition stuff
    const analyserNodeRef = React.useRef<AnalyserNode | null>(null);
    const speechRecognizerRef = React.useRef<any | null>(null);
    const [audioResults, setAudioResults] = useImmer(audioResultsInitial);
    const [isTranscriptExpanded, setIsTranscriptExpanded] = useState(false);
    //#endregion

    useEffect(() => {
        setIsRecordingStarting(true);
        startRecording('video').then(stream => {
            recorderRef.current.camera = stream;
            if (videoHtmlRef.current!=null) {
                videoHtmlRef.current!.muted = true;
                videoHtmlRef.current!.volume = 0;
                videoHtmlRef.current!.srcObject = stream;
                let camSettings = stream.getVideoTracks()[0].getSettings();
                let camWidth = camSettings.width!;
                let camHeight = camSettings.height!;
                setAspectWidthToHeigh(camWidth / camHeight);
                videoHtmlRef.current.srcObject=stream;
                // TODO add detection of iOS and configuration accordingly
                // Fix for iOS Safari from https://leemartin.dev/hello-webrtc-on-safari-11-e8bcb5335295
                videoHtmlRef.current.setAttribute('autoplay', '');
                videoHtmlRef.current.setAttribute('muted', '');
                videoHtmlRef.current.setAttribute('playsinline', '');
                videoHtmlRef.current.play()
                .then( () => {
                        if (analysisSettings.generalAnalysisSettings.includeVideo) analyseVideo();
                        if (analysisSettings.generalAnalysisSettings.includeAudio) analyseAudio();
                        setIsRecordingStarting(false);
                    }
                );
            }
        })

        // cleaning up
        return ()=> {
            stopRecording('video');
            stopMedia();
            stopAnalysis();
        }
    }, []);

    //#region audio analysis
    const startRecording = (recType: 'audio' | 'video' = 'audio') => {
        let generalConstrains = {
            video: recType==='video',
            audio: true,
        };
        let curConstrains = recordingDeviceId ? {...generalConstrains,
            video: {
                deviceId: {
                    exact: recordingDeviceId
                }
            }
        } : generalConstrains;

        let r = navigator.mediaDevices.getUserMedia(curConstrains)
            .then( stream => {
                streamRef.current = stream;
                recorderRef.current = RecordRTC(stream, {
                    type: recType
                });
                recorderRef.current.startRecording();
                return stream;
            });
        return r;
    }

    // TODO add checking null reference and/or clearing promise for stopping video early (before loading full stuff)
    const stopRecording = (recType: 'audio' | 'video' = 'audio') => {
        recorderRef.current!.stopRecording( () => {
            let recodredBlob = recorderRef.current.getBlob();
            // TODO check if recoding works on iOS
            handleRecordedBlob(recodredBlob);
            if (recType === 'video') recorderRef.current.camera.stop();
            recorderRef.current.destroy();
            recorderRef.current = null;
        });
    };

    const audioVolumeAnalysis = () => {
        if (analyserNodeRef.current) {
            if (isAudioAnalysisOnRef.current) {
                requestAnimationFrame(audioVolumeAnalysis);
            }
            const pcmData = new Float32Array(analyserNodeRef.current!.fftSize);
            analyserNodeRef.current!.getFloatTimeDomainData(pcmData);
            let sumSquares = 0.0;
            for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }
            let curVolume = Math.sqrt(sumSquares / pcmData.length);
            setAudioResults(ar => {
                ar.currentVolume = curVolume;
            });
        }
    }
    const analyseAudio = () => {
        if (!speechRecognizerRef.current) speechRecognizerRef.current = createSpeechRecognizer("ru-RU", setAudioResults);
        let curResults = _.clone(audioResultsInitial);
        curResults.currentTimeTick = Date.now();
        setAudioResults(curResults);
        if (analyserNodeRef.current == null) createAnalyserNodeRef();
        speechRecognizerRef.current!.onend = () => {
            speechRecognizerRef.current!.start();
        };
        speechRecognizerRef.current!.start();
        audioVolumeAnalysis();
    }

    const createAnalyserNodeRef = () => {
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(streamRef.current!);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);
        analyserNodeRef.current = analyserNode;
    }

    const  stopAudioAnalysis = () => {
        speechRecognizerRef.current!.onend = () => {
            // console.log(`stopAudioAnalysis`);
        };
        speechRecognizerRef.current!.stop();
        analyserNodeRef.current = null;
        isAudioAnalysisOnRef.current = false;
    };
    //#endregion

    //#region video analysis
    const analyseVideo = () => {
        isVideoAnalysisOnRef.current = true;

        const video = videoHtmlRef.current;
        const wk = canvasRef.current!.width / video!.videoWidth;
        const hk = canvasRef.current!.height / video!.videoHeight;

        if (faceDetectorRef.current==null || onnxSessionRef.current == null){
            const handler1 = createFaceLandmarksDetector();
            // handling emotions detector setup
            const handler2 = InferenceSession.create("/emotion-ferplus-8.onnx",
                {
                    executionProviders: ["webgl"],
                }
            );
            const handlers = Promise.all([handler1, handler2]);
            handlers.then(loadedHandlers => {
                faceDetectorRef.current = loadedHandlers[0];
                onnxSessionRef.current = loadedHandlers[1];
                analyseFrame(wk, hk);
            });
        }
        else {
            analyseFrame(wk, hk);
        }
    }

    const analyseFrame = (
        wk: number,
        hk: number,
    ) => {
        if (!isVideoAnalysisOnRef.current) return;
        let ctx = canvasRef.current!.getContext("2d") as CanvasRenderingContext2D;
        clearCanvas(ctx);
        createImageBitmap(videoHtmlRef.current!)
        .then(imageBitmap => detectFaces(faceDetectorRef.current!, onnxSessionRef.current!, imageBitmap, ctx, wk, hk, setVideoResults, analysisSettings));
        setTimeout(() => {
            if (isVideoAnalysisOnRef.current) {
                analyseFrame(wk, hk);
            }
        }, analysisSettings.videoSettings.frameDelayCapture);
    }

    const  stopVideoAnalysis = () => {
        isVideoAnalysisOnRef.current = false;
        if (canvasRef.current) {
            const ctx = canvasRef.current!.getContext("2d") as CanvasRenderingContext2D;
            clearCanvas(ctx);
        }
    };
    //#endregion

    const stopMedia = () => {
        const tracks = streamRef.current?.getTracks();
        tracks?.forEach(function (track) {
            track.stop();
        });
        if (videoHtmlRef.current!=null) {
            videoHtmlRef.current.srcObject = null;
        }
    }

    const stopAnalysis = () => {
        if (analyserNodeRef.current) analyserNodeRef.current = null;
        if (analysisSettings.generalAnalysisSettings.includeAudio) stopAudioAnalysis();
        if (analysisSettings.generalAnalysisSettings.includeVideo) stopVideoAnalysis();
    };

    // let w_c = (size.width==null || isNaN(size.width)) ? 300 : Math.max(Math.round(size.width!*0.9), 300);
    let w_c = Math.round( Math.min(windowWidth, windowHeight)/2);
    let h_c = aspectWidthToHeigh ? Math.round(w_c / aspectWidthToHeigh) :  Math.round(w_c / (1920/1080));
    let canvasStyle = {
        transform: 'scaleX(-1)',
        border: '1px solid green',
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        zIndex: 10,
    } as CSSProperties;
    let curCanvasStyle = {...canvasStyle, border: '1px solid red'};

    return (
    <Grid stackable padded centered>
        <Grid.Row columns={2}>
            <Grid.Column width={ (analysisSettings.generalAnalysisSettings.includeVideo || analysisSettings.generalAnalysisSettings.includeAudio) ? 10 : 16} textAlign="center">
                <Header as='h3' textAlign='center' style={{ fontSize: '2em'}}>
                    <FormattedMessage id={"exercisenew.video.header"} />
                </Header>
                <div style={{
                    justifyContent: 'center',
                    display: 'inline-block',
                    width: w_c,
                    height: h_c,
                    position: 'relative',
                }}>
                    <video
                        ref={videoHtmlRef}
                        width={w_c}
                        height={h_c}
                        hidden = {false}
                        muted={true}
                        autoPlay
                        playsInline
                        style = {{
                            transform: 'scaleX(-1)',
                            position: 'relative',
                        }}
                    />
                    <canvas
                        ref={canvasRef}
                        width={w_c}
                        height={h_c}
                        style={curCanvasStyle}/>
                </div>
            </Grid.Column>
            { (analysisSettings.generalAnalysisSettings.includeVideo || analysisSettings.generalAnalysisSettings.includeAudio) &&
                <Grid.Column width={6}>
                    <Grid>
                        {analysisSettings.generalAnalysisSettings.includeVideo &&
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header content={formatMessage({id: "exercisenew.video.position"})} as='h3' style = {{...styleCenteredText, ...styleWordWrap}}/>
                                    { videoResults.positionChangeRequired ?
                                        <ThumbDownSvgComponent style={{display:'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}/> :
                                        <ThumbUpSvgComponent
                                            style={{display:'block',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        />
                                    }
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <EmotionMeter emotion={videoResults.emotions} />
                                </Grid.Column>
                            </Grid.Row>
                        }
                        {analysisSettings.generalAnalysisSettings.includeAudio &&
                            <Grid.Row>
                                {analysisSettings.audioSettings.includeVolume &&
                                    <Grid.Column width={analysisSettings.audioSettings.includeSpeech? 8 : 16}  textAlign='center'>
                                        <Header content={formatMessage({id: "exercisenew.audio.volume"})} as='h3' style = {{...styleCenteredText, ...styleWordWrap}}/>
                                        <SizeMe monitorWidth>
                                            {({ size }) =>
                                                {
                                                    const msw = size.width ? Math.floor(size.width!/ 5) : meterSizesDefault[0];
                                                    const msh = size.width ? Math.floor(size.width!/ 2) : meterSizesDefault[1];
                                                    return <div style={{width: '100%'}}>
                                                        <Meter
                                                        curValue={audioResults.currentVolume}
                                                        meterSizes={[msw, msh]}
                                                        meterValues={[0, 2*analysisSettings.audioSettings.volumeMax]}
                                                        meterNormRangeValues={[analysisSettings.audioSettings.volumeMin, analysisSettings.audioSettings.volumeMax]}
                                                        />
                                                    </div>
                                                }
                                            }
                                        </SizeMe>
                                    </Grid.Column>
                                }
                                {analysisSettings.audioSettings.includeSpeech &&
                                    <Grid.Column width={analysisSettings.audioSettings.includeVolume? 8 : 16} textAlign='center'>
                                        <Header content={formatMessage({id: "exercisenew.audio.speech"})} as='h3' style = {{...styleCenteredText, ...styleWordWrap}}/>
                                        <SizeMe monitorWidth>
                                            {({ size }) =>
                                                <ReactSpeedometer
                                                    value={audioResults.currentWordSpeed}
                                                    minValue={0}
                                                    maxValue={analysisSettings.audioSettings.upperMaxWords}
                                                    width = {size.width ? size.width * 0.8 : 200}
                                                    height = {size.width ? size.width  / 2 : 100}
                                                    customSegmentStops = {[0, analysisSettings.audioSettings.upperSlowWords, analysisSettings.audioSettings.upperOkWords, analysisSettings.audioSettings.upperMaxWords]}
                                                    currentValueText={`${audioResults.currentWordSpeed.toFixed(2)}`}
                                                    segmentColors = {['cyan', 'green', 'red']}
                                                    customSegmentLabels={[
                                                    {
                                                        text: formatMessage({id: "exercisenew.audio.speech.slow"}),
                                                        position: CustomSegmentLabelPosition.Outside,
                                                        color: "#555",
                                                    },
                                                    {
                                                        text: formatMessage({id: "exercisenew.audio.speech.ok"}),
                                                        position: CustomSegmentLabelPosition.Outside,
                                                        color: "#555",
                                                    },
                                                    {
                                                        text: formatMessage({id: "exercisenew.audio.speech.fast"}),
                                                        position: CustomSegmentLabelPosition.Outside,
                                                        color: "#555",
                                                    },
                                                    ]}
                                                />
                                            }
                                        </SizeMe>
                                    </Grid.Column>
                                }
                            </Grid.Row>
                        }
                    </Grid>
                </Grid.Column>
            }
        </Grid.Row>
        {analysisSettings.generalAnalysisSettings.includeAudio &&
            <Grid.Row>
                <Grid.Column width={16}>
                    <Accordion fluid styled>
                        <Accordion.Title
                            active={isTranscriptExpanded}
                            onClick={()=> setIsTranscriptExpanded(!isTranscriptExpanded)}
                        >
                            <Icon name='dropdown' />
                            <FormattedMessage id="exercisenew.audio.transcript" />
                        </Accordion.Title>
                        <Accordion.Content
                            active={isTranscriptExpanded}
                        >
                            <TextArea
                                rows = {3}
                                value={audioResults.transcript.join('\r\n')}
                                disabled = {true}
                                style={{width: '100%',
                                    resize: 'vertical'
                                }}
                            />
                        </Accordion.Content>
                    </Accordion>
                </Grid.Column>
            </Grid.Row>
        }
    </Grid>
    )

}

export default RecordingComponent;