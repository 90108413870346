import {
    Icon,
    Menu,
    Segment,
    Sidebar,
    Visibility
} from 'semantic-ui-react'
import { Outlet, useLocation } from "react-router-dom";
import React, { Component, useState } from 'react'
import { setSidebarClosed, setSidebarOpened } from '../store/slices/ui';

import { CSSProperties } from 'styled-components';
import { Media } from '../utils/Mobile';
import Navmenu from './Navmenu';
import { RootState } from '../store';
import { connect } from "react-redux";

type MobileContainerProps = {
    sidebarOpened: boolean,
    setSidebarOpened: ()=> void,
    setSidebarClosed: ()=> void
};

interface  IDesktopContainerState {
    fixed?: boolean,
    activeItem?: string
};
const DesktopMenu = () => {
    let {pathname} = useLocation();
    const initName = pathname?.slice(1);
    const [state, setState] = useState<IDesktopContainerState>({
        fixed: false,
        activeItem: initName ? initName : 'home'
    });

    const hideFixedMenu = () => {
        setState( (curstate: IDesktopContainerState) => ({...curstate, fixed: false }));
    };

    const showFixedMenu = () => {
        setState( (curstate: IDesktopContainerState) => ({...curstate, fixed: true }));
    };
    const { fixed } = state;
    return (
        <Media greaterThan='mobile'>
            <Visibility
                once={false}
                onBottomPassed={showFixedMenu}
                onBottomPassedReverse={hideFixedMenu}
            >
                <Segment
                    inverted
                    textAlign='center'
                    style={{ padding: '1em 0em' }}
                    vertical
                >
                    <Menu
                        fixed={fixed ? 'top' : undefined}
                        inverted={!fixed}
                        pointing={!fixed}
                        secondary={!fixed}
                        size='large'
                    >
                        <Navmenu/>
                    </Menu>
                </Segment>
            </Visibility>
        </Media>
    );
}

const stylePusherDefault = {
    height: `200px`,
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1000,
} as CSSProperties;

class MobileMenuComponent extends Component<MobileContainerProps>  {
    handleSidebarHide = () => this.props.setSidebarClosed();
    handleToggle = () => this.props.setSidebarOpened();
    render() {
        const { sidebarOpened} = this.props;
        return (
            <Media at='mobile'>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ padding: '1em 0em' }}
                    vertical
                >
                    <Menu
                        inverted
                        secondary
                    >
                        <Menu.Item onClick={this.handleToggle}>
                            <Icon name='sidebar' />
                        </Menu.Item>
                    </Menu>
                </Segment>
                {sidebarOpened &&
                <Sidebar.Pushable style={stylePusherDefault}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                        style={{overflowY: 'scroll'}}
                    >
                        <Menu inverted secondary vertical size='large'>
                            <Navmenu mobile={true}/>
                        </Menu>
                    </Sidebar>
                </Sidebar.Pushable>
                }
            </Media>
        )
    }
}

const mapStateToProps = (state: RootState)  => ({
        sidebarOpened: state.ui.sidebarOpened
    });

let MobileMenu = connect(mapStateToProps, { setSidebarOpened, setSidebarClosed })(MobileMenuComponent);

const ResponsiveMenuContainer = ({ children } : {children : React.ReactNode}) => (
    <React.Fragment>
        <DesktopMenu/>
        <MobileMenu/>
        {children}
    </React.Fragment>
)

const Layout = () => {
    return (<ResponsiveMenuContainer>
        <Outlet/>
        {/* <Footer/> */}
    </ResponsiveMenuContainer>
    )
}

export default Layout;
