import { useEffect, useRef, useState } from "react";

import { CameraSelectionType } from "../typings/recording";
import { ContentFullType } from "../typings/models/other";
import { axiosService } from "../utils/axios";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";

export function useEnumerateCameras() {
  const [cameras, setCameras] = useImmer< CameraSelectionType | null> (null);
  useEffect(()=> {
      if (!navigator.mediaDevices?.enumerateDevices) {
          alert("enumerateDevices() not supported.");
      } else {
      // List cameras
          navigator.mediaDevices.getUserMedia({audio: true, video: true})
          .then((stream) => {
              navigator.mediaDevices
              .enumerateDevices()
              .then((devices) => {
                  let videoDevices = devices.filter((d) => d.kind==='videoinput');
                  if (videoDevices && videoDevices.length > 0) {
                      setCameras({
                          selectedCameraIndex: 0,
                          availableCameras: videoDevices,
                      });
                  }
                  else {
                      console.error(`no video devices`);
                  }

                  const tracks = stream.getTracks();
                  tracks?.forEach(function (track) {
                      track.stop();
                  });

              })
              .catch((err) => {
                  console.error(`${err.name}: ${err.message}`);
              });
          })
      }
      // return to clear Promise?
  }, []);

  return {cameras, setCameras};
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return [
    width,
    height
  ];
}

/**
 * @returns inner width and inner height of the window
 */
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      let dims = getWindowDimensions();
      setWindowDimensions(dims);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export const useCreateAnalysis = (setIsHandling: (value: React.SetStateAction<boolean>) => void) => {
  const navigate = useNavigate();
  const createAnalysis = (id: string | number | undefined) => () : void => {
    setIsHandling(true);
    axiosService.post(`${process.env.REACT_APP_API_URL}/analysis/`, {
      exercise: id,
    } )
    .then( (r : any) => {
      if (r.status===201)
      {
        navigate(`/analyse/${r.data}`);
      }
    })
    .catch((_ : any) => {
        console.log(`err creating analysis for exercise with id=${id}!`);
    });
  };

  return createAnalysis;

}

export const useContentFromDB = (id: number) => {
  const [curContent, setCurContent] = useState<ContentFullType | null >(null);
  useEffect(()=>{
    axiosService.get<ContentFullType>(`${process.env.REACT_APP_API_URL}/content/${id}/`)
    .then( (res : any) => {
        const { data } = res;
        setCurContent(data);
    })
    .catch((err : any) => {
        alert(err.message);
    });
  }, [id]);

  return curContent;

}

type SomeFunction = (...args: any[]) => void;
type Timer = ReturnType<typeof setTimeout>;

/**
 *
 * @param func - the function, execution of which is debounced (delayed)
 * @param delay - number of miliseconds which are used for delay of func execution (the same as used for setTimeout function delay)
 * @returns debounced function
 */
export function useDebounce<Func extends SomeFunction>(func: Func, delay: number = 1000) {
  const [timer, setTimer] = useState<Timer>(); //Create timer state

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer); //Cancel previous timers
    setTimer(newTimer); //Save latest timer
  }) as Func;

  return debouncedFunction;
}