import * as Yup from "yup";

import { Button, Form } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import LabelWithError from "../components/LabelWithError";
import { RootState } from "../store";
import authSlice from "../store/slices/auth";
import { axiosService } from "../utils/axios";
import { defaultAnalysisSettings } from "../typings/models/AnalysisSettings";
import { formatMessage as formatMessageLocal } from "../utils/localization";
import { setAnalysisSettings } from "../store/slices/AnalysisSettings";
import { useFormik } from "formik";
import { useState } from "react";
import { withHandlingDimming } from "../utils/hocs";

function VerifyEmail({setIsHandling} : {setIsHandling: React.Dispatch<React.SetStateAction<boolean>>}) {
    const [isLoading, setLoading] = useState(false);
    const [authMessage, setAuthMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const { otp: otp_param } = useParams();

    useEffect( () => {
        if (otp_param) {
            handleOtpVerification(otp_param);
        }
    }, []);

    const handleOtpVerification = (otp: string) => {
        setIsHandling(true);
        axiosService
            .post(`${process.env.REACT_APP_API_URL}/auth/verifyemail/`, { otp })
            .then((res : any) => {
                dispatch(
                    authSlice.actions.setAuthTokens({
                        token: res.data.access,
                        refreshToken: res.data.refresh,
                    })
                );
                dispatch(authSlice.actions.setAccount(res.data.user));
                axiosService.put(`${process.env.REACT_APP_API_URL}/profile/`, defaultAnalysisSettings);
                dispatch(setAnalysisSettings(defaultAnalysisSettings));
                setLoading(false);
                navigate("/exercise/new/");
            })
            .catch((err : any) => {
                setAuthMessage(formatMessage({id: "VerifyEmail.errmessage"}));
                setLoading(false);
            });
    };

    const formikVerifyEmailForm = useFormik({
        initialValues: {
            otp: otp_param ?? "",
        },
        onSubmit: (values) => {
            setLoading(true);
            handleOtpVerification(values.otp);
        },
        validationSchema: Yup.object({
            otp: Yup.string().trim().required(formatMessage({id: "VerifyEmail.validationSchema.required"})),
        }),
    });
    const handleChangeCustom = (e: React.ChangeEvent<any>) => {
        if (authMessage!=="") setAuthMessage("");
        formikVerifyEmailForm.handleChange(e);
    }
    const auth = useSelector((state: RootState) => state.auth);
    let redirectToProfileForLoggedUsers = <Navigate to="/profile" replace={true} />;
    let authComponent = <div style ={{padding: "1em"}}>
        <p>🔐 <FormattedMessage id="VerifyEmail.intro" defaultMessage="Welcome!"/><a href="/login"><FormattedMessage id="VerifyEmail.ref_to_login"/></a>:</p>
        <Form onSubmit ={formikVerifyEmailForm.handleSubmit}>
            <Form.Field>
                <LabelWithError
                        isErr={formikVerifyEmailForm.touched.otp && Boolean(formikVerifyEmailForm.errors.otp)}
                        labelTextNorm = {formatMessage({id: "VerifyEmail.label.otp"})}
                        labelTextErr={formikVerifyEmailForm.errors.otp!}
                    />
                <input
                    id="otp"
                    type="text"
                    placeholder="otp"
                    name="otp"
                    value={formikVerifyEmailForm.values.otp}
                    onChange={handleChangeCustom}
                    onBlur={formikVerifyEmailForm.handleBlur}
                />
            </Form.Field>
            <Button type='submit'
                disabled = {isLoading}
                loading = {isLoading}
                color= {!isLoading ? 'green' : 'red'}
            >
                <FormattedMessage id={isLoading ? "VerifyEmail.button.submitting" : "VerifyEmail.button.submit"}/>
            </Button>
            {authMessage && <span style={{"color": "red"}}>{authMessage}</span>}
        </Form>
    </div>;
    return auth.account == null ? authComponent : redirectToProfileForLoggedUsers;
}

export default withHandlingDimming(formatMessageLocal({id: "general.serverhandling"}), false)(VerifyEmail);
