import 'filepond/dist/filepond.min.css'

import * as FilePond1 from 'filepond';
import * as Yup from "yup";

import AccessSubForm, { accessSubFormYupShape } from '../common/Subforms/AccessSubForm';
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import CommentsSubForm, { commentsSubFormYupShape } from '../common/Subforms/CommentsSubForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDateTimeString, isDateTimeStringValid } from '../../utils/general';

import { DateTimeInput } from 'semantic-ui-calendar-react';
import { FilePond } from 'react-filepond'
import { FilePondFile } from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import LabelWithError from "../LabelWithError";
import { initialValuesUploadData } from '../../typings/models/other';
import { uploadFile } from '../../utils/axios';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

FilePond1.registerPlugin(FilePondPluginFileValidateType);


export default function UploadModal({isDisabled = false, mediaToUpload = undefined} : {isDisabled? : boolean, mediaToUpload? : File[] | Blob[]})
{
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const initMedia = mediaToUpload ?? [];
    const [files, setFiles] = useState<File[] | Blob[] >(initMedia);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleUpdateFiles = (curFiles: FilePondFile[]) => {
        const videos = curFiles.map((file) => file.file as File);
        setFiles(videos);
        setFieldValue('recording_file', videos[0]);
    };
    const uploadSchema = Yup.object().shape({
        date_created: Yup.date()
            .typeError(formatMessage({id: "uploadmodal.date_created.err"}))
            .required(formatMessage({id: "uploadmodal.uploadSchema.required"})),
        recording_file: Yup.string()
            // .nullable()
            .required(formatMessage({id: "uploadmodal.uploadSchema.required"})),
        ...commentsSubFormYupShape,
        ...accessSubFormYupShape
    });
    const formik  = useFormik({
        initialValues: initialValuesUploadData,
        validationSchema: uploadSchema,
        onSubmit: (values) => {
            uploadFile({
                urlAPI: `${process.env.REACT_APP_API_URL}/exercise/`,
                file: values.recording_file!,
                auxData: {
                    is_private: values.is_private,
                    date_created : getDateTimeString(values.date_created) + '+0300',
                    comments: values.comments,
                    reviewers: values.reviewers,
                }
            })
            .then( (r : any) => {
                resetForm({values: initialValuesUploadData});
                setIsModalOpened(false);
                navigate("/exercise");
            })
            .catch(error => {
                alert(formatMessage({id: "uploadmodal.alert.error"}, {err: error.message}));
                setIsModalOpened(false);
            });
        }
    });
    const {
        values,
        errors,
        handleSubmit,
        resetForm,
        setFieldValue,
        isValid,
        validateField
    } = formik;

    const openModal = () => {
        setIsModalOpened(true);
    };
    const handleDateTimeChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
        let strCurDate = data.value;
        try {
            if (isDateTimeStringValid(strCurDate)) {
                let curDate = new Date(strCurDate);
                setFieldValue('date_created', curDate);
            } else {
                setFieldValue('date_created', strCurDate);
                validateField('date_created');
            }
        }
        catch (e: any) {
            console.log(`err with date=${e}`);
        }
    };
    const handleClose = () => {
        setIsModalOpened(false);
    };
    const cancelModal = () => {
        resetForm({values: initialValuesUploadData});
        setIsModalOpened(false);
    };
    return (
        <Modal
            trigger={
                <Button icon='cloud upload' color='green' onClick={openModal} disabled={isDisabled} />
            }
            as={Form}
            open={isModalOpened}
            size="large"
            closeOnDimmerClick={false}
            onSubmit={handleSubmit}
            closeOnEscape={true}
            onClose={handleClose}
        >
            <Modal.Header>
                <Icon name='child' /> <FormattedMessage id="uploadmodal.Header"/>
            </Modal.Header>
            <Modal.Content>
                <Grid verticalAlign="middle" textAlign="left">
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Field>
                                <LabelWithError
                                    isErr={Boolean(errors.date_created)}
                                    labelTextNorm = {formatMessage({id: "uploadmodal.date_created.norm"})}
                                    labelTextErr={errors.date_created! as string}
                                    infoText={formatMessage({id: "uploadmodal.date_created.infoText"})}
                                />
                                <DateTimeInput
                                    name="date_created"
                                    popupPosition='bottom center'
                                    dateTimeFormat = 'YYYY-MM-DD HH:mm'
                                    value={getDateTimeString(values.date_created)}
                                    iconPosition="left"
                                    onChange={handleDateTimeChange}
                                    closable
                                    autoComplete='off'
                                    clearable
                                    clearIcon={<Icon name="remove" color="red" />}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Field>
                                <LabelWithError
                                    isErr={Boolean(errors.recording_file)}
                                    labelTextNorm = {formatMessage({id: "uploadmodal.recording_file.norm"})}
                                    labelTextErr={errors.recording_file! as string}
                                    infoText={formatMessage({id: "uploadmodal.recording_file.infoText"})}
                                />
                                <FilePond
                                    files={files}
                                    onupdatefiles={handleUpdateFiles}
                                    maxFiles={1}
                                    name="files" /* sets the file input name, it's filepond by default */
                                    acceptedFileTypes={['video/mp4']}
                                    labelIdle={formatMessage({id: "uploadmodal.recording_file.labelIdle"}) +
                                        '<span class="filepond--label-action">' + formatMessage({id: "uploadmodal.recording_file.labelselect"}) + '</span>'}
                                    />

                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <CommentsSubForm formik={formik} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <AccessSubForm formik={formik} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions style={{ overflow: "auto" }}>
            <Button.Group floated="right">
                <Button onClick={cancelModal} type="button">
                    <FormattedMessage id = "uploadmodal.Button.cancel" />
                </Button>
                <Button.Or text={"|"} />
                <Button
                    type="submit"
                    positive
                    disabled = {!isValid}
                >
                    <FormattedMessage id = "uploadmodal.Button.submit" />
                </Button>
            </Button.Group>
            </Modal.Actions>
        </Modal>
    );
}
