import * as _ from 'lodash';

import { AccessRightsType, ExerciseInfo } from '../typings/models/other';
import { Button, Grid, Icon, Popup, SemanticWIDTHS, TextArea, TextAreaProps } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProcessingStatus, ProcessingStatusIcons } from "../settings";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions, { useCreateAnalysis } from '../utils/hooks';

import ExerciseAccessModal from '../components/Modals/ExerciseAccessModal';
import Link from '../components/Link';
import ReactPlayer from "react-player";
import { RootState } from "../store";
import { RouteItemIdParam } from "../typings/models/User";
import { axiosService } from "../utils/axios";
import { formatMessage as formatMessageLocal } from "../utils/localization";
import styleCenteredHorizontally from "../typings/styles";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { withHandlingDimming } from '../utils/hocs';

function Exercise({setIsHandling} : {setIsHandling: React.Dispatch<React.SetStateAction<boolean>>}) {
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const {id: exerciseId} = useParams<RouteItemIdParam>();
    const [curExercise, setCurExercise] = useImmer<ExerciseInfo|null>(null);
    const analysises = curExercise?.analysises ?? null;
    const analysises_of_author = curExercise?.analysises_of_author ?? null;
    const createAnalysis = useCreateAnalysis(setIsHandling);
    const account = useSelector((state: RootState) => state.auth.account);
    const isOwn = curExercise ?  account?.id === curExercise.author.id : true;
    const [colWidth1, colWidth2 ] = !isOwn ? [5, 11] as SemanticWIDTHS[] : [4, 8] as SemanticWIDTHS[];
    const playerRef = React.useRef<ReactPlayer>(null);
    const [isEditingComments, setIsEditingComments] = useState(false);
    const [exerciseComments, setExerciseComments] = useState<string | number | undefined>(undefined);
    const [_w, windHeight] = useWindowDimensions();

    const changeExercisePrivacy = (_exerciseId: number, accessRights: AccessRightsType) => {
      setCurExercise(e => {
        e!.is_private = accessRights.is_private;
        e!.reviewers = accessRights.reviewers;
      });
    };

    useEffect(()=>{
      axiosService.get<ExerciseInfo>(`${process.env.REACT_APP_API_URL}/exercise/${exerciseId}/`)
      .then( (res : any) => {
          const { data } = res;
          setCurExercise(data);
          setExerciseComments(data.comments);
          setIsHandling(false);
      })
      .catch((err : any) => {
        // TODO !!! - check if the response is empty just clear everything - for example, if access to the exercise was removed! Response 405
        // also Uncaught (in promise) TypeError: e.response.data.detail is undefined
          alert(err.response.data.detail.toString());
          setIsHandling(false);
      });
    }, []);

    const componentForExerciseNotProcessed = <Grid.Row columns={1}>
      <Grid.Column width={16} textAlign='center'>
        <FormattedMessage id="exercise.introtext.processingnotfinished"/>
      </Grid.Column>
    </Grid.Row>

    const componentForExerciseProcessed = <Fragment>
      <Grid.Row columns={1}>
        <Grid.Column width={16} textAlign='center'>
            <ReactPlayer
              ref={playerRef}
              url={curExercise ? `${process.env.REACT_APP_MEDIA_URL}${curExercise?.recording_file}` : undefined}
              controls={true}
              width="auto"
              height={0.5 * windHeight}
              progressInterval={curExercise?.video_info?.FPS ?? 30}
            />
        </Grid.Column>
      </Grid.Row>
      { isOwn &&
        <Grid.Row columns={3}>
          <Grid.Column width={colWidth1} verticalAlign='middle' textAlign = 'left'>
            <FormattedMessage id="exercise.privacy"/>
          </Grid.Column>
          <Grid.Column width={colWidth2} verticalAlign='middle' textAlign = 'left'>
            <Icon
                style={styleCenteredHorizontally}
                name={!curExercise?.is_private ? 'eye' : 'eye slash' }
                color={!curExercise?.is_private ? 'green' : 'grey' }
            />
          </Grid.Column>
          <Grid.Column width={4} verticalAlign='middle' textAlign = 'center'>
            <Popup
              content={formatMessage({id: curExercise?.is_private ? "exercise.privacy.open" : "exercise.privacy.close"})}
              trigger={
                <ExerciseAccessModal
                  exerciseId={+exerciseId!}
                  accessRightsChangeCallback={changeExercisePrivacy}
                  accessFormInitialValues={{is_private : curExercise?.is_private, reviewers: curExercise?.reviewers}}
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      }
      <Grid.Row columns={isOwn ? 3 : 2}>
        <Grid.Column width={colWidth1} verticalAlign='middle' textAlign = 'left'>
          <FormattedMessage id='exercise.comments' />
        </Grid.Column>
        <Grid.Column width={colWidth2} verticalAlign='middle'>
          <TextArea
            style = {{'width': '100%'}}
            placeholder={curExercise?.comments} rows={3} disabled = {!isEditingComments}
            value={exerciseComments}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
              setExerciseComments(data.value);}}
          />
        </Grid.Column>
        { isOwn && <Grid.Column width={4} verticalAlign='middle'>
            {!isEditingComments ?
              <Popup content={formatMessage({id: "exercise.comments.editing"})}
                trigger={
                  <Button
                    style={styleCenteredHorizontally}
                    inverted icon='edit' color='green' onClick={() => setIsEditingComments(true)} />}/>
              :
              <Popup content={formatMessage({id: "exercise.comments.finish"})} trigger={
                <Button.Group fluid>
                  <Button
                    icon color= 'green'
                    onClick={() => {
                      const curComments = _.isNumber(exerciseComments) ? exerciseComments.toString() : exerciseComments;
                      axiosService
                      .put(`${process.env.REACT_APP_API_URL}/exercise/${exerciseId}/`, {'comments': curComments})
                      .then( (r) => {
                        if (r.status===200) {
                          setIsEditingComments(false);
                          setCurExercise(c => {
                            c!.comments = curComments;
                          })
                        }})
                      .catch( (_) => {
                        console.log(`err in saving comments for exercise with id=${exerciseId}!`);
                      });
                  }}>
                    <Icon name= 'save'/>
                  </Button>
                  <Button
                    icon color= 'red' onClick={() => {
                      setIsEditingComments(false);
                      setExerciseComments(curExercise?.comments);
                    }}>
                    <Icon name= 'cancel'/>
                  </Button>
                </Button.Group>
              }/>
            }
        </Grid.Column> }
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          {(isOwn && (!analysises || analysises.length===0)) || (!isOwn && (!analysises_of_author || analysises_of_author.length===0)) ?
            <FormattedMessage id='exercise.noanalysis' />
            :
            <React.Fragment>
              <FormattedMessage id='exercise.analysis' />
              { isOwn && analysises && analysises.length>0 ?
                  <ul>
                    {analysises.map(a =>
                      (<li key={a.id}>
                        {formatMessage({id: "exerciselist.analysistext"},
                          {
                            AnalysisLink: <Link url={process.env.REACT_APP_WEB_URL! + "/analyse/" + a.id} children={(new Date(a.date_created)).toDateString()}/>,
                            AnalysisAuthor: a.author.name,
                            CommentsText: a.comments ? (` - ${a.comments}`) : '.'
                          }
                        )}
                      </li>)
                    )}
                  </ul>
                :
                <ul>
                  {analysises_of_author!.map(a =>
                    (<li key={a.id}>
                      {formatMessage({id: "exerciselist.AnalysisOwnText"},
                        {
                          AnalysisLink: <Link url={process.env.REACT_APP_WEB_URL! + "/analyse/" + a.id} children={(new Date(a.date_created)).toDateString()}/>,
                          CommentsText: a.comments ? (` - ${a.comments}`) : '.'
                        }
                      )}
                    </li>)
                  )}
                </ul>
              }
            </React.Fragment>
          }
          <br/>
          <FormattedMessage id ="exercise.create.analysis.text" />
        </Grid.Column>
          <Grid.Column width={4} verticalAlign='bottom'>
              <Popup content={formatMessage({id:"exercise.create.analysis.button"})} trigger={<Button
                style={styleCenteredHorizontally}
                color='green' inverted
                icon='address book outline'
                onClick={createAnalysis(exerciseId)} />}
              />
          </Grid.Column>
      </Grid.Row>
      {isOwn && <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <FormattedMessage id='exercise.delete' />
          </Grid.Column>
          <Grid.Column width={4}>
            <Popup content={formatMessage({id: "exercise.delete.popup"})} trigger={
              <Button
                style={styleCenteredHorizontally}
                icon='delete' color='red' inverted
                onClick={() => {
                  axiosService.delete(`${process.env.REACT_APP_API_URL}/exercise/${exerciseId}/`)
                    .then( (r : any) => {
                      if (r.status===200)
                      {
                        navigate(`/exercise`);
                      }
                    })
                    .catch((_ : any) => {
                        console.log(`err in deleteExercise id=${exerciseId}!`);
                    });
                }}
              />}
            />
          </Grid.Column>
        </Grid.Row>
      }
    </Fragment>

    return (<Grid stackable padded centered>
      <Grid.Row columns={1}>
        <Grid.Column width={16} verticalAlign='middle'>
          {formatMessage({id: "exercise.introtext"},
            {
              id: exerciseId,
              recorded: curExercise && format(parseISO(curExercise!.date_created), "yyyy-MM-dd hh:mm"),
            }
          )}
          {!isOwn && formatMessage({id: "exercise.introtext.author"},
              {
                email: curExercise?.author.email,
                name: curExercise?.author.name && (' (' + curExercise?.author.name + ')')
              }
          )}
          {formatMessage({id: "exercise.introtext.status"},
              {
                icon: curExercise && ProcessingStatusIcons[curExercise?.processing_status],
                statustext:  curExercise?.processing_status
              }
          )}
        </Grid.Column>
      </Grid.Row>
      {curExercise?.processing_status !== ProcessingStatus.DONEOK ? componentForExerciseNotProcessed : componentForExerciseProcessed}
    </Grid>);
}

export default withHandlingDimming(formatMessageLocal({id: "general.serverhandling"}), false)(Exercise);
