import { Icon, Popup } from "semantic-ui-react";

import { CSSProperties } from "react";

interface ILabelWithErrorProps {
    labelTextNorm: string,
    isErr?: boolean | undefined,
    colorNorm?: CSSProperties["color"],
    labelTextErr?: string,
    colorErr?: CSSProperties["color"],
    infoText?: string
}

const LabelWithError = ({isErr = false, labelTextNorm, colorNorm='black', labelTextErr = "", colorErr='red', infoText=''}: ILabelWithErrorProps) => {
    const curStyle = {color: isErr ? colorErr : colorNorm};
    const curText = isErr ? labelTextNorm + ` ${labelTextErr}` : labelTextNorm;
    return <label style={curStyle}>
            {curText}
            {infoText? <Popup content={infoText} trigger={<Icon name='info circle' />}/> : ""}
        </label>
}

export default LabelWithError;