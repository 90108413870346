import { Icon, Menu, MenuItemProps, MenuMenu } from "semantic-ui-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage } from 'react-intl';
import React from "react";
import { RootState } from "../store";
import { setSidebarClosed } from '../store/slices/ui';

function Navmenu({mobile} :{mobile?:boolean}){
    let {pathname} = useLocation();
    const navigate = useNavigate();
    const initName = pathname?.split('/')[1];
    const activeItem  =  initName ? initName : 'home';
    const dispatch = useDispatch();
    const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
        dispatch(setSidebarClosed());
        navigate("/"+ data.to);
    };
    const account = useSelector((state: RootState) => state.auth.account);

    return <React.Fragment>
        <Menu.Item
            as='div'
            name='home'
            active={activeItem==='home' || activeItem==='/'}
            link={true}
            to=""
            onClick={handleItemClick}>
                <NavLink to="">
                    <Icon name='home'/>
                </NavLink>
        </Menu.Item>
        {account && <React.Fragment>
            <Menu.Item
                as='div'
                name='exercise'
                active={activeItem==='exercise'}
                link={true}
                to="exercise"
                onClick={handleItemClick}>
                <NavLink to="exercise">
                    <FormattedMessage id="navmenu.exercise" />
                </NavLink>
            </Menu.Item>
            <Menu.Item
                as='div'
                name='analyse'
                active={activeItem==='analyse'}
                link={true}
                to="analyse"
                onClick={handleItemClick}>
                <NavLink to="analyse">
                    <FormattedMessage id="navmenu.analyse" />
                </NavLink>
            </Menu.Item>
        </React.Fragment>}
        <MenuMenu position='right'>
            <Menu.Item
                as='div'
                name='help'
                active={activeItem==='help'}
                link={true}
                to={"help"}
                onClick={handleItemClick}
                >
                    <NavLink to="help">
                        {account ? <Icon name='help'/> : <FormattedMessage id="navmenu.help"/>}
                    </NavLink>
            </Menu.Item>
            <Menu.Item position={mobile? 'left' : 'right'}
                as='div'
                name={account ? "profile" : "login"}
                active={activeItem==='profile' || activeItem==='login' || activeItem==='register'}
                link={true}
                to={account ? "profile" : "login"}
                onClick={handleItemClick}
                >
                    <NavLink to="profile">
                        {account ? <Icon name='user'/> : <FormattedMessage id="navmenu.profile"/>}
                    </NavLink>
            </Menu.Item>
        </MenuMenu>
    </React.Fragment>
}

export default Navmenu;
