import * as Yup from "yup";

import { Button, Form, Header, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';

import LabelWithError from "../components/LabelWithError";
import { OTP_max_length } from "../settings";
import React from 'react';
import { RootState } from "../store";
import { axiosService } from "../utils/axios";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";

function Register() {
    const [isLoading, setLoading] = useState(false);
    const [authMessage, setAuthMessage] = useState('');
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const { otc_invite } = useParams();

    const handleRegister = async (name: string, email: string, password: string, otc: string) => {
        setLoading(true);
        try {
            let r = await axiosService.post(`${process.env.REACT_APP_API_URL}/auth/register/`, {name, email, password, otc_invite: otc});
            alert(formatMessage({id: "register.messagesent"}))
            setLoading(false);
            navigate("/verifyemail/");
        }
        catch (err: any) {
            let inqueryEmail = process.env.REACT_APP_EMAIL?.replace("@", "(at)");
            let strFormat = {
                email_to_contact: inqueryEmail
            } as any;
            let errMessage : any;

            if (err.response?.data?.name?.type === "name_not_unique") {
                strFormat['name_suggestion'] = err.response?.data?.name?.name_suggestion;
                errMessage = formatMessage({id: "register.errmessage.name_suggestion"}, strFormat);
            }
            else if (err.response?.data?.email?.type === 'email_exists') {
                errMessage = formatMessage({id: "register.errmessage.email_exists"})
            }
            else {
                errMessage = formatMessage({id: "register.errmessage"}, strFormat);
            }
            setAuthMessage(errMessage);
            setLoading(false);
        };
    };

    const formikRegister = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            otc: otc_invite ? otc_invite : '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            await handleRegister(values.name, values.email, values.password, values.otc);
        },
        validationSchema: Yup.object({
            name: Yup.string().max(50,formatMessage({id: "register.validationSchema.name_max"})),
            email: Yup.string().trim().required(formatMessage({id: "register.validationSchema.required"})).email(formatMessage({id: "register.validationSchema.required"})),
            password: Yup.string().trim().min(8,formatMessage({id: "register.validationSchema.password_min"})).required(formatMessage({id: "register.validationSchema.required"})),
            confirmPassword: Yup.string().trim().required(formatMessage({id: "register.validationSchema.confirmPassword"})),
            otc: Yup.string().max(OTP_max_length,formatMessage({id: "register.validationSchema.otc"})),
        }),
    });
    const handleChangeCustom = (e: React.ChangeEvent<any>) => {
        if (authMessage!=="") setAuthMessage("");
        formikRegister.handleChange(e);
    };
    const auth = useSelector((state: RootState) => state.auth);

    let forLoggedUsers = <Segment>
        <FormattedMessage id="register.alreadyregistered"/>
    </Segment>;

    let authComponent = <div style ={{padding: "1em"}}>
        <Header as='h1'>🤗 <FormattedMessage id="register.header_login"/> </Header>
        <div dangerouslySetInnerHTML={{__html: formatMessage({id: "register.login"}, {link: process.env.REACT_APP_WEB_URL})}} />
        <Header as='h1'>🤫 <FormattedMessage id="register.header"/></Header>
        <p><FormattedMessage id="register.intro" defaultMessage="verifyemail!"/></p>
        <Form onSubmit ={formikRegister.handleSubmit}>
            <Form.Field>
                <LabelWithError
                        isErr={formikRegister.touched.name && Boolean(formikRegister.errors.name)}
                        labelTextNorm = {formatMessage({id: "register.label.name"})}
                        labelTextErr={formikRegister.errors.name!}
                    />
                <input
                    id="name"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formikRegister.values.name}
                    onChange={handleChangeCustom}
                    onBlur={formikRegister.handleBlur}
                />
            </Form.Field>
            <Form.Field>
                <LabelWithError
                        isErr={formikRegister.touched.email && Boolean(formikRegister.errors.email)}
                        labelTextNorm = {formatMessage({id: "register.label.email"})}
                        labelTextErr={formikRegister.errors.email!}
                    />
                <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formikRegister.values.email}
                    onChange={handleChangeCustom}
                    onBlur={formikRegister.handleBlur}
                />
            </Form.Field>
            <Form.Field>
                <LabelWithError
                        isErr={formikRegister.touched.password && Boolean(formikRegister.errors.password)}
                        labelTextNorm = {formatMessage({id: "register.label.password"})}
                        labelTextErr={formikRegister.errors.password!}
                    />
                <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formikRegister.values.password}
                    onChange={handleChangeCustom}
                    onBlur={formikRegister.handleBlur}
                />
            </Form.Field>
            <Form.Field>
                <LabelWithError
                        isErr={formikRegister.touched.confirmPassword && Boolean(formikRegister.errors.confirmPassword)}
                        labelTextNorm = {formatMessage({id: "register.label.password"})}
                        labelTextErr={formikRegister.errors.confirmPassword!}
                    />
                <input
                    id="confirmPassword"
                    type="password"
                    placeholder="Сonfirm Password"
                    name="confirmPassword"
                    value={formikRegister.values.confirmPassword}
                    onChange={handleChangeCustom}
                    onBlur={formikRegister.handleBlur}
                />
            </Form.Field>
            <Form.Field>
                <LabelWithError
                        isErr={formikRegister.touched.otc && Boolean(formikRegister.errors.otc)}
                        labelTextNorm = {formatMessage({id: "register.label.otc"})}
                        labelTextErr={formikRegister.errors.otc!}
                        infoText={formatMessage({id: "register.otc.infoText"})}
                />
                <input
                    id="otc"
                    type="text"
                    placeholder="OTC"
                    name="otc"
                    value={formikRegister.values.otc}
                    onChange={handleChangeCustom}
                    onBlur={formikRegister.handleBlur}
                />
            </Form.Field>
            <Button type='submit'
                disabled = {isLoading || !formikRegister.isValid || !(authMessage.length===0)}
                loading = {isLoading}
                color= {!isLoading ? 'green' : 'red'}
            >
                <FormattedMessage id={isLoading ? "register.button.submitting" : "register.button.submit"}/>
            </Button>
            {authMessage && <span style={{"color": "red"}}>{authMessage}</span>}
        </Form>
    </div>;

    return auth.account == null ? authComponent : forLoggedUsers;
}

export default Register;
