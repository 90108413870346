export type VideoInfo = {
    FPS: number,
    duration: number,
    FRAME_COUNT: number,
    FRAME_WIDTH: number,
    FRAME_HEIGHT: number
}
export const VideoInfoDefault : VideoInfo = {
    FPS: 0,
    duration: 0,
    FRAME_COUNT: 0,
    FRAME_WIDTH: 0,
    FRAME_HEIGHT: 0
};