import * as _ from "lodash";

import React, { useEffect, useRef } from "react";

type Tuple<TItem, TLength extends number> = [TItem, ...TItem[]] & {
  length: TLength;
};

type MeterSizesType = Tuple<number, 2>;
// [width, height]
export const meterSizesDefault: MeterSizesType = [70, 100];
type MeterValuesType = Tuple<number, 2>;
const meterValuesDefault: MeterValuesType = [0, 10];
const meterNormRangeValuesDefault: MeterValuesType = [6, 8];
const meterValueTextHeightDefault = 17;

type NormRangeDescriptionType = Tuple<string, 3>;
const rangePositionLabelsDefault: NormRangeDescriptionType = [
  "lower",
  "middle",
  "upper"
]; //in ascending order to label position relative to the range
const rangePositionColorsDefault: NormRangeDescriptionType = [
  "cyan",
  "green",
  "red"
];

function positionInRange(
  curValue: number = 10,
  meterNormRangeValues: MeterValuesType = meterNormRangeValuesDefault,
  rangePositionLabels: NormRangeDescriptionType = rangePositionLabelsDefault
) {
  const [minValue, maxValue] = meterNormRangeValues;
  let r =
    curValue < minValue
      ? rangePositionLabels[0]
      : curValue > maxValue
      ? rangePositionLabels[2]
      : rangePositionLabels[1];
  return r;
}

interface MeterPropsType {
  curValue: number;
  meterSizes?: MeterSizesType;
  meterValues?: MeterValuesType;
  meterValueTextHeight?: number;
  meterNormRangeValues?: MeterValuesType;
  rangePositionLabels?: NormRangeDescriptionType;
  rangePositionColors?: NormRangeDescriptionType;
}
const Meter = ({
  curValue = 8,
  meterSizes = meterSizesDefault,
  meterValues = meterValuesDefault,
  meterValueTextHeight = meterValueTextHeightDefault,
  meterNormRangeValues = meterNormRangeValuesDefault,
  rangePositionLabels = rangePositionLabelsDefault,
  rangePositionColors = rangePositionColorsDefault
}: MeterPropsType) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [width, height] = meterSizes;

  const rangePositionVisualization = _.zipObject(
    rangePositionLabels,
    rangePositionColors
  );
  useEffect(() => {
    const [vmin, vmax] = meterValues;
    const vdelta = vmax - vmin;
    // min and max of the normal range
    const [normRangeMin, normRangeMax] = meterNormRangeValues;
    const k = (height - meterValueTextHeight) / vdelta;
    const curY = (vmax - curValue) * k;
    const curPositionInrange = positionInRange(curValue, meterNormRangeValues);
    const canvas = canvasRef.current;
    const ctx = canvas!.getContext("2d")!;
    const cur_height = height ?? meterSizesDefault[1];
    const gradient = ctx!.createLinearGradient(0, 0, 0, cur_height);

    // Add three color stops
    gradient.addColorStop(
      0,
      rangePositionVisualization[rangePositionLabels[2]]
    );
    gradient.addColorStop(
      (vmax - (normRangeMax + normRangeMin) / 2) / vdelta,
      rangePositionVisualization[rangePositionLabels[1]]
    );
    gradient.addColorStop(
      1,
      rangePositionVisualization[rangePositionLabels[0]]
    );

    // Set the fill style and draw a rectangle
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height - meterValueTextHeight);

    ctx.save();

    ctx.lineWidth = 3;
    ctx.strokeStyle = "#black";
    ctx.beginPath(); // Start a new path
    ctx.moveTo(0, curY); // Move the pen to (30, 50)
    ctx.lineTo(width, curY); // Draw a line to (150, 100)
    ctx.stroke(); // Render the path

    const rangeColor = rangePositionVisualization[curPositionInrange];
    ctx.fillStyle = rangeColor;
    ctx.lineWidth = 1;
    ctx.save();

    // draw first mark
    ctx.translate(0, curY);
    const angleRad1 = (45 * Math.PI) / 180;
    ctx.rotate(angleRad1);
    ctx.rect(0, -5, 5, 5);
    ctx.fillRect(0, -5, 5, 5);
    ctx.stroke();
    ctx.restore();

    // draw first mark
    ctx.translate(width, curY);
    const angleRad = (225 * Math.PI) / 180;
    ctx.rotate(angleRad);
    ctx.rect(0, -5, 5, 5);
    ctx.fillRect(0, -5, 5, 5);
    ctx.stroke();
    ctx.restore();

    // drawing text to show the current value
    if (meterValueTextHeight > 0) {
      ctx.font = `${meterValueTextHeight - 1}px Arial`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.fillText(curValue.toFixed(2), width / 2, height);
      ctx.restore();
    }
    return () => ctx.clearRect(0, 0, canvas!.width, canvas!.height);
  }, [curValue, height, width]);

  return (
    <React.Fragment>
      <canvas ref={canvasRef} width={width} height={height} />
    </React.Fragment>
  );
};

export default Meter;
