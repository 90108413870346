import _ from "lodash";

export enum OrderingDirections {
    Ascending = "ascending",
    Descending = "descending"
}
export type OrderingDirectionType = `${OrderingDirections}`;

export type TableStateTypeGeneric<T> = {
    column?: string | null;
    data: T[] | null;
    direction?: OrderingDirectionType | null;
};

export type TableActionGeneric<T> = {
    type: "CHANGE_SORT" | "INITIALIZE" | "UPDATE";
    column?: string;
    data?: T[];
};


export const TableInitialStateGeneric = {
    column: null,
    data: null,
    direction: null
};

export function tableReducer<T>(state: TableStateTypeGeneric<T>, action: TableActionGeneric<T>) {
    switch (action.type) {
        case "INITIALIZE":
            return {
                data: action.data
            } as TableStateTypeGeneric<T>;
        case "UPDATE":
            return {
                ...state,
                data: action.data
            } as TableStateTypeGeneric<T>;
        case "CHANGE_SORT":
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data!.slice().reverse(),
                    direction:
                        state.direction === OrderingDirections.Ascending
                        ? OrderingDirections.Descending
                        : OrderingDirections.Ascending
                } as TableStateTypeGeneric<T>;
            }
            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                direction: OrderingDirections.Ascending
            } as TableStateTypeGeneric<T>;
        default:
            throw new Error();
    }
}