import * as Yup from "yup";

import { Button, Dropdown, Form, Grid, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';

import LabelWithError from "../LabelWithError";
import { RootState } from "../../store";
import { axiosService } from "../../utils/axios";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";

const initialValues = {
    topic: "",
    summary: "",
    details: ""
};

interface IFeedbackModalProps{
    buttonLabel: string;
    buttonProps?: {
        [x:string]: any;
    };
}


export default function FeedbackModal({buttonLabel, buttonProps} : IFeedbackModalProps)
{
    const {formatMessage} = useIntl();

    const topics = [
        { key: "Request", text: formatMessage({id: "FeedbackModal.topics.Password"}), value: "Password" },
        { key: "Request", text: formatMessage({id: "FeedbackModal.topics.Request"}), value: "Request" },
        { key: "Proposal", text: formatMessage({id: "FeedbackModal.topics.Proposal"}), value: "Proposal" },
        { key: "General", text: formatMessage({id: "FeedbackModal.topics.General"}), value: "General" },
    ];

    const responseSchema = Yup.object().shape({
        topic: Yup.string().required(formatMessage({id: "FeedbackModal.responseSchema.topic.required"})),
        summary: Yup.string()
            .min(10, formatMessage({id: "FeedbackModal.responseSchema.summary_min"}))
            .max(255, formatMessage({id: "FeedbackModal.responseSchema.summary_max"}))
            .required(formatMessage({id: "FeedbackModal.responseSchema.required"})),
        details: Yup.string().max(500, formatMessage({id: "FeedbackModal.responseSchema.details_max"}))
    });
    const account = useSelector((state: RootState) => state.auth.account);

    const [isModalOpened, setIsModalOpened] = useState(false);
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue
    }  = useFormik({
        initialValues,
        validationSchema: responseSchema,
        onSubmit: (values) => {
            if (account!=null) {
                axiosService
                .post(`${process.env.REACT_APP_API_URL}/feedback/`, values)
                .then((_) => {
                    alert(formatMessage({id: "FeedbackModal.onSubmit.ok"}));
                })
                .catch((error) => {
                    alert(formatMessage({id: "FeedbackModal.onSubmit.post.err"}, {err: error}));
                });
            }
            else
            {
                alert(formatMessage({id: "FeedbackModal.alert.notregistered"}));
            }
            resetForm({values: initialValues});
            setIsModalOpened(false);
        }
    });

    const openModal = () => {
        setIsModalOpened(true);
    };
    const handleClose = () => {
        setIsModalOpened(false);
    };

    const cancelModal = () => {
        resetForm({values: initialValues});
        setIsModalOpened(false);
    };

    return (
        <Modal
            trigger={
                <Button content={buttonLabel} icon='wpforms' labelPosition='right' onClick={openModal} {...buttonProps}/>
            }
            as={Form}
            open={isModalOpened}
            size="large"
            closeOnDimmerClick={false}
            onSubmit={handleSubmit}
            closeOnEscape={true}
            onClose={handleClose}
        >
            <Modal.Header>
                <Icon name='wpforms' /> <FormattedMessage id="FeedbackModal.Header"/>
            </Modal.Header>
            <Modal.Content>
            <Grid columns={2} verticalAlign="middle" textAlign="right">
                <Grid.Row>
                <Grid.Column width={4}>
                    <LabelWithError
                        isErr={touched.topic&& Boolean(errors.topic)}
                        labelTextNorm = {formatMessage({id: "FeedbackModal.topic.labelTextNorm"})}
                        labelTextErr={errors.topic!}
                        infoText = {formatMessage({id: "FeedbackModal.topic.infoText"})}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form.Field>
                    <Dropdown
                        id="topic"
                        name="topic"
                        options={topics}
                        placeholder={formatMessage({id: "FeedbackModal.topic.placeholder"})}
                        selection
                        fluid
                        value={values.topic}
                        onChange={(_, { value }) =>
                        setFieldValue("topic", value)
                        }
                        style={
                        touched.topic && errors.topic
                            ? {
                                border: "3px solid #ff0000"
                            }
                            : {}
                        }
                    />
                    </Form.Field>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column width={4}>
                    <LabelWithError
                        isErr={touched.summary&& Boolean(errors.summary)}
                        labelTextNorm = {formatMessage({id: "FeedbackModal.summary.labelTextNorm"})}
                        labelTextErr={errors.summary!}
                        infoText = {formatMessage({id: "FeedbackModal.summary.infoText"})}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form.Field>
                    <Input
                        placeholder={formatMessage({id: "FeedbackModal.summary.placeholder"})}
                        name="summary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={
                        touched.summary && errors.summary
                            ? {
                                border: "3px solid #ff0000"
                            }
                            : {}
                        }
                        type="string"
                        value={values.summary}
                    />
                    </Form.Field>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column width={4}>
                    <LabelWithError
                        isErr={touched.details&& Boolean(errors.details)}
                        labelTextNorm = {formatMessage({id: "FeedbackModal.details.labelTextNorm"})}
                        labelTextErr={errors.details!}
                        infoText = {formatMessage({id: "FeedbackModal.details.infoText"})}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form.Field>
                        <TextArea
                            placeholder={formatMessage({id: "FeedbackModal.details.placeholder"})}
                            name="details"
                            rows={4}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={
                            touched.details && errors.details
                                ? {
                                    border: "3px solid #ff0000"
                                }
                                : {}
                            }
                            type="string"
                            value={values.details}
                        />
                    </Form.Field>
                </Grid.Column>
                </Grid.Row>
            </Grid>
            </Modal.Content>
            <Modal.Actions style={{ overflow: "auto" }}>
            <Button.Group floated="right">
                <Button onClick={cancelModal} type="button">
                    <FormattedMessage id = "FeedbackModal.Button.cancel" />
                </Button>
                <Button.Or text={"|"} />
                <Button type="submit" positive>
                    <FormattedMessage id = "FeedbackModal.Button.submit" />
                </Button>
            </Button.Group>
            </Modal.Actions>
        </Modal>
    );
}
