import * as Yup from "yup";

import { AccessRightsInitialValues, AccessRightsType } from '../../typings/models/other';
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';

import AccessControlComponent from '../common/AccessControlComponent';
import { accessSubFormYupShape } from '../common/Subforms/AccessSubForm';
import { axiosService } from "../../utils/axios";
import { useFormik } from "formik";
import { useState } from "react";

export type ExerciseAccessChangeCallback = (exerciseId : number, accessRights: AccessRightsType) => void | undefined;

export default function ExerciseAccessModal({exerciseId, accessRightsChangeCallback = undefined, accessFormInitialValues = AccessRightsInitialValues, isDisabled = false} : {exerciseId : number, accessRightsChangeCallback?: ExerciseAccessChangeCallback,  accessFormInitialValues? : AccessRightsType, isDisabled? : boolean})
{
    const [isModalOpened, setIsModalOpened] = useState(false);
    const {formatMessage} = useIntl();

    const exerciseAccessModalSchema = Yup.object().shape({
        ...accessSubFormYupShape
    });
    const formik  = useFormik({
        initialValues: accessFormInitialValues,
        validationSchema: exerciseAccessModalSchema,
        onSubmit: (values) => {
            // saving the values to the server
            axiosService.put(`${process.env.REACT_APP_API_URL}/exercise/${exerciseId}/`, {...values, is_private: values['is_private']})
            .then(r=> {
                alert(formatMessage({id: "ExerciseAccessModal.Submitted"}));
                if (accessRightsChangeCallback) accessRightsChangeCallback(exerciseId, values);
                setIsModalOpened(false);
            })
            .catch( (err) => {
                alert(formatMessage({id: "general.error"}));
            });
        }
    });
    const {
        dirty,
        handleSubmit,
        resetForm,
        isValid    } = formik;

    const openModal = () => {
        setIsModalOpened(true);
    };
    const handleClose = () => {
        setIsModalOpened(false);
    };
    const cancelModal = () => {
        resetForm({values: accessFormInitialValues});
        setIsModalOpened(false);
    };
    return (
        <Modal
            trigger={
                <Button inverted icon='add user' color='red' onClick={openModal} disabled={isDisabled} />
            }
            as={Form}
            open={isModalOpened}
            size="large"
            closeOnDimmerClick={false}
            onSubmit={handleSubmit}
            closeOnEscape={true}
            onClose={handleClose}
        >
            <Modal.Header>
                <Icon name='child' />
                <FormattedMessage id="ExerciseAccessModal.Header"/>{exerciseId}
            </Modal.Header>
            <Modal.Content>
                <FormattedMessage id="ExerciseAccessModal.Intro"/>
                <AccessControlComponent formik={formik} />
            </Modal.Content>
            <Modal.Actions style={{ overflow: "auto" }}>
            <Button.Group floated="right">
                <Button onClick={cancelModal} type="button">
                    <FormattedMessage id = "ExerciseAccessModal.Button.cancel" />
                </Button>
                <Button.Or text={"|"} />
                <Button
                    type="submit"
                    positive
                    disabled = {!isValid || !dirty}
                >
                    <FormattedMessage id = "ExerciseAccessModal.Button.Submit" />
                </Button>
            </Button.Group>
            </Modal.Actions>
        </Modal>
    );
}
