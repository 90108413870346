import '../index.css';

import * as _ from 'lodash';

import { Grid, GridColumn, Menu, MenuItem, MenuItemProps } from "semantic-ui-react";

import ContentComponentFromDB from '../components/common/Content';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useState } from "react";

// region settings for the content
const topicNames = [
  'registration',
  'exercise',
  'analyse',
  'access',
  'intelligence',
] as const;
const topicContentIDs = [
  1,
  2,
  3,
  4,
  5,
] as const;

const contentTopicTabRef = "help.topic.ref.";

// endregion

type TopicsNameType = typeof topicNames[number];
type TopicsIdType = typeof topicContentIDs[number];

type TopicsType = { [K in TopicsNameType]: TopicsIdType};

const topicsInfo =  _.zipObject(topicNames, topicContentIDs) as TopicsType;

function Help() {
  const {formatMessage} = useIntl();
  const [activeTopicName, setActiveTopicName] = useState<TopicsNameType>('registration');
  const handleItemClick  = (topicName: TopicsNameType) => (event : any, {name} : MenuItemProps) => {
    setActiveTopicName(topicName);
  }

  const mobileHelp = topicNames.map(tn =>
    <ContentComponentFromDB key={topicsInfo[tn]} id= {topicsInfo[tn]} withTitle={true} withBody={true}/>
  );

  const desktopHelp = <Grid stretched style={{height: '100%', margin: 0}}>
    <Grid.Row stretched>
        <GridColumn width={2}>
        <Menu fluid vertical tabular>
            { topicNames.map(tn =>
                <MenuItem
                  key={tn}
                  name={formatMessage({id: contentTopicTabRef+tn})}
                  active={activeTopicName === tn}
                  onClick={handleItemClick(tn)}
                />
            )}
        </Menu>
        </GridColumn>
        <GridColumn stretched width={14}>
            <ContentComponentFromDB id= {topicsInfo[activeTopicName]} withTitle={false} withBody={true}  withStyle={{height: '100%'}}/>
        </GridColumn>
      </Grid.Row>
    </Grid>;

  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
  return <Fragment >
    {isBigScreen ? desktopHelp : mobileHelp}
  </Fragment>
}

export default Help;
