import * as _ from 'lodash';

export function arrLength(arr: number[])
{
    let rsq = arr.reduce( (t, i) => (t + (i**2)), 0 );
    return Math.sqrt(rsq);
}
export function arrCoordSum(arr: number[] | Float32Array)
{
    // let r = arr.reduce((t, i) => t+i, 0);
    let r = 0;
    for (let i = 0; i < arr.length; i++)
    {
        r+=arr[i];
    }
    return r;
}
export function arrsSubtract(arr1: number[], arr2: number[])
{
    let r = _.zipWith(arr1, arr2, (a1: number, a2: number) => a1-a2);
    return r;

}
export function arrsMult(arr1: number[] | Float32Array, arr2: number[] | Float32Array)
{
    let r = _.zipWith(arr1, arr2, (a1: number, a2: number) => a1*a2);
    return r;
}
export function arrsAngle(arr1: number[], arr2: number[])
{
    let sc1 = arrsMult(arr1, arr2);
    let sc = arrCoordSum(sc1);
    let arr1Len = arrLength(arr1);
    let arr2Len = arrLength(arr2);
    let arrCos = sc / (arr1Len * arr2Len);
    let arrAngle = Math.acos(arrCos) * 180 / Math.PI;
    return arr1[1]>0 ? arrAngle : -arrAngle;
}