import * as Yup from "yup";

import { Button, Grid, Popup, Segment, TextArea } from "semantic-ui-react";
import { Fragment, useState } from "react";
import { InviteUserInfoType, InvitedUserInfoType } from "../../typings/models/User";

import LabelWithError from "../LabelWithError";
import { Nullable } from "../../typings/general";
import { axiosService } from "../../utils/axios";
import { errBoarderStyle } from "../../typings/styles";
import { formatMessage } from "../../utils/localization";
import { useFormik } from "formik";
import { useIntl } from 'react-intl';
import { withHandlingDimming } from "../../utils/hocs";

const initialValues = {
    name: "",
    email: "",
    comments: ""
} as InviteUserInfoType;

const inviteSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, formatMessage({id: "Invite.name.min"}))
        .max(255, formatMessage({id: "Invite.name.max"}))
        .required(formatMessage({id: "Invite.name.required"})),
    email: Yup.string().trim().email(formatMessage({id: "Invite.email.required"})).required(formatMessage({id: "Invite.email.required"})),
    comments: Yup.string()
        .min(10, formatMessage({id: "Invite.comments.min"}))
        .max(255, formatMessage({id: "Invite.comments.max"}))
        .required(formatMessage({id: "Invite.comments.required"})),
});

/**
 * Component for sending invitations to other users
 * @param onUserInvited callback to handle the user info, which may be Nullable<IInvitedUserInfo> if the user cancelled creating the invite
 * @param setIsHandling callback to indicate to the parent component that Invite is handling something so the parent may use it to enable/disable stuff or alike
 * @returns IInvitedUserInfo or null if invitation is cancelled (and all inputted data is cleared/lost)
 */
function Invite({onUserInvited, setIsHandling } : {
    onUserInvited : (invitedUserInfo : Nullable<InvitedUserInfoType>) => void,
    setIsHandling: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const [isLoading, setLoading] = useState(false);
    const t = useIntl();

    const formikInvite = useFormik<InviteUserInfoType>({
        initialValues: initialValues,
        validateOnMount: true,
        onSubmit: async (values : InviteUserInfoType) => {
            setLoading(true);
            setIsHandling(true);
            try {
                // saving to the server and receiving the results invitedUserInfo
                await axiosService.post(`${process.env.REACT_APP_API_URL}/invite/`, values);
                formikInvite.resetForm({values: initialValues});
                onUserInvited(null);
            }
            catch (err : any) {
                // handling different errors - existing invite or there's already a user with such an email
                let inqueryEmail = process.env.REACT_APP_EMAIL?.replace("@", "(at)");
                let errMessage : any;
                if (err.response?.data.type === "invite_exists") {
                    errMessage = t.formatMessage({id: "Invite.errmessage.invite_exists"});
                }
                else if (err.response?.data.type === 'user_exists') {
                    errMessage = t.formatMessage({id: "Invite.errmessage.user_exists"},
                        {existing_user_name: err.response?.data?.existing_user_name}
                    )
                }
                else {
                    errMessage = t.formatMessage({id: "Invite.errmessage"}, {email_to_contact: inqueryEmail});
                }
                alert(errMessage);
            };

            setLoading(false);
            setIsHandling(false);
        },
        validationSchema: inviteSchema,
    });

    const onCancel = () => {
        onUserInvited(null);
        formikInvite.resetForm({values: initialValues});
    }

    const buttonElement = (<Button.Group size='large'>
        <Popup
            content={t.formatMessage({id: "Invite.Popup.content"})}
            trigger={
                <Button
                    disabled = {isLoading || !formikInvite.isValid}
                    loading = {isLoading}
                    color= {!isLoading ? 'green' : 'red'}
                    icon='save'
                    onClick={formikInvite.submitForm}
                />
            }
        />
        <Button.Or text='|' />
        <Button icon = "cancel" onClick={onCancel}/>
    </Button.Group>);
    return (<Segment>
        <Grid verticalAlign="middle" textAlign="left" stackable>
            <Grid.Row>
                    <Grid.Column width={8}>
                        <LabelWithError
                            isErr={formikInvite.touched.name && Boolean(formikInvite.errors.name)}
                            labelTextNorm = {t.formatMessage({id: "register.label.name"})}
                            labelTextErr={formikInvite.errors.name!}
                        />
                        <input
                            id="name"
                            type="text"
                            placeholder={t.formatMessage({id: "Invite.name.placeholder"})}
                            name="name"
                            value={formikInvite.values.name}
                            onChange={formikInvite.handleChange}
                            onBlur={formikInvite.handleBlur}
                            style={ errBoarderStyle(formikInvite.touched.name! && Boolean(formikInvite.errors.name)) }
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <LabelWithError
                            isErr={formikInvite.touched.email && Boolean(formikInvite.errors.email)}
                            labelTextNorm = {t.formatMessage({id: "register.label.email"})}
                            labelTextErr={formikInvite.errors.email!}
                        />
                        <input
                            id="email"
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={formikInvite.values.email}
                            onChange={formikInvite.handleChange}
                            onBlur={formikInvite.handleBlur}
                            style={ errBoarderStyle(formikInvite.touched.email! && Boolean(formikInvite.errors.email)) }
                        />
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <LabelWithError
                        isErr={formikInvite.touched.comments && Boolean(formikInvite.errors.comments)}
                        labelTextNorm = {t.formatMessage({id: "Invite.LabelWithError.comments"})}
                        labelTextErr={formikInvite.errors.comments!}
                    />
                    <TextArea
                        placeholder={formatMessage({id: "Invite.comments.placeholder"})}
                        name="comments"
                        rows={3}
                        onChange={formikInvite.handleChange}
                        onBlur={formikInvite.handleBlur}
                        style={ errBoarderStyle(formikInvite.touched.comments! && Boolean(formikInvite.errors.comments!)) }
                        type="string"
                        value={formikInvite.values.comments}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                    {buttonElement}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>);
};

export default withHandlingDimming(formatMessage({id: "exercisenew.dimmer.loading"}), false)(Invite);