import { PayloadAction, Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { User } from "../../typings/models/User";
import {axiosService} from "../../utils/axios";

export type AuthStateType = {
    token: string | null;
    refreshToken: string | null;
    account: User | null;
};

const initialState: AuthStateType = { token: null, refreshToken: null, account: null };

const authSlice : Slice<AuthStateType, {
    setAuthTokens(state: AuthStateType, action: PayloadAction<{
        token: string;
        refreshToken: string;
    }>): void;
    setAccount(state: AuthStateType, action: PayloadAction<any>): void;
    setLogout(state: AuthStateType): void;
}, string> = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthTokens(
            state: AuthStateType,
            action: PayloadAction<{ token: string; refreshToken: string }>
        ) {
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
        },
        setAccount(state: AuthStateType, action: PayloadAction<any>) {
            state.account = action.payload;
        },
        setLogout(state: AuthStateType) {
            state.account = null;
            state.refreshToken = null;
            state.token = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(registerAsync.fulfilled, (state, { payload } : PayloadAction<any>) =>{
            state.refreshToken = payload.refreshToken;
            state.token = payload.token;
            state.account = payload.user;
        })
    }
});

// export const registerAsync = createAsyncThunk<{ token: string; refreshToken: string, account: any }>('auth/registerAsync',async ({name, email, password } : {name: string, email: string, password: string}) => {
export const registerAsync = createAsyncThunk('auth/registerAsync', async ({name, email, password } : {name: string, email: string, password: string}, {rejectWithValue}) => {
    try {
        let res = await axiosService.post(`${process.env.REACT_APP_API_URL}/auth/register/`, {name, email, password });
        return res.data;
    } catch (err : any) {
        return rejectWithValue(err.response.data)
    }
})

export default authSlice;
