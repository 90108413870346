import { AuthorInfo, UserShortInfo } from "./User"

import { ProcessingStatus } from "../../settings"
import { VideoInfo } from "../video"

export type AnalysisInfoShort = {
    id: number,
    author: UserShortInfo,
    date_created: string,
    comments: string
}

export type ExerciseInfo = {
    id: number,
    author: Partial<AuthorInfo>,
    processing_status: ProcessingStatus,
    analysis_results: any,
    date_created: string,
    recording_file: string,
    is_private: boolean | undefined,
    analysises?: AnalysisInfoShort[],
    analysises_of_author?: Exclude<AnalysisInfoShort, 'author'>[],
    reviewers?: number[] | undefined,
    comments?: string,
    video_info?: VideoInfo
}

export type AnalysisInfo ={
    id: number,
    author: Partial<AuthorInfo>,
    exercise: Pick<ExerciseInfo, 'id'| 'author'| 'processing_status' | 'date_created' | 'recording_file' | 'analysis_results'>,
    date_created: string,
    comments?: string,
    analysis_results?: Record<string, any>
}

export type SpecialtyInfo = {
    id: number,
    title: string,
    description: string
}

export type AccessRightsType = {
    is_private: boolean | undefined,
    reviewers: number[] | undefined
}

export const AccessRightsInitialValues : AccessRightsType = {
    is_private: false,
    reviewers: undefined
}

export type RecordDataType = {
        blob: Blob | null;
        comments?: string
    } & AccessRightsType;

export type UploadDataType = {
    date_created: Date;
    recording_file: File | Blob | undefined;
    comments: string
} & AccessRightsType;

export const initialRecordData : RecordDataType = {blob: null, ...AccessRightsInitialValues}
export const initialValuesUploadData : UploadDataType = {
    date_created: new Date(),
    recording_file: undefined,
    comments: "",
    ...AccessRightsInitialValues
};

export type ContentShortType = {
    title: string;
    body: string;
    comments?: string;
}

export type ContentFullType = {
    id: number;
    profile?: number;
    specialty?: number;
} & ContentShortType;