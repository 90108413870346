import * as yup from "yup";

import { Button, Checkbox, Form, Grid, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";

import LabelWithError from "../LabelWithError";
import { RootState } from "../../store";
import {axiosService} from "../../utils/axios";
import { changeAnalysisSettingsOpened } from '../../store/slices/ui';
import { defaultAnalysisSettings } from "../../typings/models/AnalysisSettings";
import { setAnalysisSettings } from "../../store/slices/AnalysisSettings";
import { styleCenteredText } from "../../typings/styles";
import { useFormik } from "formik";

interface ISettingsModalProps {
    isDisabled?: boolean,
}

export default function SettingsModal({isDisabled = false} : ISettingsModalProps)
{
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const initialSettings = useSelector((state: RootState) => state.analysisSettings);
    const isModalOpened = useSelector((state: RootState) => state.ui.analysisSettingsOpened);
    //#region modal settings
    const openModal = () => {
        dispatch(changeAnalysisSettingsOpened());
    };
    const cancelModal = () => {
        dispatch(setAnalysisSettings(initialSettings));
        dispatch(changeAnalysisSettingsOpened());
        resetForm({ values: initialSettings});
    };
    const resetToDefaultModal = () => {
        resetForm({ values: defaultAnalysisSettings});
    };

    const account = useSelector((state: RootState) => state.auth.account);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: initialSettings,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(setAnalysisSettings(values));
            dispatch(changeAnalysisSettingsOpened());
            if (account!=null) {
                axiosService
                    .put(`${process.env.REACT_APP_API_URL}/profile/`, values)
                    .catch((error) => {
                        alert(formatMessage({id: "settingsmodal.alert.error"}, {err: error}));
                    });
            }
            else
            {
                // TODO add info/link for registration!
                alert(formatMessage({id: "settingsmodal.alert.savinglocally"}));
            }
        },
        validationSchema: yup.object().shape({
            generalAnalysisSettings: yup.object().shape({
                includeVideo: yup
                .bool(),
                includeAudio: yup
                .bool()
            }),
            videoSettings: yup.object().shape({
                frameDelayCapture: yup
                    .number()
                    .min(100, formatMessage({id: "settingsmodal.frameDelayCapture.min"}))
                    .max(1000, formatMessage({id: "settingsmodal.frameDelayCapture.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                centerPositionDiviation: yup
                    .number()
                    .min(0, formatMessage({id: "settingsmodal.centerPositionDiviation.min"}))
                    .max(0.5, formatMessage({id: "settingsmodal.centerPositionDiviation.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                rotationAngleDeviation: yup
                    .number()
                    .min(0, formatMessage({id: "settingsmodal.rotationAngleDeviation.min"}))
                    .max(90, formatMessage({id: "settingsmodal.rotationAngleDeviation.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
            }),
            audioSettings: yup.object().shape({
                includeVolume: yup
                    .bool(),
                volumeMin: yup
                    .number()
                    .min(0, formatMessage({id: "settingsmodal.volumeMin.min"}))
                    .max(100, formatMessage({id: "settingsmodal.volumeMin.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                volumeMax: yup
                    .number()
                    .min(0, formatMessage({id: "settingsmodal.volumeMax.min"}))
                    .max(500, formatMessage({id: "settingsmodal.volumeMax.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                includeSpeech: yup
                    .bool(),
                upperSlowWords: yup
                    .number()
                    .min(30, formatMessage({id: "settingsmodal.upperSlowWords.min"}))
                    .max(100, formatMessage({id: "settingsmodal.upperSlowWords.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                upperOkWords: yup
                    .number()
                    .min(30, formatMessage({id: "settingsmodal.upperOkWords.min"}))
                    .max(150, formatMessage({id: "settingsmodal.upperOkWords.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                upperMaxWords: yup
                    .number()
                    .min(60, formatMessage({id: "settingsmodal.upperMaxWords.min"}))
                    .max(300, formatMessage({id: "settingsmodal.upperMaxWords.max"}))
                    .typeError(formatMessage({id: "settingsmodal.number.typeError"}))
                    .required(formatMessage({id: "settingsmodal.required"})),
                })
        }),
    });
    //#endregion

    return <Modal
        trigger={
            <Button icon
                color='green'
                onClick={openModal}
                disabled={isDisabled}
                // style={styleCenteredHorizontally}
                // circular
            >
                <Icon name='settings' />
            </Button>
        }
        as={Form}
        open={isModalOpened}
        size="large"
        closeOnDimmerClick={false}
        onSubmit = {handleSubmit}
    >
        <Modal.Header style = {styleCenteredText}>
            <Icon name="settings" /><FormattedMessage id="settingsmodal.Modal.Header.text"/>
        </Modal.Header>
        <Modal.Content>
            {/*//#region general */}
            <Segment>
                <Header content={formatMessage({id: "settingsmodal.Modal.Content.Segment.Header"})} as='h3' style = {styleCenteredText}/>
                <Grid stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Form.Field>
                                <Checkbox
                                    id="generalAnalysisSettings.includeVideo"
                                    name="generalAnalysisSettings.includeVideo"
                                    label = {formatMessage({id: "settingsmodal.Modal.Content.includeVideo.label"})}
                                    onChange={handleChange}
                                    checked = {values.generalAnalysisSettings.includeVideo}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Checkbox
                                id="generalAnalysisSettings.includeAudio"
                                name="generalAnalysisSettings.includeAudio"
                                label = {formatMessage({id: "settingsmodal.Modal.Content.includeAudio.label"})}
                                onChange={handleChange}
                                checked = {values.generalAnalysisSettings.includeAudio}
                                />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            {/*//#endregion */}
            {/*//#region video */}
            {values.generalAnalysisSettings.includeVideo &&
                <Segment>
                    <Header content={formatMessage({id: "settingsmodal.Modal.Header.includeVideo"})}
                        as='h3' style = {styleCenteredText}/>
                    <Grid stackable columns={3}>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Form.Field>
                                    <LabelWithError
                                        isErr={touched.videoSettings?.frameDelayCapture&& Boolean(errors.videoSettings?.frameDelayCapture)}
                                        labelTextNorm = {formatMessage({id: "settingsmodal.frameDelayCapture.labelTextNorm"})}
                                        labelTextErr={errors.videoSettings?.frameDelayCapture!}
                                        infoText={formatMessage({id: "settingsmodal.frameDelayCapture.infoText"})}
                                    />
                                    <Input
                                        placeholder={formatMessage({id: "settingsmodal.frameDelayCapture.placeholder"})}
                                        name="videoSettings.frameDelayCapture"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={touched.videoSettings?.frameDelayCapture && errors.videoSettings?.frameDelayCapture ? {
                                            border: "3px solid #ff0000"} : {}
                                        }
                                        type='number'
                                        step="any"
                                        value={values.videoSettings.frameDelayCapture}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form.Field>
                                    <LabelWithError
                                        isErr={touched.videoSettings?.centerPositionDiviation&& Boolean(errors.videoSettings?.centerPositionDiviation)}
                                        labelTextNorm = {formatMessage({id: "settingsmodal.centerPositionDiviation.labelTextNorm"})}
                                        labelTextErr={errors.videoSettings?.centerPositionDiviation!}
                                        infoText={formatMessage({id: "settingsmodal.centerPositionDiviation.infoText"})}
                                    />
                                    <Input
                                        name="videoSettings.centerPositionDiviation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={touched.videoSettings?.centerPositionDiviation && errors.videoSettings?.centerPositionDiviation ? {
                                            border: "3px solid #ff0000"} : {}
                                        }
                                        type='number'
                                        step="any"
                                        value={values.videoSettings.centerPositionDiviation}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form.Field>
                                    <LabelWithError
                                        isErr={touched.videoSettings?.rotationAngleDeviation&& Boolean(errors.videoSettings?.rotationAngleDeviation)}
                                        labelTextNorm = {formatMessage({id: "settingsmodal.rotationAngleDeviation.labelTextNorm"})}
                                        labelTextErr={errors.videoSettings?.rotationAngleDeviation!}
                                        infoText={formatMessage({id: "settingsmodal.rotationAngleDeviation.infoText"})}
                                    />
                                    <Input
                                        placeholder="10"
                                        name="videoSettings.rotationAngleDeviation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        // defaultValue = {initialSettings.videoSettings?.rotationAngleDeviation}
                                        style={touched.videoSettings?.rotationAngleDeviation && errors.videoSettings?.rotationAngleDeviation ? {
                                            border: "3px solid #ff0000"} : {}
                                        }
                                        type='number'
                                        step="any"
                                        value={values.videoSettings.rotationAngleDeviation}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            }
            {/*//#endregion */}
            {/*//#region audio */}
            {values.generalAnalysisSettings.includeAudio &&
                <Segment>
                    <Header content="Настройки аудио" as='h3' style = {styleCenteredText}/>
                    <Grid stackable columns={3}>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field>
                                    <Checkbox
                                        id="includeVolume"
                                        name="audioSettings.includeVolume"
                                        label ={formatMessage({id: "settingsmodal.includeVolume.label"})}
                                        onChange={handleChange}
                                        checked = {values.audioSettings.includeVolume}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        {values.audioSettings.includeVolume &&
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Form.Field>
                                        <LabelWithError
                                            isErr={touched.audioSettings?.volumeMin&& Boolean(errors.audioSettings?.volumeMin)}
                                            labelTextNorm = {formatMessage({id: "settingsmodal.volumeMin.labelTextNorm"})}
                                            labelTextErr={errors.audioSettings?.volumeMin!}
                                            infoText={formatMessage({id: "settingsmodal.volumeMin.infoText"})}
                                        />
                                        <Input
                                            placeholder="100"
                                            name="audioSettings.volumeMin"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={touched.audioSettings?.volumeMin && errors.audioSettings?.volumeMin ? {
                                                border: "3px solid #ff0000"} : {}
                                            }
                                            type='number'
                                            step="any"
                                            value={values.audioSettings.volumeMin}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Form.Field>
                                        <LabelWithError
                                            isErr={touched.audioSettings?.volumeMax&& Boolean(errors.audioSettings?.volumeMax)}
                                            labelTextNorm = {formatMessage({id: "settingsmodal.volumeMax.labelTextNorm"})}
                                            labelTextErr={errors.audioSettings?.volumeMax!}
                                            infoText={formatMessage({id: "settingsmodal.volumeMax.infoText"})}
                                        />
                                        <Input
                                            placeholder="500"
                                            name="audioSettings.volumeMax"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={touched.audioSettings?.volumeMax && errors.audioSettings?.volumeMax ? {
                                                border: "3px solid #ff0000"} : {}
                                            }
                                            type='number'
                                            step="any"
                                            value={values.audioSettings.volumeMax}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        {/*//#region speech */}
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field>
                                    <Checkbox
                                        id="includeSpeech"
                                        name="audioSettings.includeSpeech"
                                        label ={formatMessage({id: "settingsmodal.includeSpeech.label"})}
                                        onChange={handleChange}
                                        checked = {values.audioSettings.includeSpeech}
                                />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        {values.audioSettings.includeSpeech &&
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <LabelWithError
                                            isErr={touched.audioSettings?.upperSlowWords&& Boolean(errors.audioSettings?.upperSlowWords)}
                                            labelTextNorm = {formatMessage({id: "settingsmodal.upperSlowWords.labelTextNorm"})}
                                            labelTextErr={errors.audioSettings?.upperSlowWords!}
                                            infoText={formatMessage({id: "settingsmodal.upperSlowWords.infoText"})}
                                        />
                                        <Input
                                            placeholder="30"
                                            name="audioSettings.upperSlowWords"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            // defaultValue = {initialSettings.audioSettings?.upperSlowWords}
                                            style={touched.audioSettings?.upperSlowWords && errors.audioSettings?.upperSlowWords ? {
                                                border: "3px solid #ff0000"} : {}
                                            }
                                            type='number'
                                            step="any"
                                            value={values.audioSettings.upperSlowWords}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <LabelWithError
                                            isErr={touched.audioSettings?.upperOkWords&& Boolean(errors.audioSettings?.upperOkWords)}
                                            labelTextNorm = {formatMessage({id: "settingsmodal.upperOkWords.labelTextNorm"})}
                                            labelTextErr={errors.audioSettings?.upperOkWords!}
                                            infoText={formatMessage({id: "settingsmodal.upperOkWords.infoText"})}
                                        />
                                        <Input
                                            placeholder="130"
                                            name="audioSettings.upperOkWords"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={touched.audioSettings?.upperOkWords && errors.audioSettings?.upperOkWords ? {
                                                border: "3px solid #ff0000"} : {}
                                            }
                                            type='number'
                                            step="any"
                                            value={values.audioSettings.upperOkWords}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <LabelWithError
                                            isErr={touched.audioSettings?.upperMaxWords&& Boolean(errors.audioSettings?.upperMaxWords)}
                                            labelTextNorm = {formatMessage({id: "settingsmodal.upperMaxWords.labelTextNorm"})}
                                            labelTextErr={errors.audioSettings?.upperMaxWords!}
                                            infoText={formatMessage({id: "settingsmodal.upperMaxWords.infoText"})}
                                        />
                                        <Input
                                            placeholder="130"
                                            name="audioSettings.upperMaxWords"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={touched.audioSettings?.upperMaxWords && errors.audioSettings?.upperMaxWords ? {
                                                border: "3px solid #ff0000"} : {}
                                            }
                                            type='number'
                                            step="any"
                                            value={values.audioSettings.upperMaxWords}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        {/*//#endregion */}
                    </Grid>
                </Segment>
            }
            {/*//#endregion */}
        </Modal.Content>
        <Modal.Actions style={{overflow: "auto" }}>
            <Button.Group floated='left'>
                <Button onClick={resetToDefaultModal} attached='left' negative type="button">
                    <FormattedMessage id="settingsmodal.Button.resetToDefaultModal" />
                </Button>
            </Button.Group>
            <Button.Group floated='right'>
                <Button onClick={cancelModal} type="button">
                    <FormattedMessage id="settingsmodal.Button.cancelModal" />
                </Button>
                <Button.Or text={'|'} />
                <Button type="submit" positive >
                    <FormattedMessage id="settingsmodal.Button.submit" />
                </Button>
            </Button.Group>
        </Modal.Actions>
    </Modal>
}
