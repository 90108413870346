import * as Yup from "yup";

import AccessSubForm, { accessSubFormYupShape } from "../common/Subforms/AccessSubForm";
import { Button, Divider, Form, Grid, Header, Icon, Image, List, ListContent, ListIcon, ListItem, Modal, Popup } from "semantic-ui-react";
import CommentsSubForm, { commentsSubFormYupShape } from "../common/Subforms/CommentsSubForm";
import { FormattedMessage, useIntl } from "react-intl";
import { Fragment, useEffect, useState } from "react";

import { AccessRightsInitialValues } from "../../typings/models/other";
import CameraRotateSvgComponent from "../../imgs/CameraRotateSVG";
import RecordingComponent from "../common/RecordingComponent";
import { SizeMe } from "react-sizeme";
import VideoComponent from "../common/VideoComponent";
import { getDateTimeString } from "../../utils/general";
import { uploadBlob } from "../../utils/axios";
import { useFormik } from "formik";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/hooks";

type RecordingType = {
    blob: Blob,
    url: string,
}

type CameraSelectionType = {
    selectedCameraIndex: number,
    availableCameras: MediaDeviceInfo[],
}

function RecordModal() {
    const {formatMessage} = useIntl();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isRecordingOn, setIsRecordingOn] = useState(false);
    const [isRecordingStarting, setIsRecordingStarting] = useState(false);
    const [curRecording, setCurRecording] = useImmer<RecordingType | null>(null);
    const [ windowWidth, windowHeight] = useWindowDimensions();
    const navigate = useNavigate();

    // region camera selection
    const [cameras, setCameras] = useImmer< CameraSelectionType | null> (null);
    useEffect(()=> {
        if (!navigator.mediaDevices?.enumerateDevices) {
            alert("enumerateDevices() not supported.");
        } else {
        // List cameras
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then((_) => {
                navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    // devices.forEach(d => alert(`111deviceId = ${d.deviceId}, kind = ${d.kind}`));
                    let videoDevices = devices.filter((d) => d.kind==='videoinput');
                    if (videoDevices && videoDevices.length > 0) {
                        setCameras({
                            selectedCameraIndex: 0,
                            availableCameras: videoDevices,
                        });
                    }
                    else {
                        console.error(`no video devices`);
                    }
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });
            })
        }
        // return to clear Promise?
    }, []);

    const avaliableCamerasContent = <Fragment>
        <Header>
            <FormattedMessage id="recordmodal.AvailableCameras"/>
        </Header>
        { cameras && cameras.availableCameras.length > 0 ?
            <List>
                {cameras?.availableCameras.map((c, index) =>
                    <ListItem key={c.deviceId}>
                        <ListIcon name='camera' />
                        <ListContent>{c.label}</ListContent>
                    </ListItem>
                )}
            </List>
            :
            <FormattedMessage id="recordmodal.NoAvailableCameras"/>
        }
        <Divider />
        <div><FormattedMessage id="recordmodal.cameras.introtext"/></div>
        <Divider />
        <Header><FormattedMessage id="recordmodal.cameras.introtext.selectedCamera"/>{cameras  ? `${cameras?.availableCameras[cameras.selectedCameraIndex]?.label}` : <FormattedMessage id="recordmodal.NoSelectedCamera"/>}
        </Header>
    </Fragment>
    // endregion

    //#region formik stuff
    const recordSchema = Yup.object().shape({...commentsSubFormYupShape, ...accessSubFormYupShape});
    const recordSchemaInitialValues = {comments: '', ...AccessRightsInitialValues};

    // TODO check why blob is uploaded to the server and not the file - check if corrected now!
    const submitRecordHandler = (values: typeof recordSchemaInitialValues) => {
        uploadBlob({
            urlAPI: `${process.env.REACT_APP_API_URL}/exercise/`,
            blob: curRecording?.blob!,
            auxData: {
                is_private: values.is_private,
                date_created : getDateTimeString(new Date()) + '+0300',
                comments: values.comments,
                reviewers: values.reviewers,
            }
        })
        .then( (r : any) => {
            navigate("/exercise");
        })
        .catch(error => {
            alert(formatMessage({id: "recordmodal.alert.error"}, {err: error.message}));
            setIsModalOpened(false);
        });
    }

    const formik = useFormik({
        initialValues: recordSchemaInitialValues,
        validationSchema: recordSchema,
        onSubmit: submitRecordHandler
    })
    //#endregion formik

    const handleClose = () => {
        formik.resetForm({values: recordSchemaInitialValues});
        if (isRecordingOn) setIsRecordingOn(false);
        setIsModalOpened(false);
        setCurRecording(null);
    };
    const recordUploadElementRecorded : JSX.Element = <Grid stackable padded centered>
        <Grid.Row columns={2}>
            <Grid.Column width={9}>
                <VideoComponent
                    divProps={{
                        style: {
                            justifyContent: 'center',
                            textAlign: "center",
                        }
                    }}
                    videoProps={{
                        width: Math.round( Math.min(windowWidth, windowHeight)/2),
                        hidden: false,
                        muted: true,
                        controls: true,
                        playsInline: true,
                        src: curRecording?.url
                    }}
                />
            </Grid.Column>
            <Grid.Column width={7}>
                <CommentsSubForm formik={formik} debounceTime={1000}/>
                <AccessSubForm formik={formik} />
            </Grid.Column>
        </Grid.Row>
    </Grid>;
    const recordUploadElementEmpty : JSX.Element =
    <div style={{
        justifyContent: 'center',
        textAlign: "center",
        borderStyle: curRecording ? "none" : "solid"
    }}
    >
        <Image src={`/imgs/image_placeholder.png`} wrapped ui={false} fluid centered verticalAlign="middle" style={{
            objectFit: "contain"
        }}/>
    </div>;

    const recordUploadElement = curRecording ? recordUploadElementRecorded : recordUploadElementEmpty;

    return (
        <Modal
            trigger={ <Button icon = 'file video' onClick={() => setIsModalOpened(!isModalOpened)}/>}
            as={Form}
            open={isModalOpened}
            size="fullscreen"
            closeOnDimmerClick={false}
            onSubmit={formik.handleSubmit}
            closeOnEscape={false}
            onClose={handleClose}
        >
            <Modal.Header>
                <Icon name='child' /> <FormattedMessage id="recordmodal.Header"/>
            </Modal.Header>
            <Modal.Content>
                {isRecordingOn ?
                    <RecordingComponent
                        setIsRecordingStarting={setIsRecordingStarting}
                        handleRecordedBlob={(recordedBlob) => setCurRecording({blob: recordedBlob, url: URL.createObjectURL(recordedBlob)})}
                        recordingDeviceId={cameras?.availableCameras[cameras.selectedCameraIndex]?.deviceId}/>
                    :
                    recordUploadElement
                }
            </Modal.Content>
            <Modal.Actions style={{ overflow: "auto" }}>
                <Button.Group floated="left">
                    {cameras?.availableCameras?.length && cameras?.availableCameras?.length > 1 &&
                        <Popup
                            wide
                            content = {avaliableCamerasContent}
                            trigger={
                                <Button
                                    disabled={isRecordingOn}
                                    icon
                                    onClick={() => {
                                        if (cameras) {
                                            let length = cameras.availableCameras.length;
                                            let curIndex = cameras.selectedCameraIndex;
                                            let nextIndex =  curIndex + 1;
                                            let settingIndex = nextIndex > length-1 ? 0 : nextIndex;
                                            setCameras(c => {
                                                c!.selectedCameraIndex = settingIndex;
                                            });
                                        }
                                        else {
                                            alert('no cameras available');
                                        }
                                    }}
                                    type="button"
                                >
                                    <SizeMe monitorHeight>
                                        {({ size }) => <CameraRotateSvgComponent style = {{ width: "auto", height: `${size.height}px`}}/>}
                                    </SizeMe>
                                </Button>
                            }
                        />
                    }
                    <Button
                        color= {'red'}
                        onClick={() => {
                            setIsRecordingOn(!isRecordingOn);
                        }}
                        disabled = {isRecordingStarting}
                        content = {formatMessage({id : isRecordingOn ? "RecordModal.Button.record.stop" : curRecording ?  "RecordModal.Button.record.rerecord" : "RecordModal.Button.record.start" })}
                        icon = {isRecordingOn ? 'stop circle' : 'video' }
                    />
                </Button.Group>
                {!isRecordingOn &&
                    <Button.Group floated="right">
                        <Button onClick={handleClose} type="button">
                            <FormattedMessage id = "RecordModal.Button.cancel" />
                        </Button>
                        <Button.Or text={"|"} />
                        <Button
                            type="submit"
                            positive
                            disabled = {isRecordingOn || !formik.isValid || !curRecording}
                        >
                            <FormattedMessage id = "recordmodal.Button.submit" />
                        </Button>
                    </Button.Group>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default RecordModal;
