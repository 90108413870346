import React, { memo } from "react";

function VideoComponent( {divProps, videoProps} : {divProps?: React.HTMLAttributes<HTMLDivElement>, videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>}) {
    return (<div {...divProps}>
            <video {...videoProps}/>
        </div>);
}

// export default React.memo(VideoComponent);
export default memo(VideoComponent, (prev, next) => {
    const c = next.videoProps ?
        ( prev.videoProps ? next.videoProps.src===prev.videoProps.src : false)
        :
        false;
    return c;
    }
);