import { createIntl, createIntlCache } from 'react-intl';

import English from '../langs/en.json'
import Russian from '../langs/ru.json';

const message_langs = {
    "en": English,
    "ru": Russian
}
const locale = process.env.REACT_APP_LANG;

export const LangSettings = {
    messages: (message_langs as any)[locale!],
    locale: locale!,
    defaultLocale: locale!
};

const getFormatMessage = () => {
    const cache = createIntlCache();
    const intl = createIntl({ ...LangSettings}, cache);
    const {formatMessage} = intl;
    return formatMessage;
}

export const formatMessage = getFormatMessage();

export const boldText = (c: React.ReactNode[]) => (<b>{c}</b>);
