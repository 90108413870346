import * as Yup from "yup";

import { Form, Icon } from "semantic-ui-react";

import AccessControlComponent from "../AccessControlComponent";
import { FormikValues } from "formik";
import LabelWithError from "../../LabelWithError";
import React from "react";
import { useIntl } from "react-intl";
import { useState } from "react";

export const accessSubFormYupShape = {
    is_private: Yup.bool(),
    reviewers: Yup.array().of(Yup.number()),
}

function AccessSubForm({formik, isExpandedInitial = false}: {formik: FormikValues, isExpandedInitial?: boolean}) {
    const [isExpanded, setIsExpanded] = useState(isExpandedInitial);
    const {formatMessage} = useIntl();
    return <Form.Field>
        <div style={{whiteSpace: "nowrap"}}>
            <Icon name={isExpanded ? "angle up" : "angle down"} onClick = {()=> setIsExpanded(!isExpanded)}/>
            <LabelWithError
                labelTextNorm = {formatMessage({id: "AccessSubForm.reviewers.norm"})}
                infoText={formatMessage({id: "AccessSubForm.reviewers.infoText"})}
            />
        </div>
        {isExpanded && <AccessControlComponent formik={formik} /> }
    </Form.Field>

}

export default React.memo(AccessSubForm);
