import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";

import * as _ from 'lodash';

import {Button, Header, Icon, Popup, Table} from "semantic-ui-react";
import { TableActionGeneric, TableInitialStateGeneric, TableStateTypeGeneric, tableReducer } from "../typings/Table";
import {FormattedMessage, useIntl} from "react-intl";
import { ProcessingStatus, ProcessingStatusIcons } from "../settings";
import React, { useEffect, useReducer } from 'react';

import Link from "../components/Link";
import { RootState } from "../store";
import { axiosService } from "../utils/axios";
import format from "date-fns/format";
import { parseISO } from "date-fns";
import { styleCenteredText } from "../typings/styles";
import { useSelector } from "react-redux";
import { useCreateAnalysis } from "../utils/hooks";
import { withHandlingDimming } from "../utils/hocs";
import { formatMessage as formatMessageLocal } from "../utils/localization";
import { AccessRightsType, ExerciseInfo } from "../typings/models/other";
import UploadModal from "../components/Modals/UploadModal";
import ExerciseAccessModal from "../components/Modals/ExerciseAccessModal";
type ExerciseListType = ExerciseInfo[]
type ExerciseTableStateType = TableStateTypeGeneric<ExerciseInfo>;
type ExerciseTableAction = TableActionGeneric<ExerciseInfo>;
const TableInitialState = TableInitialStateGeneric as ExerciseTableStateType;
const exerciseTableReducer = tableReducer<ExerciseInfo>;

const deleteExercise = (id: number, exersises: ExerciseInfo[], dispatch: React.Dispatch<ExerciseTableAction>, changeStateFn: React.Dispatch<React.SetStateAction<boolean>>, errMessage = "") => () => {
    changeStateFn(true);
    axiosService.delete(`${process.env.REACT_APP_API_URL}/exercise/${id}/`)
        .then( (r) => {
            if (r.status===200)
            {
                let data = exersises.filter( e => e.id!==id);
                dispatch({type: "UPDATE", data});
                changeStateFn(false);
            }
        })
        .catch((_) => {
            alert(errMessage);
        });
}

function ExerciseList({setIsHandling} : {setIsHandling: React.Dispatch<React.SetStateAction<boolean>>}) {
    const {formatMessage} = useIntl();
    const errMessage = formatMessage({id: "general.error"});
    const account = useSelector((state: RootState) => state.auth.account);
    const [{ column, data: exersises, direction }, dispatch] = useReducer(
        exerciseTableReducer,
        TableInitialState
    );
    const updateExercisePrivacy = (exerciseId: number, accessRights: AccessRightsType) => {
        if (exersises) {
            let e = exersises.find( e => e.id ===exerciseId );
            e!.is_private = accessRights.is_private;
            e!.reviewers = accessRights.reviewers;
            dispatch({type: "UPDATE", data: exersises});
        }
    };

    const createAnalysis = useCreateAnalysis(setIsHandling);
    useEffect(()=>{
        axiosService.get<ExerciseListType>(`${process.env.REACT_APP_API_URL}/exercise/`)
        .then( (res) => {
            const { data } = res;
            dispatch({type: "INITIALIZE", data: data});
            setIsHandling(false);
        })
        .catch((err) => {
            alert(err.message);
            setIsHandling(false);
        });
    }, [])

    return (
    <React.Fragment>
        <Header style={styleCenteredText}>
            <FormattedMessage id="exerciselist.header" />
        </Header>
        <div style={{ fontSize: '1.33em', textAlign: 'justify'  }}>
            {formatMessage({id: "exerciselist.infotext"},
                {
                    newExerciseLink: <Link url={process.env.REACT_APP_WEB_URL! + "/exercise/new"} children={formatMessage({id: "exerciselist.newExerciseLink"})}/>,
                    buttonUpload: <UploadModal/>,

                }
            )}
        </div>
        <Table celled sortable >
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign='center'>
                #
                </Table.HeaderCell>
                <Table.HeaderCell
                    textAlign='center'
                    sorted={column === 'id' && direction ? direction : undefined}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'id' })}>
                    Id
                    {column === 'id' && direction ? '' : <Icon name='sort' />}
                </Table.HeaderCell>
                <Table.HeaderCell
                    textAlign='center'
                    sorted={column === 'date_created' && direction ? direction : undefined}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'date_created' })}>
                    <FormattedMessage id="exerciselist.date" />
                    {column === 'date_created' && direction ? '' : <Icon name='sort' />}
                </Table.HeaderCell>
                <Table.HeaderCell
                    textAlign='center'
                    sorted={column === 'is_private' && direction ? direction : undefined}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'is_private' })}>
                    <Popup content={formatMessage({id: "exerciselist.private.info"})} trigger={<Icon name='info circle' />}/>
                    <FormattedMessage id="exerciselist.private" />
                    {column === 'is_private' && direction ? '' : <Icon name='sort' />}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                    <FormattedMessage id="exerciselist.processing_status" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                    <FormattedMessage id="exerciselist.comments" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                    <FormattedMessage id="exerciselist.analysises" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                    <FormattedMessage id="exerciselist.actions" />
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={column === 'author' && direction ? direction : undefined}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'author' })}
                    textAlign='center'
                >
                    <Popup content={formatMessage({id: "exerciselist.author.info"})} trigger={<Icon name='info circle' />}/>
                    <FormattedMessage id="exerciselist.author" />
                    {column === 'author' && direction ? '' : <Icon name='sort' />}
                </Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
            {exersises?.map(
                (
                    {
                        id,
                        author,
                        processing_status,
                        analysises,
                        analysises_of_author,
                        comments,
                        date_created,
                        is_private,
                        reviewers
                    },
                    i
                ) => {
                    // determin if the exercise is own or of another user
                    const isOwn = account?.id === author.id ? true : false;
                    return <Table.Row key={i}>
                        <Table.Cell textAlign='center'>{i+1}</Table.Cell>
                        <Table.Cell textAlign='center' >
                            <Link url={process.env.REACT_APP_WEB_URL! + `/exercise/${id}`}>
                                {id}
                            </Link>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{format(parseISO(date_created), "yyyy-MM-dd hh:mm")}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            {isOwn ?
                                <Icon
                                    name={!is_private ? 'eye' : 'eye slash' }
                                    color={!is_private ? 'green' : 'grey' }
                                />
                                :
                                "-"
                            }
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            { ProcessingStatusIcons[processing_status]}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{comments}</Table.Cell>
                        <Table.Cell
                            textAlign= {(isOwn && (!analysises || analysises.length===0)) || (!isOwn && (!analysises_of_author || analysises_of_author.length===0)) ? 'center' : 'left'}
                        >
                            {isOwn ?
                                ( analysises && analysises.length>0 ?
                                    <ul>
                                        {analysises.map(a =>
                                            (<li key={a.id}>
                                                {formatMessage({id: "exerciselist.analysistext"},
                                                    {
                                                        AnalysisLink: <Link url={process.env.REACT_APP_WEB_URL! + "/analyse/" + a.id} children={(new Date(a.date_created)).toDateString()}/>,
                                                        AnalysisAuthor: a.author.name,
                                                        CommentsText: a.comments ? (` - ${a.comments}`) : '.'
                                                    }
                                                )}
                                            </li>)
                                        )}
                                    </ul>
                                    :
                                    '-'
                                )
                                :
                                ( analysises_of_author && analysises_of_author.length>0 ?
                                    <ul>
                                        {analysises_of_author.map(a =>
                                            (<li key={a.id}>
                                                {formatMessage({id: "exerciselist.AnalysisOwnText"},
                                                    {
                                                        AnalysisLink: <Link url={process.env.REACT_APP_WEB_URL! + "/analyse/" + a.id} children={(new Date(a.date_created)).toDateString()}/>,
                                                        CommentsText: a.comments ? (` - ${a.comments}`) : '.'
                                                    }
                                                )}
                                            </li>)
                                        )}
                                    </ul>
                                    :
                                    "-"
                                )
                            }
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {isOwn && <React.Fragment>
                                <ExerciseAccessModal exerciseId={id} accessRightsChangeCallback={updateExercisePrivacy} accessFormInitialValues={{is_private : is_private, reviewers: reviewers}} />
                                <Popup content={formatMessage({id: "exerciselist.deleteInfoText"})} trigger={<Button icon='delete' color='red' inverted onClick={deleteExercise(id, exersises, dispatch, setIsHandling, errMessage)}/>}/>
                            </React.Fragment>}
                            { processing_status === ProcessingStatus.DONEOK &&
                                <Popup
                                    content={formatMessage({id: "exerciselist.createAnalysis"})}
                                    trigger={
                                        <Button icon='address book outline' color='green' inverted onClick={createAnalysis(id)}/>
                                    }
                                />
                            }
                        </Table.Cell>
                        <Table.Cell  textAlign='center'>
                            {isOwn ?
                                <Icon name='user' />
                                :
                                <a href = {`mailto: ${author.email}`}>{author.name}</a>
                            }
                        </Table.Cell>
                    </Table.Row>
                }
            )}
            </Table.Body>
        </Table>
    </React.Fragment>
    );
}

export default withHandlingDimming(formatMessageLocal({id: "general.serverhandling"}))(ExerciseList);

