import { Analyse, Exercise, Home, Login, Profile } from "./pages";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import store, { persistor } from "./store";

import AnalyseList from "./pages/AnalyseList";
import ExerciseList from "./pages/ExerciseList";
import ExerciseNew from "./pages/ExerciseNew";
import Help from "./pages/Help";
import { IntlProvider } from 'react-intl';
import { LangSettings } from "./utils/localization";
import Layout from "./components/Layout";
import { MediaContextProvider } from "./utils/Mobile";
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoute from "./routes/ProtectedRoute";
import { Provider } from "react-redux";
import Register from "./pages/Register";
import TgComponent from "./pages/Telegram/TgComponent";
import VerifyEmail from "./pages/VerifyEmail";

// TODO add logout by timeout - https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2


export default function App() {
  return (
    <IntlProvider {...LangSettings}>
      <MediaContextProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <Router>
                  <Routes>
                    <Route path="/" element={<Layout/>}>
                      <Route index element={<Home/>} />
                      <Route path="login" element={<Login/>} />
                      <Route path="help" element={<Help/>} />
                      <Route path="register/:otc_invite" element={<Register/>} />
                      <Route path="register" element={<Register/>} />
                      <Route path="verifyemail/:otp" element={<VerifyEmail/>} />
                      <Route path="verifyemail" element={<VerifyEmail/>} />
                      <Route element={<ProtectedRoute/>}>
                        <Route path="exercise/new" element={<ExerciseNew/>} />
                        <Route path="exercise/:id" element={<Exercise/>} />
                        <Route path="exercise" element={<ExerciseList/>} />
                        <Route path="analyse/:id" element={<Analyse/>} />
                        <Route path="analyse" element={<AnalyseList/>} />
                        <Route path="profile" element={<Profile/>} />
                      </Route>
                    </Route>
                    <Route path="tg" element={<TgComponent/>} />
                  </Routes>
            </Router>
          </PersistGate>
        </Provider>
      </MediaContextProvider>
    </IntlProvider>
  );
}