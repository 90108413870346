import { Checkbox, Divider, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";

import ExpertsTable from "../Domain/ExpertsTable/ExpertsTable";
import { FormikValues } from "formik";

export default function AccessControlComponent({formik}: {formik: FormikValues}) {
    const {formatMessage} = useIntl();
    return <Segment>
        <Checkbox
            radio
            label={formatMessage({id: "AcessForm.checkbox.label.notPrivate"})}
            name='checkboxRadioGroup'
            value='public'
            checked= {!formik.values.is_private}
            onChange={(e, data) => {
                if (data.checked !== undefined) {
                    formik.setFieldValue('is_private', !data.checked);
                    formik.setFieldValue('reviewers', []);
                };
            }}
        />
        <Divider horizontal>
            <FormattedMessage id="general.or" />
        </Divider>
        <Checkbox
            radio
            label={formatMessage({id: "AcessForm.checkbox.label.Private"})}
            name='checkboxRadioGroup'
            value='private'
            checked={formik.values.is_private}
            onChange={(e, data) => {
                if (data.checked !== undefined) {
                    formik.setFieldValue('is_private', data.checked);
                };
            }}
        />
        {formik.values.is_private &&
            <div style={{paddingLeft: 26}}>
                <ExpertsTable setSelectedExperts={(reviewers) => formik.setFieldValue('reviewers', reviewers)} initiallySelectedExpertIDs={formik.values.reviewers}/>
            </div>
            }
    </Segment>
}
