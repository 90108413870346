import {Divider, Segment} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";

import React from 'react';
import RecordModal from '../components/Modals/RecordModal';
import SettingsModal from "../components/Modals/SettingsModal";
import UploadModal from "../components/Modals/UploadModal";

function ExerciseNew() {
    const {formatMessage} = useIntl();
    return (
        <React.Fragment>
            <Segment>
                { formatMessage({id : "exercisenew.upload"}, {buttonUpload: <UploadModal/> })}
            </Segment>
            <Divider horizontal><FormattedMessage id='general.or' /></Divider>
            <Segment>
                { formatMessage({id : "exercisenew.record"}, {
                    buttonRecordModal: <RecordModal />,
                    settingsRecordingModal: <SettingsModal/>,
                })}
            </Segment>
        </React.Fragment>
    );
}

export default React.memo(ExerciseNew);
