import * as _ from 'lodash';

import { AudioResultsType } from '../typings/models/Results';
import { DraftFunction } from 'use-immer';

type RecognitionResultsHandlerType = (arg: AudioResultsType | DraftFunction<AudioResultsType>) => void;

/**
 * creates recognition object for the selected language
 * @param [lang] language used for recognition. Russian ("ru-RU") is default
 * @param [resultsHandler] callback function for handling results of the recognition. Console is default
 * @returns SpeechRecognition object
 */
// export const createSpeechRecognizer = (lang: string = "ru-RU", resultsHandler: typeof console.log = console.log) : SpeechRecognition =>{
export const createSpeechRecognizer = (lang: string = "ru-RU", resultsHandler: RecognitionResultsHandlerType) : SpeechRecognition =>{
    const sr =  window.SpeechRecognition || window.webkitSpeechRecognition;
    const speechRecognizer = new sr();
    speechRecognizer.continuous = true;
    speechRecognizer.interimResults = false;
    speechRecognizer.lang = lang;

    speechRecognizer.onstart = () => {
        console.log("microphones on");
    };
    
    speechRecognizer.onresult = (event:SpeechRecognitionEvent) => {
        const recordingResult = Array.from(event.results).map((result) => result[0].transcript);
        resultsHandler(ar => {
            let currentTimeTick = Date.now();
            // elapsed time in minutes
            let timeElapsed = (currentTimeTick - ar.currentTimeTick) / 60000;
            let words = recordingResult.length - ar.transcript.length;
            if (timeElapsed>0) ar.currentWordSpeed = words / timeElapsed;
            ar.transcript = recordingResult;
        });
    };
    
    speechRecognizer.onerror = (event: SpeechRecognitionErrorEvent) => {
        console.log(event.error);
    };

    return speechRecognizer;
}