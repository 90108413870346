import * as React from "react"

import { SVGProps } from "react"

const ThumbUpSvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={51.102}
    height={51.102}
    {...props}
  >
    <path
      strokeMiterlimit={10}
      d="M20.762.906s2.159-.116 2.903 1.567c.745 1.682.67 4.235.67 4.235s2.382 3.829 4.988 5.338c2.605 1.508 6.848 3.249 6.848 3.249s.893.58 1.34 1.392c.447.812 2.457 5.396 2.457 5.396s1.489 2.843 3.796 4.757c2.308 1.915 3.424 2.321 4.69 2.611 1.265.29 1.712.58 1.712.58l-2.382 19.843s-5.21-2.03-7.146-1.741c-1.936.29-4.988.29-7.295 1.218-2.308.929-14.367 1.276-20.694-.696-6.327-1.972-7.817-.87-9.23-3.597-1.415-2.727 2.233-2.843 2.456-3.83.223-.985-3.722-.87-4.243-3.074-.175-.742 1.138-2.483 3.35-3.713 1.041-.58-4.393.174-3.872-3.771.244-1.845.894-2.263 3.127-2.727 2.233-.464-3.648-1.16-3.35-3.946.203-1.893 3.494-3.786 8.635-4.06 5.434-.29 8.263-.232 8.263-.232l5.657-.29s-3.797-4.816-5.21-6.557c-1.415-1.74-3.201-5.627-3.052-6.44.147-.815.37-5.978 5.581-5.514z"
      style={{
        fill: "#cf0",
        stroke: "#000",
        strokeWidth: 1.75439036,
        strokeMiterlimit: 10,
      }}
    />
  </svg>
)
export default ThumbUpSvgComponent
