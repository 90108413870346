import { Button, Divider, Grid, Icon, Popup, Segment } from 'semantic-ui-react'
import { Container, Header } from 'semantic-ui-react'
import {FormattedMessage, useIntl} from "react-intl";
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import ContentComponentFromDB from '../components/common/Content';
import { ContentShortType } from '../typings/models/other';
import FeedbackModal from '../components/Modals/FeedbackModal';
import InfoModal from '../components/Modals/InfoModal';
import { RootState } from "../store";
import SpecialitiesComponent from '../components/Domain/Specialties';
import authSlice from "../store/slices/auth";
import { axiosService } from '../utils/axios';
import { useNavigate } from "react-router";

// settings
const helpId = 1;

const Profile = () => {
    const account = useSelector((state: RootState) => state.auth.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const userName = account?.name ? ` ${account?.name}` : "";

    const handleLogout = () => {
        dispatch(authSlice.actions.setLogout());
        navigate("/login");
    };

    const {formatMessage} = useIntl();

    return (
        <Segment style={{ padding: '0em 1em' }} vertical>
            <Container fluid>
                <Header as='h2' style={{ textAlign: 'center' }}>
                    <FormattedMessage id="Profile.Header" values={{name: userName}} />
                </Header>
                <Grid stackable columns={2} verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={12} >
                            <Icon name='wpforms' />
                            <div dangerouslySetInnerHTML={{__html: formatMessage({id: "Profile.feedback"})}} style={{"display": "inline"}} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <FeedbackModal
                                buttonLabel={formatMessage({id: "Profile.button.feedback"})}
                                buttonProps={{
                                    labelPosition: 'right',
                                    fluid: true,
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Icon name='log out' /><div dangerouslySetInnerHTML={{__html: formatMessage({id: "Profile.logout"})}} style={{"display": "inline"}} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button icon labelPosition='right' onClick={handleLogout} fluid>
                                <Icon name='log out' /><FormattedMessage id="Profile.button.logout" />
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Divider/>
        </Segment>
    );
};

export default Profile;
