import "./styles.css";
import "semantic-ui-css/semantic.min.css";
import * as _ from "lodash";
import {FormattedMessage} from "react-intl";
import { Dimmer, Header, Icon, Loader, Table } from "semantic-ui-react";
import { useEffect, useReducer, useState } from "react";
import Link from '../components/Link';
import { RootState } from "../store";
import { styleCenteredText } from "../typings/styles";
import { useSelector } from "react-redux";
import { axiosService } from "../utils/axios";
import format from "date-fns/format";
import { parseISO } from "date-fns";
import { AnalysisInfo } from "../typings/models/other";
import { TableInitialStateGeneric, TableStateTypeGeneric, tableReducer } from "../typings/Table";

type AnalysisListType = AnalysisInfo[];
type TableStateType = TableStateTypeGeneric<AnalysisInfo>;
const TableInitialState = TableInitialStateGeneric as TableStateType;
const analysisTableReducer = tableReducer<AnalysisInfo>;

function AnalyseList() {
  const account = useSelector((state: RootState) => state.auth.account);
  const [isHandling, setIsHandling] = useState(true);
  const [{ column, data: analysises, direction }, dispatch] = useReducer(
    analysisTableReducer,
    TableInitialState
  );
  useEffect(() => {
    axiosService.get<AnalysisListType>(`${process.env.REACT_APP_API_URL}/analysis/`)
    .then( (res : any) => {
        const { data } = res;
        dispatch({type: "INITIALIZE", data: data});
        setIsHandling(false);
    })
    .catch((err) => {
        alert(err.response.data.detail.toString());
    });
  }, []);

  return (
    <Dimmer.Dimmable dimmed={!isHandling}>
      <Dimmer active={isHandling} inverted>
          <Loader><FormattedMessage id="general.serverhandling" /></Loader>
      </Dimmer>
      <Header style={styleCenteredText}>
        <FormattedMessage id="analyselist.header" />
      </Header>
      <Table celled sortable structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center' rowSpan='2'>
              #
            </Table.HeaderCell>
            <Table.HeaderCell
            textAlign='center' rowSpan='2'
            sorted={column === 'id' && direction ? direction : undefined}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'id' })}>
              <FormattedMessage id="analyselist.id" />
              {column === 'id' && direction ? '' : <Icon name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
            textAlign='center' rowSpan='2'
            sorted={column === 'date_created' && direction ? direction : undefined}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'date_created' })}>
              <FormattedMessage id="analyselist.date_created" />
              {column === 'date_created' && direction ? '' : <Icon name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center' rowSpan='2'>
              <FormattedMessage id="analyselist.comments" />
            </Table.HeaderCell>

            <Table.HeaderCell
            textAlign='center' rowSpan='2'
            sorted={column === 'author' && direction ? direction : undefined}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'author' })}
            >
              <FormattedMessage id={"analyselist.author"} />
              {column === 'author' && direction ? '' : <Icon name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={'3'} textAlign='center' >
              <FormattedMessage id={"analyselist.tablerowheader"} />
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
              <Table.HeaderCell textAlign='center'
              sorted={column === 'exercise.author' && direction ? direction : undefined}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'exercise.author' })}
              >
                <FormattedMessage id={"analyselist.exercise.author"} />
                {column === 'exercise.author' && direction ? '' : <Icon name='sort' />}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'
              sorted={column === 'exercise.id' && direction ? direction : undefined}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'exercise.id' })}
              >
                <FormattedMessage id="analyselist.exercise.id" />
                {column === 'exercise.id' && direction ? '' : <Icon name='sort' />}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'
              sorted={column === 'exercise.date_created' && direction ? direction : undefined}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'exercise.date_created' })}
              >
                <FormattedMessage id="analyselist.exercise.date_created" />
                {column === 'exercise.date_created' && direction ? '' : <Icon name='sort' />}
              </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {analysises?.map(
            (
              { id,
                exercise,
                date_created,
                comments,
                author,
              },
              i
            ) => (<Table.Row key={id}>
                <Table.Cell textAlign='center'>{i}</Table.Cell>
                <Table.Cell textAlign='center'>
                  <Link url={process.env.REACT_APP_WEB_URL! + `/analyse/${id}`}>
                      {id}
                  </Link>
                </Table.Cell>
                <Table.Cell textAlign='center'>{format(parseISO(date_created), "yyyy-MM-dd hh:mm")}</Table.Cell>
                <Table.Cell textAlign='center'>{comments}</Table.Cell>
                <Table.Cell  textAlign='center'>{account?.id === author.id ? <Icon name='user' /> : author.name}</Table.Cell>
                <Table.Cell  textAlign='center'>{account?.id === exercise.author.id ? <Icon name='user' /> : exercise.author.name}</Table.Cell>
                <Table.Cell textAlign='center'>
                  <Link url={process.env.REACT_APP_WEB_URL! + `/exercise/${exercise.id}`}>
                      {exercise.id}
                  </Link>
                </Table.Cell>
                <Table.Cell textAlign='center'>{format(parseISO(exercise.date_created), "yyyy-MM-dd hh:mm")}</Table.Cell>
              </Table.Row>)
          )}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
}

export default AnalyseList;