const arrowTurnToLeft_1 = `
    m 33.343444 31.973972 c 0 17.4888 -25.5194 31.6663 -57.1579 31.6831 l -0.2733 3.8814 l -11.0241 -6.8084 l 11.4083 -6.1429 l -0.1023 3.4495 l 0.3198 0.002 c 31.4798 0 56.746 -13.2275 56.746 -30.7163 z
    `;
const arrowTurnToLeft_2 = `
    m 33.343444 31.973972 c 0 -10.5757 -9.3319 -19.9405 -23.6706 -25.6916 c -3.0162 -1.2098 -6.2539 -2.2596 -9.6729 -3.1272 v -5.6016 c 3.419 0.8676 6.6567 1.9175 9.6729 3.1272 c 14.3387 5.7511 23.6706 15.1159 23.6706 25.6916 z
    `;
const arrowMoveRight = `
    m 0 2.4887 c 0 0 10.5891 0.1297 20.7946 0.1571 l 0.1543 3.8814 l 6.2254 -6.8084 l -6.4424 -6.1429 l 0.0578 3.4495 l -20.7897 0.1717 z
    `;

const arrowRotateClockwise = `
    m 0.18592606,0.29399404 c -13.14367106,-26.53759404 -32.10632606,-63.75879404 1.58919904,-88.85085504 14.9644659,-11.707469 36.0212539,-9.136153 47.7287219,5.828312 l 8.626555,11.026447 6.300827,-4.92946 -0.278005,25.610989 -26.500511,-4.660784 7.088431,-5.545642 -8.626555,-11.026448 c -40.3298679,-25.583234 -44.5876359,50.119285 -35.92866294,72.54744104 z
`;
const arrowRotateCounterClockwise = `
    m 0.18745746,0.48399167 c 13.14367154,-26.53759367 32.10632654,-63.75879367 -1.58919896,-88.85085467 -14.9644655,-11.707467 -36.0212535,-9.136153 -47.7287215,5.828312 l -8.626555,11.026447 -6.300827,-4.92946 0.278005,25.610989 26.500511,-4.660784 -7.088431,-5.545642 8.626555,-11.026448 c 40.3298675,-25.583234 44.5876355,50.119285 35.92866246,72.54744067 z
`;

export type TurnDirection = "left" | "right";
export type RotationDirectionX = "clockwise" | "counterclockwise";
interface ISuggestionVideoDrawingSettings {
    canvas: HTMLCanvasElement;
    attachX: number;
    attachY: number;
    kx?: number;
    ky?: number;
}
interface ISuggestionVideoDrawingMoveSettings extends ISuggestionVideoDrawingSettings {
    moveDirection: MoveDirection;
}
interface ISuggestionVideoDrawingTurnZSettings extends ISuggestionVideoDrawingSettings {
    turnDirection: TurnDirection;
}
interface ISuggestionVideoDrawingRotateXSettings extends ISuggestionVideoDrawingSettings {
    rotationDirection: RotationDirectionX;
}

const RotationXArrow = {
    clockwise: arrowRotateClockwise,
    counterclockwise: arrowRotateCounterClockwise
};
const RotationAngleForMoveArrow = {
    left: 0,
    up: 90,
    right: 180,
    down: 270
};
export type MoveDirection = "left" | "right" | "up" | "down";
const moveAngle = {
    right: 0,
    down: 90,
    left: 180,
    up: 270
};


// const widthArrow = 69.1364;
// const heightArrow = 70.4767;
// const hArrowAttach = 35.8765;
// const wAttach = 6.0933 - 0.217;

export function drawArrowRotationX({
    canvas,
    attachX = 0,
    attachY = 0,
    rotationDirection = "clockwise",
    kx = 1,
    ky = 1
}: ISuggestionVideoDrawingRotateXSettings) {
    const ctx = canvas.getContext("2d");
    if (ctx==null) return;
    ctx.save();
    ctx.transform(kx, 0, 0, ky, 0, 0);
    ctx.translate(attachX / kx, attachY / ky);
    ctx.fillStyle = "red";
    let arrowToRotate = RotationXArrow[rotationDirection];
    var path1 = new Path2D(arrowToRotate);
    ctx.fill(path1);
    ctx.lineWidth = 1;
    ctx.stroke(path1);
    ctx.restore();
}

export function drawArrowTurnZ({
    canvas,
    attachX = 0,
    attachY = 0,
    turnDirection: rotationDirection = "left",
    kx = 1,
    ky = 1
}: ISuggestionVideoDrawingTurnZSettings) {
    const ctx = canvas.getContext("2d");
    if (ctx==null) return;
    const angle = RotationAngleForMoveArrow[rotationDirection];
    const angleRad = (angle * Math.PI) / 180;
    ctx.save();
    ctx.transform(kx, 0, 0, ky, 0, 0);
    ctx.translate(attachX / kx, attachY / ky);
    ctx.rotate(angleRad);
    ctx.fillStyle = "red";
    var path1 = new Path2D(arrowTurnToLeft_1);
    ctx.fill(path1);
    ctx.lineWidth = 1;
    ctx.stroke(path1);
    ctx.fillStyle = "blue";
    var path2 = new Path2D(arrowTurnToLeft_2);
    ctx.strokeStyle = "black";
    ctx.stroke(path2);
    ctx.fill(path2);
    ctx.restore();
}

export function drawArrowMove({
    canvas,
    attachX = 0,
    attachY = 0,
    moveDirection = "right",
    kx = 1,
    ky = 1
}: ISuggestionVideoDrawingMoveSettings) {
        const ctx = canvas!.getContext("2d");
        if (ctx==null) return;
        const angle = moveAngle[moveDirection];
        const angleRad = (angle * Math.PI) / 180;
        ctx.save();
        ctx.transform(kx, 0, 0, ky, 0, 0);
        ctx.translate(attachX / kx, attachY / ky);
        ctx.rotate(angleRad);
        ctx.fillStyle = "red";
        var path1 = new Path2D(arrowMoveRight);
        ctx.fill(path1);
        ctx.lineWidth = 1;
        ctx.stroke(path1);
        ctx.restore();
}
