import { Dimmer, Loader } from "semantic-ui-react";
import { Fragment, useState } from "react";

type DimmingHandlingInfoType = {
    setIsHandling: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * hoc to create cover for components. the component should have properties with function to set cover (trigger via setIsHandling). setIsHandling must be of type setting boolean state.
 * @param [loaderMessage] message showed in the cover
 * @param [isInitiallyDimmed] flag to show if the component is initially covered
 * @returns returns component which accepts additional parameters for showing cover besides original ones
 */
export const withHandlingDimming = (loaderMessage: string, isInitiallyDimmed: boolean = true) => <P  extends DimmingHandlingInfoType,>(
    WrappedComponent: React.ComponentType<P>
) => {
    type ResType = Omit<P, keyof DimmingHandlingInfoType>
    const ComponentWithHandlingDimming = (props: ResType) => {
        const [isHandling, setIsHandling] = useState(isInitiallyDimmed);
        const curProps = {...props, setIsHandling} as P;
        return (
            <Fragment>
                <Dimmer active={isHandling} inverted>
                    <Loader>{loaderMessage}</Loader>
                </Dimmer>
                <WrappedComponent {...curProps } />
            </Fragment>
        );
    };
    return ComponentWithHandlingDimming;
}
