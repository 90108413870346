import "ka-table/style.scss";
import "./ExpertsTable.css";

import * as _ from "lodash";

import { Accordion, AccordionTitleProps, Button, Checkbox, Dropdown, Icon, Input, Popup, SemanticICONS } from 'semantic-ui-react';
import { DataType, FilteringMode, PagingPosition, SortingMode } from 'ka-table/enums';
import { ExpertsTableUSer as ExpertsTableUser, InvitedUserInfoType, UsersInfoItemsType, } from "../../../typings/models/User";
import {FormattedMessage, useIntl} from "react-intl";
import { ICellTextProps, IFilterRowEditorProps } from 'ka-table/props';
import { ITableProps, Table, useTable, useTableInstance } from 'ka-table';
import React, { Fragment, useEffect, useState } from 'react';
import { selectRow, updateCellValue, updateData, updateFilterRowValue } from "ka-table/actionCreators";

import { AxiosResponse } from "axios";
import { Column } from "ka-table/models";
import Invite from "../../common/Invite";
import { Nullable } from "../../../typings/general";
import { axiosService } from "../../../utils/axios";
import { formatMessage as formatMessageLocal } from "../../../utils/localization";
import {getData} from "ka-table/Utils/PropsUtils"

//#region table props - columns and initials props
const columns = [
  // { key: 'id', title: "id", dataType: DataType.Number, width: 100, style: {textAlign: 'center'}  },
  { key: 'selected', title: formatMessageLocal({id: "ExpertsTable.columns.selected"}), dataType: DataType.Boolean, width: 100, style: {textAlign: 'center'}  },
  { key: 'name', title: formatMessageLocal({id: "ExpertsTable.columns.name"}), dataType: DataType.String, width: 200},
  { key: 'role', title: formatMessageLocal({id: "ExpertsTable.columns.role"}), dataType: DataType.String, width: 150 },
  { key: 'specialty', title: formatMessageLocal({id: "ExpertsTable.columns.specialty"}), dataType: DataType.Object, width: 150 },
  { key: 'description', title: formatMessageLocal({id: "ExpertsTable.columns.description"}), dataType: DataType.String, width: 250 },
] as Column[];

const tablePropsInit = {
  columns: columns,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
  filter: ({ column }) => {
    if (column.key === "specialty") {
      const filterFunc = (specialty: string[], filterRowValue: string) => _.some(specialty, (s) => s.includes(filterRowValue));
      return filterFunc;
    }
  },
  filteringMode: FilteringMode.FilterRow,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
    pageSizes: [3, 5, 10],
    position: PagingPosition.Bottom
  },
} as ITableProps;
//#endregion

//#region table filter configuration
const selectionFilterOptions = [
  {
    key: 'all',
    text: 'All',
    value: 'all',
    content: formatMessageLocal({id: "ExpertsTable.selectionFilterOptions.All"}),
    icon: 'circle outline',
  },
  {
    key: 'true',
    text: 'Selected',
    value: 'true',
    content: formatMessageLocal({id: "ExpertsTable.selectionFilterOptions.Selected"}),
    icon: 'checkmark',
  },
  {
    key: 'false',
    text: 'Unselected',
    value: 'false',
    content: formatMessageLocal({id: "ExpertsTable.selectionFilterOptions.Unselected"}),
    icon: 'minus',
  },
];
const FilterSelectionCustomEditor: React.FC<IFilterRowEditorProps> = ({
  column, dispatch,
}) => {
  const toNullableBoolean = (value: any) => {
    switch (value) {
      case 'true': return true;
      case 'false': return false;
      case 'all': return undefined;
    }
    return value;
  };
  const [curSelection, setCurSelection] = useState<boolean | undefined>(column.filterRowValue?.value ?? selectionFilterOptions[0].value);
  const filterIconName = _.find(selectionFilterOptions, { key: curSelection?.toString()})?.icon as SemanticICONS;

  return (<Dropdown
    trigger={<React.Fragment><Icon name="filter" /><Icon name={filterIconName===undefined ? selectionFilterOptions[0].icon as SemanticICONS : filterIconName}/></React.Fragment>}
    options={selectionFilterOptions}
    defaultValue = {curSelection}
    onChange={(event, data) => {
      setCurSelection(toNullableBoolean(data?.value));
      dispatch(updateFilterRowValue(column.key, toNullableBoolean(data?.value)));
    }}
    compact
    inline
    fluid
  />);
};
const FilterTextCustomEditor: React.FC<IFilterRowEditorProps> = ({
  column, dispatch,
}) => {
  return (
    <div>
      <Input
        icon='filter'
        fluid
        iconPosition='left'
        defaultValue={column.filterRowValue}
        onChange={(event) => {
          const filterRowValue = event.currentTarget.value !== ""
            ? event.currentTarget.value
            : null;
          dispatch(updateFilterRowValue(column.key, filterRowValue));
        }}
      />
    </div>
  );
};
//#endregion

//#region table selection defs
const allRowsSelected = (props: ITableProps) => {
  const newData = getData(props);
  let r = newData.every(d => d.selected);
  return r;
}
const anyRowsSelected = (props:ITableProps) => {
  const newData = getData(props);
  let r = newData.some(d => d.selected);
  return r;
}

const SelectionHeaderCustom = () => {
  const table = useTableInstance();
  const areAllRowsSelected = allRowsSelected(table.props);
  const areAnyRowsSelected = anyRowsSelected(table.props);
  const curCheckBoxProps = areAllRowsSelected ? {indeterminate: false, checked : true} : areAnyRowsSelected ? {indeterminate: true, checked : false} : {indeterminate: false, checked : false} ;
  return (
    <Fragment>
      <Popup
        content={formatMessageLocal({id: "ExpertsTable.Checkbox.popup"})}
        trigger={
          <Checkbox
            {...curCheckBoxProps}
            onClick={(event: any) => {
              if (!areAllRowsSelected && areAnyRowsSelected) { //indeterminate
                table.selectAllVisibleRows(); // also available: selectAllFilteredRows selectAllVisibleRows(), selectAllRows()
                // table.selectAllRows(); // also available: selectAllFilteredRows selectAllVisibleRows(), selectAllRows()
              } else if (areAllRowsSelected) {
                table.deselectAllVisibleRows(); // also available: deselectAllFilteredRows deselectAllVisibleRows(), deselectAllRows()
                // table.deselectAllRows(); // also available: deselectAllFilteredRows deselectAllVisibleRows(), deselectAllRows()
              } else {
                // table.selectAllRows(); // also available: selectAllFilteredRows selectAllVisibleRows(), selectAllRows()
                table.selectAllVisibleRows(); // also available: selectAllFilteredRows selectAllVisibleRows(), selectAllRows()
              }
            }}
            style={{verticalAlign: 'middle'}}
            label = {areAnyRowsSelected ? formatMessageLocal({id: "ExpertsTable.Checkbox.label.Unselect"}) : formatMessageLocal({id: "ExpertsTable.Checkbox.label.Select"})}
          />}
      />
    </Fragment>
  );
};

const SelectionCellCustom: React.FC<ICellTextProps> = ({
  rowData,
  rowKeyValue,
  dispatch
}) => {
  const table = useTableInstance();
  return (
    <Icon
      name= { rowData['selected'] ? 'checkmark' : 'minus' }
      onClick={() => {
          if (rowData['selected']) {
            table.deselectRow(rowKeyValue);
            dispatch(updateCellValue(rowKeyValue, 'selected', false));
        }
          else {
            table.selectRow(rowKeyValue);
            dispatch(updateCellValue(rowKeyValue, 'selected', true));
        }
      }}
    />
);};

const SpecialtyCellCustom: React.FC<ICellTextProps> = ({rowData}) => {
  return (
    <Fragment>
      {rowData['specialties']?.join('; ')}
    </Fragment>
);};
//#endregion

const ExpertsTable = ({setSelectedExperts, initiallySelectedExpertIDs = []} : {setSelectedExperts : (p: number[] | undefined) => void, initiallySelectedExpertIDs? : number[]}
) => {
  const {formatMessage} = useIntl();
  const [tableProps, setTableProps] = useState<ITableProps | undefined>(undefined);
  const [activePanelIndex, setActivePanelIndex] = useState<number | undefined>(undefined);
    // loading from server
  useEffect(() => {
    axiosService.get<UsersInfoItemsType>(`${process.env.REACT_APP_API_URL}/usersinfoitems/`)
    .then( ({data : {
      users : usersShowInfo,
      specialties
    }} : AxiosResponse<UsersInfoItemsType, any>) => {
      const users = usersShowInfo.map( (u : any) => {
        let isSelected = initiallySelectedExpertIDs.includes(u.id);
        return new ExpertsTableUser(u, specialties, isSelected);
    });
      setTableProps({...tablePropsInit, data: users, selectedRows: initiallySelectedExpertIDs} as ITableProps);
    })
    .catch((err: any) => {
        alert(err.toString());
    });
  }, []);

  const tableConfig = tableProps!==undefined ? {
    onDispatch: (action : any, newProps : any) => {
      const { selectedRows: selectedRowsOld } = tableProps;
      const { data: dataNew, selectedRows: selectedRowsNew } = newProps;
      if (selectedRowsOld!==undefined && dataNew !==undefined) {
        selectedRowsOld.forEach( (s : any) => {
          let ce = _.find(dataNew, {id: s});
          ce['selected']=false;
        });
      }
      if (selectedRowsNew!==undefined && dataNew !==undefined) {
        selectedRowsNew.forEach( (s : any) => {
          let ce = _.find(dataNew, {id: s});
          ce['selected']=true;
        });
      }
      setSelectedExperts(selectedRowsNew);
      setTableProps(newProps);
    }
  } : {};

  const table = useTable(tableConfig);
  const [isEditingInvite, setIsEditingInvite] = useState(false);
  //Adding invited user to the selected users for requesting analysis
  const onUserInvited = (invitedUserInfo : Nullable<InvitedUserInfoType>) => {
    if (invitedUserInfo != null) {
      let newData = [...tableProps?.data!, {...invitedUserInfo, selected: true}];
      table.dispatch(updateData(newData));
      table.dispatch(selectRow(invitedUserInfo.id));
    }
    setIsEditingInvite(false);
  }

  const InviteElement = <Fragment>
    <FormattedMessage id="ExpertsTable.InviteElement.intro" />
    {!isEditingInvite && <Button icon="add user" color="green" onClick={() => setIsEditingInvite(!isEditingInvite)}/>}
    {isEditingInvite && <Invite onUserInvited={onUserInvited}/>}
  </Fragment>

  const ExpertsTableElement = <Fragment>
    <FormattedMessage id="ExpertsTable.table.intro" />
    {tableProps!==undefined ? <Table
        {...tableProps}
        table={table}
        childComponents={{
          cellText: {
            content: (props) => {
              if (props.column.key === 'selected') {
                return <SelectionCellCustom {...props} />;
              }
              if (props.column.key === 'specialty') {
                return <SpecialtyCellCustom {...props} />;
              }
            },
          },
          headCell: {
            content: (props) => {
              if (props.column.key === 'selected') {
                return <SelectionHeaderCustom />;
              }
            },
          },
          filterRowCell: {
            content: (props) => {
              switch (props.column.key){
                case 'selected': return <FilterSelectionCustomEditor {...props}/>;
                default: return <FilterTextCustomEditor {...props}/>;
              }
            }
          }
        }}
      />
      :
      <Fragment>
        <Icon loading name='spinner' size="massive" /><FormattedMessage id="ExpertsTable.loading.label" />
      </Fragment>
    }
  </Fragment>;

  const panels = [{
      key: `invites`,
      title: formatMessage({id: "ExpertsTable.panels.invites"}),
      content: {
        content: InviteElement
      },
    },
    {
      key: `userlist`,
      title: formatMessage({id: "ExpertsTable.panels.userlist"}),
      content: {
        content: ExpertsTableElement
      },
    },
  ];

  const handleTitleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
    const curPanelIndex = data.index as number;
    const newIndex = activePanelIndex === curPanelIndex ? undefined : curPanelIndex;
    setActivePanelIndex(newIndex);
  }

  return (
    <Fragment>
      <Accordion  styled fluid
        panels={panels}
        activeIndex={undefined}
        exclusive={true}
        onTitleClick={handleTitleClick}
      />
    </Fragment>
  );
};

export default React.memo(ExpertsTable);