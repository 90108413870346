import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";

import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

import { MediaPipeFaceMeshMediaPipeModelConfig } from "@tensorflow-models/face-landmarks-detection";

export function createFaceLandmarksDetector() : Promise<faceLandmarksDetection.FaceLandmarksDetector> {
        // handling face_mesh set up
        const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
        const detectorConfig = {
            runtime: 'mediapipe', // or 'tfjs'
            solutionPath: '/face_mesh',
            refineLandmarks: false,
        } as MediaPipeFaceMeshMediaPipeModelConfig;
        let detector = faceLandmarksDetection.createDetector(model, detectorConfig);
        return detector;
}

